import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { API_STATUS } from 'src/app/constant';
import { LoginService } from '../login.service';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { TaxPayerDashboardService } from 'src/app/shared/services/taxpayer-dashboard.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EInvoiceService } from 'src/app/taxPayer/e-invoice/e-invoice.service';
import { switchLanguage } from 'src/app/app.i18n';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';

@Component({
  selector: 'app-reset-forget-password',
  templateUrl: './reset-forget-password.component.html',
  styleUrls: ['./reset-forget-password.component.scss']
})
export class ResetForgetPasswordComponent implements OnInit {
  password;
  show: any;
  showNew: any;
  passwords;
  passwordMatched = true;

  resetPasswordForm: FormGroup;
  userId: any;
  marquee: any;
  modalRef: any;
  currentPage: any;
  isMenuSelected = 'ForWhom';
  constructor(
    @Inject(I18NEXT_SERVICE) public i18nextSvc: ITranslationService,
    private route: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private toastrService: ToastrService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private taxPayerDashboardService: TaxPayerDashboardService,
    private modalService: BsModalService,
    private invoiceService: EInvoiceService

  ) { }

  /**
   * Initialize component
   */
  async ngOnInit() {
    this.userId = this.route.snapshot.paramMap.get('userId');
    this.initForm();
    this.password = 'password';
    this.passwords = 'password';
    this.taxPayerDashboardService.getMarqueeForPre().subscribe(marqueeBody => {
      if (!(this.invoiceService.isEmpty(marqueeBody))) {
        this.marquee = marqueeBody;
      }
    })
  }

  // Initialize set password form
  initForm() {
    this.resetPasswordForm = new FormGroup({
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(16),
        this.patternValidator(/\d/, { hasNumber: true }),
        this.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        // this.patternValidator(/[a-z]/, { hasSmallCase: true }),
        this.patternValidator(/['/;\[\]\\!@#+_\-=$%^&*(),~`.?":{}|<>]/, {
          hasSpecialCharacters: true,
        }),
      ]),
      confirmPassword: new FormControl()
    });
  }

  /**
 * validate password pattern
 * @param regex regex to match
 * @param error error object to add to form control
 * @returns validation function to be used in form control
 */
  patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): any => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }


  /**
   * set password for user if passwords matches
   * @returns void
   */
  async resetPassword() {
    this.checkPasswordMatch();
    if (!this.passwordMatched) return;
    let res;
    let passwordDetails = {
      confirmPassword: this.resetPasswordForm.controls.confirmPassword.value,
      newPassword: this.resetPasswordForm.controls.password.value,

      userId: this.userId,
    };
    try {
      this.loginService.resetPassword(passwordDetails).subscribe(res => {
        if (res['status'] === API_STATUS.error) {
          let passwordErr = '';
          if (res['fieldErrors']) {
            res['fieldErrors'].forEach(
              (err: any) => (passwordErr += err.message + '</br>')
            );
          }
          this.toastrService.error(passwordErr, res['message'], {
            enableHtml: true,
          });
          return;
        }
        this.toastrService.success(res['message'], '', { enableHtml: true })
        this.router.navigate(['/login']);
      });

    } catch (e) {
      this.toastrService.error('Password not created');
    }
  }

  /**
   * check whether password and confirm password both are same or not
   * @returns boolean
   */
  checkPasswordMatch() {
    if (this.resetPasswordForm.value.password && this.resetPasswordForm.value.confirmPassword) {
      this.passwordMatched = this.resetPasswordForm.value.password === this.resetPasswordForm.value.confirmPassword;
    }
    return this.passwordMatched;
  }
  getNewsAndUpdates() {
    this.taxPayerDashboardService.getNews().subscribe(res => {
      if (res && res['response']) {
        this.marquee = res['response']['marquee'][0];

      }
    })
  }

  redirectTo(featureName, subfeature = null) {
    switch (featureName) {
      case 'contactus':
        this.router.navigate(['/contact-us']);
        break;

      case 'plans':
        this.router.navigate([`/plans/${subfeature}`]);
        break;

      case 'masters':
        this.router.navigate([`/masters`]);
        break;

      case 'web-content':
        this.router.navigate([`/web-content/${subfeature}`]);
        break;
      case 'api-integrator':
        this.router.navigate([`/api-integrator-detail-content`]);
        break;

      case 'taxpayer':
        this.router.navigate([`/taxpayer-content`]);
        break;
      case 'enablement-status':
        this.router.navigate([`/einvoice-enablement-status`]);
        break;
      case 'gstin-status':
        this.router.navigate([`/gstin-status`]);
        break;
      case 'verify-signedInvoice':
        this.router.navigate([`/verify-signed-invoice`]);
        break;
      case 'verify-qrcode':
        this.router.navigate([`/verify-qrcode`]);
        break;
      case 'hsn-search':
        this.router.navigate([`/hsn-search`]);
        break;
      case 'master-search':
        this.router.navigate([`/masters-code`]);
        break;
    }
  }
  openModal(template: TemplateRef<any>, page) {
    this.modalRef = this.modalService.show(template);
    this.currentPage = page
  }
  redirectToPage() {
    console.log("page", this.currentPage)
    if (this.currentPage == 'news') {
      window.open("https://irisirp.com/news/", '_blank');

    }
    if (this.currentPage == 'notifications') {
      window.open("https://irisirp.com/notifications/", '_blank');
    }
    if (this.currentPage == 'blog') {
      window.open("https://irisirp.com/blog/", '_blank');
    }

  }
  redirectToLogin() {
    // this.router.navigate([`/login`]);
    window.location.href = ('https://einvoice6.gst.gov.in')
  }
  redirectTOLoginPage(){
    this.router.navigate([`/login`]);
  }
  changeSizeByBtn(size: any) {
    const container = document.getElementById('increaseFont');
    container.style.fontSize = size;
  }
  onClick() {
    if (this.password === 'password') {
      this.password = 'text';
      this.show = true;
    } else {
      this.password = 'password';
      this.show = false;
    }
  }
  onClickNew() {
    if (this.passwords === 'password') {
      this.passwords = 'text';
      this.showNew = true;
    } else {
      this.passwords = 'password';
      this.showNew = false;
    }
  }
  generateEvent() {
    this.trackAnalytics("Peridot_Plus")
  }
  trackAnalytics(btnName) {
    this.googleAnalyticsService.trackAnalytics(btnName);
  }
  changeLanguage(event: Event) {
    const target = event.target as HTMLSelectElement;
    switchLanguage(target.value);
  }
}
