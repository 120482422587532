<!-- <nav class="navbar-expand-lg">
    <img src="../../../../assets/images/iris-reverse-white-logo.png" class="logo logoImage" />

  </nav> -->
  <div class="container-fluid custom_container_fluid cursor-pointer landingHeader" (click)="redirectToLogin()">
    <div class="row align-items-center headerDiv">
      <div class="col-sm-3 text-center">
        <img src="../../../../assets/images/gstnlogo.png" alt="..." class="imgClass">
      </div>
      <div class="col-sm-6 text-center">
      <p class="text-center my-0 font-size-28" >{{ 'new.E-Invoice Registration Portal - IRP 6' | i18next }}</p>
      <span class="text-center my-0 highDiv powerTxt"> {{ 'new.Powered by IRIS' | i18next }}</span>
      <!-- <span class="text-center my-0 smallDiv powerTxt"> Powered by IRIS for GSTN </span> -->
      </div>
      <div class="col-sm-3 text-center">
        <img src="../../../../assets/images/IRISIRP-Logo-1.png" class="imgClass">
      </div>
    </div>

  </div>
  <!-- <div class="container-fluid custom_container_fluid cursor-pointer landingHeader" id="increaseFont">
    <div class="row align-items-center  bg-navy ptb20">
      <div class="col-sm-6 pl6 txt-cen">
       <span><i class="bi bi-pin-map"></i> 231, International Infotech Park, Vashi, Navi Mumbai</span>
      </div>
      <div class="col-sm-6 pr6 text-right">
      <span class="mr"><i class="bi bi-envelope"></i> hello@irisirp.com</span>
      <span class="hideonSmallDiv" ><a (click)="changeSizeByBtn('20px')" class="mr" href="javascript:;">A<sup>+</sup></a> <a href="javascript:;" (click)="changeSizeByBtn('unset')">A<sup>-</sup></a></span>
      </div>
    </div>
  </div> -->
<nav class="navbar navbar-expand-lg navbar-light bg-transparent custom_navbar" *ngIf="hideNavbar">
  <div class="container-fluid px-5"><button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
      aria-label="Toggle navigation">
      <span class=""><i class="bi bi-list"></i></span>
    </button>
    <span class="mr10 smallDiv mt-10">
      <a [class.active]="isMenuSelected == 'Sandbox'" class="btn btn-primary rounded p13 btnContact1"  href="https://sandbox.einvoice6.gst.gov.in/signup" target="_blank" >{{ 'new.Sandbox' | i18next }}</a>
      </span>
      <span class="mr10 smallDiv mt-10">
        <a [class.active]="isMenuSelected == 'Login'" class="btn btn-primary rounded p13 btnContact" (click)="redirectToSignUp()" >{{ 'new.Sign up' | i18next }}</a>
        </span>
        <span class="nav-item dropdown pl0 smallDiv mr10 mt-10">
          <a class="btnLang " href="javascript:;" id="navbarDropdown02" role="button" data-bs-toggle="dropdown"
            aria-expanded="false"><img src="../../../../assets/images/landingpage/default.png" class="btnLangImg"> </a>
          <ul class="dropdown-menu t60 left-3" aria-labelledby="navbarDropdown02">
            <li><a href="https://einvoice6.gst.gov.in/content/hindi/" class="dropdown-item" ><img src="../../../../assets/images/landingpage/hindi.png" class="langImg" > हिन्दी </a></li>
            <li><a href="https://einvoice6.gst.gov.in/content/tamil/" class="dropdown-item" ><img src="../../../../assets/images/landingpage/tamil.png" class="langImg"> தமிழ் </a></li>
            <li><a href="https://einvoice6.gst.gov.in/content/marathi/" class="dropdown-item" ><img src="../../../../assets/images/landingpage/marathi.png" class="langImg"> मराठी </a></li>
            <li><a href="https://einvoice6.gst.gov.in/content/gujrati/" class="dropdown-item" ><img src="../../../../assets/images/landingpage/gujrati.png" class="langImg"> ગુજરાતી</a></li>
          </ul>
        </span>
        <div class="navbar-collapse collapse" id="navbarSupportedContent" style="">
      <ul class="mb-2 mb-lg-0 ms-auto navbar-nav custom_navbar_nav">
        <li class="nav-item dropdown">
          <a class="nav-link " (mouseenter)="isMenuSelected = 'Taxpayers'" [class.active]="isMenuSelected == 'Taxpayers'" href="javascript:;" id="navbarDropdown0" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            {{ 'new.Taxpayers' | i18next }} <span><i class="bi bi-chevron-down"></i></span>
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown0">
            <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/e-invoicing-for-taxpayers/">{{ 'new.E-invoicing for Taxpayers' | i18next }}</a></li>
            <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/kb/registration/">{{ 'new.Registering with IRIS IRP' | i18next }}</a></li>
            <li class="dropdown dropend"><a class="dropdown-item" href="javascript:;" id="navbarDropdown101" role="button"  (mouseenter)="isMenuSelected = 'ForWhom'" data-bs-toggle="dropdown"
              aria-expanded="false">{{ 'new.Solutions' | i18next }} <span class="">></span></a>
            <ul class="dropdown-menu submenu" aria-labelledby="navbarDropdown101">
              <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/irp-web-portal/">{{ 'new.Web Portal' | i18next }}</a></li>
              <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/excel-utility/">{{ 'new.Excel Utility' | i18next }}</a></li>
              <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/kbtopic/developerconsole/">{{ 'new.API Integrators' | i18next }}</a></li>
              <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/erp-billing-systems/">{{ 'new.ERP / Billing Systems' | i18next }}</a></li>
              <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/mobile-web-application/">{{ 'new.Mobile Web Application' | i18next }}</a></li>
            </ul>
          </li>
            <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/kbtopic/developerconsole/">{{ 'new.Product Guide' | i18next }}</a></li>
            <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/pricing-plan/">{{ 'new.Pricing Plan' | i18next }}</a></li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link" (mouseenter)="isMenuSelected = 'Developers'" [class.active]="isMenuSelected == 'Developers'" href="javascript:;"id="navbarDropdown" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            {{ 'new.API Developers' | i18next }} <span><i class="bi bi-chevron-down"></i></span>
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
          <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/kb/api-integrator-registration/" >{{ 'new.Registering with IRIS IRP' | i18next }}</a></li>
          <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/kbtopic/developerconsole/" >{{ 'new.API Documentation' | i18next }}</a></li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link " (mouseenter)="isMenuSelected = 'Help'" [class.active]="isMenuSelected == 'Help'" href="javascript:;" id="navbarDropdown0" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            {{ 'new.Help' | i18next }} <span><i class="bi bi-chevron-down"></i></span>
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown0">
            <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/faq-powered-by-irisirp/">{{ 'new.FAQ' | i18next }}</a></li>
            <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/public-keys/">{{ 'new.Public Keys' | i18next }} <span class="blink_me">{{ 'new.Updated' | i18next }}</span></a></li>
            <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/validation-rules/">{{ 'new.Validation Rules' | i18next }}</a></li>
            <li class="dropdown dropend"><a class="dropdown-item" href="javascript:;" id="navbarDropdown101" role="button"  (mouseenter)="isMenuSelected = 'ForWhom'" data-bs-toggle="dropdown"
              aria-expanded="false">{{ 'new.E-invoice Schema' | i18next }} <span class="">></span></a>
            <ul class="dropdown-menu submenu" aria-labelledby="navbarDropdown101">
              <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/general-master/">{{ 'new.General Master' | i18next }}</a></li>
              <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/irn-2/">{{ 'new.IRN' | i18next }}</a></li>
            </ul>
          </li>
            <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/kb/troubleshooting-common-errors/">{{ 'new.Resolving Common Errors' | i18next }}</a></li>
            <li><a class="dropdown-item"  href="https://selfservice.gstsystem.in/ReportIssue.aspx">{{ 'new.Report Issue' | i18next }}</a></li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link" (mouseenter)="isMenuSelected = 'Search'" [class.active]="isMenuSelected == 'Search'" href="javascript:;"id="navbarDropdown" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            {{ 'label.Search' | i18next }} <span><i class="bi bi-chevron-down"></i></span>
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
          <li><a class="dropdown-item"  (click)="redirectTo('master-search')" >{{ 'label.Masters' | i18next }}</a></li>
          <li><a class="dropdown-item" (click)="redirectTo('enablement-status')"  >{{ 'new.EInvoice Enablement Status' | i18next }}</a></li>
          <li><a class="dropdown-item" (click)="redirectTo('verify-qrcode')" >{{ 'new.Verify QR Code' | i18next }}</a></li>
          <li><a class="dropdown-item" (click)="redirectTo('verify-signedInvoice')" >{{ 'new.Verify Signed E-Invoice' | i18next }}</a></li>
          <li><a class="dropdown-item" (click)="redirectTo('gstin-status')" >{{ 'new.Gstin Status' | i18next }}</a></li>


          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link" (mouseenter)="isMenuSelected = 'Resources'" [class.active]="isMenuSelected == 'Resources'" href="javascript:;"id="navbarDropdown" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            {{ 'new.Resources' | i18next }} <span><i class="bi bi-chevron-down"></i></span>
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
          <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/einvoice-mandate/" >{{ 'new.E-Invoice Mandat' | i18next }}</a></li>
          <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/irp-for-e-invoicing/" >{{ 'new.IRIS IRP for E-Invoicing' | i18next }}</a></li>
          <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/notifications/" >{{ 'new.GST Notifications' | i18next }}</a></li>
          <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/blog/" >{{ 'new.Blog' | i18next }}</a></li>
          <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/news/" >{{ 'new.E-invoice and GST in News' | i18next }}</a></li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link" (mouseenter)="isMenuSelected = 'About'" [class.active]="isMenuSelected == 'About'" href="javascript:;"id="navbarDropdown" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            {{ 'new.About Us' | i18next }} <span><i class="bi bi-chevron-down"></i></span>
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
          <li><a class="dropdown-item"  href="https://irisirp.com/" >{{ 'new.About IRIS IRP' | i18next }}</a></li>
          <li><a class="dropdown-item"  href="https://irisgst.com/" >{{ 'new.About IRIS GST' | i18next }}</a></li>
          <li><a class="dropdown-item"  href="https://www.irisbusiness.com/" >{{ 'new.About IRIS Business' | i18next }}</a></li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link" (mouseenter)="isMenuSelected = 'Contact Us'" [class.active]="isMenuSelected == 'Contact Us'" href="https://einvoice6.gst.gov.in/content/contact-us/" id="navbarDropdown" role="button"
            aria-expanded="false">
            {{ 'new.Contact Us' | i18next }}
          </a></li>

        <li class="nav-item highDiv pl0">
          <a class="nav-link" [class.active]="isMenuSelected == 'Sandbox'" class="btn btn-primary rounded p13 btnContact1"  href="https://sandbox.einvoice6.gst.gov.in/signup" target="_blank" >{{ 'new.Sandbox' | i18next }}</a>
        </li>
        <li class="nav-item highDiv pl0">
          <a class="nav-link" [class.active]="isMenuSelected == 'Login'" class="btn btn-primary rounded p13 btnContact"  (click)="redirectToLogin()">{{ 'label.Login' | i18next }}</a>
        </li>
        <li class="nav-item dropdown pl0">
          <a class="btnFont" href="javascript:;" id="navbarDropdown02" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">A </a>
          <ul class="dropdown-menu t60" aria-labelledby="navbarDropdown02">
            <li><a (click)="changeSizeByBtn('20px')" class="mr dropdown-item text-center" href="javascript:;">A<sup>+</sup></a></li>
            <li><a href="javascript:;" class="dropdown-item text-center" (click)="changeSizeByBtn('unset')">A<sup>-</sup></a></li>
          </ul>
        </li>
        <li class="nav-item dropdown pl0">
          <!-- <a class="btnLang hideBtnLang" href="javascript:;" id="navbarDropdown02" role="button" data-bs-toggle="dropdown"
            aria-expanded="false"><img src="../../../../assets/images/landingpage/default.png" class="btnLangImg"> </a>
           -->
            <select class="langSelection" aria-labelledby="navbarDropdown02" (change)="changeLanguage($event)"  [value]="i18nextSvc.language">
              <option value="en">English</option>
              <option value="marathi">Marathi</option>
              <!-- <option value="tamil">Tamil</option> -->
              <option value="telugu">Telugu</option>
            </select>

        </li>


      </ul>

    </div>
  </div>
</nav>
<div class="container-fluid px-0" style="position: relative;">
  <marquee class="marque mt-0 font-size-14" *ngIf="marquee && marquee.newsBody">
    {{marquee && marquee.newsBody ? marquee.newsBody :'N/A'}} <a style="color: #007ad9; font-size: 14px;font-weight: 500;"
    *ngIf="marquee.moreInfoExternalLink" [href]="marquee.moreInfoExternalLink" target="_blank"
   class="font-size-14">from here</a>
  </marquee>
</div>

<ng-template #template>
  <div class="modal-body">
    <p>{{ 'new.The link shall take you' | i18next }}</p>
  </div>
  <div class="modal-footer">
    <a class="text_black text-decoration-none"  (click)="modalRef?.hide()">{{ 'new.Skip' | i18next }}</a>

    <button
      type="button"
      class="btn btn_primary_theme font-size-14 ms-2"
      (click)="redirectToPage()"
    >
    {{ 'new.Ok' | i18next }}
    </button>

  </div>
</ng-template>
