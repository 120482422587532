import { Injectable } from "@angular/core";
import i18next from "i18next";

@Injectable({
    providedIn: 'root'
})
export class ConstantService {
    public globalSupply = [
        { code: i18next.t('constant.globalSupply.Business To Business'), desc: 'B2B' },
        { code: i18next.t('constant.globalSupply.SEZ with payment'), desc: 'SEZWP' },
        { code: i18next.t('constant.globalSupply.SEZ without payment'), desc: 'SEZWOP' },
        { code: i18next.t('constant.globalSupply.Export with Payment'), desc: 'EXPWP' },
        { code: i18next.t('constant.globalSupply.Export without Payment'), desc: 'EXPWOP' },
        { code: i18next.t('constant.globalSupply.Deemed Export'), desc: 'DEXP' },
    ];
    public globalconfirmOptions = [
        { label: i18next.t('constant.globalconfirmOptions.Yes'), code: 'Y' },
        { label: i18next.t('constant.globalconfirmOptions.No'), code: 'N' }
    ];
    public globalDocumentType = [
        { code: 'INV', desc: i18next.t('constant.globalDocumentType.Invoice') },
        { code: 'DBN', desc: i18next.t('constant.globalDocumentType.Debit Note') },
        { code: 'CRN', desc: i18next.t('constant.globalDocumentType.Credit Note') }
    ];
    public globalstateWebForm = [
        { code: "01", name: i18next.t('constant.globalstateWebForm.JAMMU AND KASHMIR'), pincode: "180-194" },
        { code: "02", name: i18next.t('constant.globalstateWebForm.HIMACHAL PRADESH'), pincode: "171-177" },
        { code: "03", name: i18next.t('constant.globalstateWebForm.PUNJAB'), pincode: "140-160" },
        { code: "04", name: i18next.t('constant.globalstateWebForm.CHANDIGARH'), pincode: "160-160" },
        { code: "05", name: i18next.t('constant.globalstateWebForm.UTTARAKHAND'), pincode: "244-263" },
        { code: "06", name: i18next.t('constant.globalstateWebForm.HARYANA'), pincode: "121-136" },
        { code: "07", name: i18next.t('constant.globalstateWebForm.DELHI'), pincode: "110-110" },
        { code: "08", name: i18next.t('constant.globalstateWebForm.RAJASTHAN'), pincode: "301-345" },
        { code: "09", name: i18next.t('constant.globalstateWebForm.UTTAR PRADESH'), pincode: "201-285" },
        { code: 10, name: i18next.t('constant.globalstateWebForm.BIHAR'), pincode: "800-855" },
        { code: 11, name: i18next.t('constant.globalstateWebForm.SIKKIM'), pincode: "737-737" },
        { code: 12, name: i18next.t('constant.globalstateWebForm.ARUNACHAL PRADESH'), pincode: "790-792" },
        { code: 13, name: i18next.t('constant.globalstateWebForm.NAGALAND'), pincode: "797-798" },
        { code: 14, name: i18next.t('constant.globalstateWebForm.MANIPUR'), pincode: "795-795" },
        { code: 15, name: i18next.t('constant.globalstateWebForm.MIZORAM'), pincode: "796-796" },
        { code: 16, name: i18next.t('constant.globalstateWebForm.TRIPURA'), pincode: "799-799" },
        { code: 17, name: i18next.t('constant.globalstateWebForm.MEGHALAYA'), pincode: "793-794" },
        { code: 18, name: i18next.t('constant.globalstateWebForm.ASSAM'), pincode: "781-788" },
        { code: 19, name: i18next.t('constant.globalstateWebForm.WEST BENGAL'), pincode: "700-743" },
        { code: 20, name: i18next.t('constant.globalstateWebForm.JHARKHAND'), pincode: "813-835" },
        { code: 21, name: i18next.t('constant.globalstateWebForm.ODISHA'), pincode: "751-770" },
        { code: 22, name: i18next.t('constant.globalstateWebForm.CHHATTISGARH'), pincode: "490-497" },
        { code: 23, name: i18next.t('constant.globalstateWebForm.MADHYA PRADESH'), pincode: "450-488" },
        { code: 24, name: i18next.t('constant.globalstateWebForm.GUJARAT'), pincode: "360-396" },
        { code: 25, name: i18next.t('constant.globalstateWebForm.DAMAN AND DIU'), pincode: "362-362" },
        { code: 26, name: i18next.t('constant.globalstateWebForm.DADRA AND NAGAR HAVELI'), pincode: "396-396" },
        { code: 27, name: i18next.t('constant.globalstateWebForm.MAHARASHTRA'), pincode: "400-445" },
        { code: 29, name: i18next.t('constant.globalstateWebForm.KARNATAKA'), pincode: "560-591" },
        { code: 30, name: i18next.t('constant.globalstateWebForm.GOA'), pincode: "403-403" },
        { code: 31, name: i18next.t('constant.globalstateWebForm.LAKSHADWEEP'), pincode: "682-682" },
        { code: 32, name: i18next.t('constant.globalstateWebForm.KERALA'), pincode: "670-695" },
        { code: 33, name: i18next.t('constant.globalstateWebForm.TAMIL NADU'), pincode: "600-643" },
        { code: 34, name: i18next.t('constant.globalstateWebForm.PUDUCHERRY'), pincode: "533-533" },
        { code: 35, name: i18next.t('constant.globalstateWebForm.ANDAMAN AND NICOBAR'), pincode: "744-744" },
        { code: 36, name: i18next.t('constant.globalstateWebForm.TELANGANA') },
        { code: 37, name: i18next.t('constant.globalstateWebForm.ANDHRA PRADESH'), pincode: "500-535" },
        { code: 38, name: i18next.t('constant.globalstateWebForm.LADAKH') },
        { code: 97, name: i18next.t('constant.globalstateWebForm.OTHER TERRITORY') },
        { code: 96, name: i18next.t('constant.globalstateWebForm.OTHER COUNTRY') },
    ];
    public globalUnits = [
        { code: 'BAG', desc: i18next.t('constant.globalUnits.BAGS')},
        { code: 'BAL', desc: i18next.t('constant.globalUnits.BALE') },
        { code: 'BDL', desc: i18next.t('constant.globalUnits.BUNDLES') },
        { code: 'BKL', desc: i18next.t('constant.globalUnits.BUCKLES') },
        { code: 'BOU', desc: i18next.t('constant.globalUnits.BILLION OF UNITS') },
        { code: 'BOX', desc: i18next.t('constant.globalUnits.BOX')},
        { code: 'BTL', desc: i18next.t('constant.globalUnits.BOTTLES') },
        { code: 'BUN', desc: i18next.t('constant.globalUnits.BUNCHES')},
        { code: 'CAN', desc: i18next.t('constant.globalUnits.CANS')},
        { code: 'CCM', desc: i18next.t('constant.globalUnits.CUBIC CENTIMETERS') },
        { code: 'CMS', desc: i18next.t('constant.globalUnits.CENTIMETERS')},
        { code: 'CBM', desc: i18next.t('constant.globalUnits.CUBIC METERS') },
        { code: 'CTN', desc: i18next.t('constant.globalUnits.CARTONS') },
        { code: 'DOZ', desc: i18next.t('constant.globalUnits.DOZENS') },
        { code: 'DRM', desc: i18next.t('constant.globalUnits.DRUMS')},
        { code: 'GGK', desc: i18next.t('constant.globalUnits.GREAT GROSS')},
        { code: 'GMS', desc: i18next.t('constant.globalUnits.GRAMMES') },
        { code: 'GRS', desc: i18next.t('constant.globalUnits.GROSS') },
        { code: 'GYD', desc: i18next.t('constant.globalUnits.GROSS YARDS')},
        { code: 'KGS', desc: i18next.t('constant.globalUnits.KILOGRAMS')},
        { code: 'KLR', desc: i18next.t('constant.globalUnits.KILOLITRE') },
        { code: 'KME', desc: i18next.t('constant.globalUnits.KILOMETRE') },
        { code: 'LTR', desc: i18next.t('constant.globalUnits.LITRES') },
        { code: 'MLS', desc: i18next.t('constant.globalUnits.MILLI LITRES') },
        { code: 'MLT', desc: i18next.t('constant.globalUnits.MILILITRE') },
        { code: 'MTR', desc: i18next.t('constant.globalUnits.METERS') },
        { code: 'MTS', desc: i18next.t('constant.globalUnits.METRIC TON') },
        { code: 'NOS', desc: i18next.t('constant.globalUnits.NUMBERS') },
        { code: 'OTH', desc: i18next.t('constant.globalUnits.OTHERS') },
        { code: 'PAC', desc: i18next.t('constant.globalUnits.PACKS') },
        { code: 'PCS', desc: i18next.t('constant.globalUnits.PIECES') },
        { code: 'PRS', desc: i18next.t('constant.globalUnits.PAIRS') },
        { code: 'QTL', desc: i18next.t('constant.globalUnits.QUINTAL') },
        { code: 'ROL', desc: i18next.t('constant.globalUnits.ROLLS') },
        { code: 'SET', desc: i18next.t('constant.globalUnits.SETS') },
        { code: 'SQF', desc: i18next.t('constant.globalUnits.SQUARE FEET')},
        { code: 'SQM', desc: i18next.t('constant.globalUnits.SQUARE METERS') },
        { code: 'SQY', desc: i18next.t('constant.globalUnits.SQUARE YARDS') },
        { code: 'TBS', desc: i18next.t('constant.globalUnits.TABLETS') },
        { code: 'TGM', desc: i18next.t('constant.globalUnits.TEN GROSS') },
        { code: 'THD', desc: i18next.t('constant.globalUnits.THOUSANDS') },
        { code: 'TON', desc: i18next.t('constant.globalUnits.TONNES') },
        { code: 'TUB', desc: i18next.t('constant.globalUnits.TUBES') },
        { code: 'UGS', desc: i18next.t('constant.globalUnits.US GALLONS') },
        { code: 'UNT', desc: i18next.t('constant.globalUnits.UNITS') },
        { code: 'YDS', desc: i18next.t('constant.globalUnits.YARDS') },
    ];
}