import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import { SignupComponent } from './loginSignup/signup/signup.component';
import { LoginComponent } from './loginSignup/login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SetPasswordComponent } from './loginSignup/set-password/set-password.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxPaginationModule } from 'ngx-pagination';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ChartsModule } from 'ng2-charts';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { NgSelectModule } from '@ng-select/ng-select';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { DashboardComponent } from './shared/dashboard/dashboard.component';
import { AuthHeaderComponent } from './auth/components/auth-header/auth-header.component';
import { ApiIntegrationDashboardComponent } from './shared/api-integration-dashboard/api-integration-dashboard.component';
import { EmailTemplateComponent } from './email-template/email-template.component';
import { ApiIntegratorDashboardComponent } from './api-integrator-dashboard/api-integrator-dashboard.component';
import { TaxpayerDashboardComponent } from './taxpayer-dashboard/taxpayer-dashboard.component';
import { TaxPayerDashboardService } from './shared/services/taxpayer-dashboard.service';
import { TokenInterceptor } from './shared/services/token-interceptor';
import { NgxCaptchaModule } from 'ngx-captcha';
import { GSTDropdownService } from './shared/services/gstDropdown.service';
import { ChangePasswordPostLoginComponent } from './loginSignup/change-password-post-login/change-password-post-login.component';
import { UtilityService } from './taxPayer/utilities/utilities.service';
import { ResetForgetPasswordComponent } from './loginSignup/reset-forget-password/reset-forget-password.component';
import { PasswordExpiredSetPasswordComponent } from './loginSignup/password-expired-set-password/password-expired-set-password.component';
import { SetPasswordSignupComponent } from './loginSignup/set-password-signup/set-password-signup.component';
import { PlanSubscriptionComponent } from './taxPayer/plan-subscription/plan-subscription.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { GenerateContentComponent } from './generate-content/generate-content.component';
import { ApiIntegratorWhomeContentComponent } from './api-integrator-whome-content/api-integrator-whome-content.component';
import { PlansPreLoginComponent } from './plans-pre-login/plans-pre-login.component';
import { MastersPreLoginComponent } from './masters-pre-login/masters-pre-login.component';
import { TaxpayerPreLoginComponent } from './taxpayer-pre-login/taxpayer-pre-login.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { HelpContentComponent } from './help-content/help-content.component';
import { GoogleAnalyticsService } from './shared/services/google-analytics.service';
import { InvoiceEnablementStatusComponent } from './pre-login-utility/invoice-enablement-status/invoice-enablement-status.component';
import { VerifyEinvoicePreloginComponent } from './pre-login-utility/verify-einvoice-prelogin/verify-einvoice-prelogin.component';
import { VerifyQrPreloginComponent } from './pre-login-utility/verify-qr-prelogin/verify-qr-prelogin.component';
import { GstinStatusPreloginComponent } from './pre-login-utility/gstin-status-prelogin/gstin-status-prelogin.component';
import { HsnSearchPreloginComponent } from './pre-login-utility/hsn-search-prelogin/hsn-search-prelogin.component';
import { MastersCodeComponent } from './pre-login-utility/masters-code/masters-code.component';
import { ContentComponentComponent } from './content-component/content-component.component';
import { AuthLoginComponent } from './auth/components/auth-login/auth-login.component';
import { I18NextModule } from 'angular-i18next';
import { I18N_PROVIDERS } from './app.i18n';
import { ConstantService } from './config/constant.service';
import { SetupMFAComponent } from './setup-mfa/setup-mfa.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    SetPasswordComponent,
    DashboardComponent,
    AuthHeaderComponent,
    ApiIntegrationDashboardComponent,
    EmailTemplateComponent,
    ApiIntegratorDashboardComponent,
    TaxpayerDashboardComponent,
    ChangePasswordPostLoginComponent,
    ResetForgetPasswordComponent,
    PasswordExpiredSetPasswordComponent,
    SetPasswordSignupComponent,
    PlanSubscriptionComponent,
    ContactUsComponent,
    GenerateContentComponent,
    ApiIntegratorWhomeContentComponent,
    PlansPreLoginComponent,
    MastersPreLoginComponent,
    TaxpayerPreLoginComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    HelpContentComponent,
    InvoiceEnablementStatusComponent,
    VerifyEinvoicePreloginComponent,
    VerifyQrPreloginComponent,
    GstinStatusPreloginComponent,
    HsnSearchPreloginComponent,
    MastersCodeComponent,
    ContentComponentComponent,
    AuthLoginComponent,
    SetupMFAComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxPaginationModule,
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    ChartsModule,
    NgxQRCodeModule,
    NgSelectModule,
    CollapseModule.forRoot(),
    NgxCaptchaModule,
    I18NextModule.forRoot(),
  ],
  providers: [
    TaxPayerDashboardService,
    GSTDropdownService,
    UtilityService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    GoogleAnalyticsService,
    I18N_PROVIDERS,
    ConstantService,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
