<div>

    <!-- <div class="plan-btn">
        <button type="button" class="btn btn_gradient font-size-16" id="planModalOpen" (click)="planTemplate.show()">
          My Plans
        </button>
      </div> -->
    <div class="get_started_box startBox">
        <h2 class="font-size-26">
            {{ 'new.Welcome to' | i18next }} <span class="fw-bold">{{ 'new.IRIS IRP' | i18next }}!</span>
        </h2>
        <h6 *ngIf="hideForSandbox">{{ 'new.Sandbox for Taxpayer' | i18next }}</h6>
        <p class="text_green font-size-18">{{ 'new.Get Started' | i18next }}</p>
        <button type="button" class="btn btn_primary_theme iconColor font-size-16" (click)="addGSTIn()">
            <i class="bi bi-plus-lg addIcon pe-2"></i> {{ 'new.ADD GSTINs' | i18next }}
        </button>
    </div>
    <div style="text-align:left" class="offset-3 mt-5 col-8">
      
            <div>
                <i class="bi bi-info-circle-fill icon-helptext font-size-16  "></i> {{ 'label.A one time activity' | i18next }}<br>
                <ul class="mt-2">
                    <li> {{ 'label.GSTINs enabled for einvoicing' | i18next }} <a target="_blank" href="#" onclick="window.open('https://irisirp.com/e-invoice-applicability-checker/');return false;">{{ 'label.enablement status' | i18next }}.</a></li>
                    <li> {{ 'label.Keep handy the email' | i18next }}</li>
                    <li> {{ 'label.To complete registration' | i18next }}</li>
                </ul>
           
        </div>

    </div>
</div>
<div bsModal #planTemplate="bs-modal" class="modal fade show" data-bs-backdrop="static" id="exampleModal" tabindex="-1"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{ 'new.Subscription' | i18next }}</h5>
                <button type="button" (click)="planTemplate.hide()" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-6">
                        <div class="subscription-box">
                            <div class="subscription-title">
                                <h3 class="current-plan">{{ 'new.Free' | i18next }}</h3>
                            </div>
                            <div class="ribbon ribbon-top-right"><span class="current-plan">{{ 'new.GSTINs' | i18next }}Current Plan</span></div>
                            <div class="subscription-content">
                                <ul class="details">
                                    <li>{{ 'new.Generation of IRN' | i18next }}
                                    </li>
                                    <li>{{ 'new.Cancellation of IRN' | i18next }}</li>
                                    <li>{{ 'new.Validations to check' | i18next }}</li>
                                    <li>{{ 'new.Print e-Invoice' | i18next }}</li>
                                    <li>{{ 'new.GSTINs' | i18next }}</li>
                                    <li>{{ 'new.Onboarding of GSPs' | i18next }} </li>
                                    <li>{{ 'new.E-Invoice APIs communicate' | i18next }}</li>
                                    <li>{{ 'new.Generation of E-way Bill' | i18next }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="subscription-box">
                            <div class="subscription-title">
                                <h3 class="recommended-plan">{{ 'new.Premium' | i18next }}</h3>
                            </div>
                            <div class="ribbon ribbon-top-right"><span class="recommended-plan">{{ 'new.Recommended' | i18next }}</span></div>
                            <div class="subscription-content">
                                <ul class="details">
                                    <li>{{ 'new.Bulk Action(Cancellation, Print, Email)' | i18next }}
                                    </li>
                                    <li>{{ 'new.Customized Template for Print' | i18next }}</li>
                                    <li>{{ 'new.Print with Esign' | i18next }}</li>
                                    <li>{{ 'new.User configuration settings on UI' | i18next }}</li>
                                    <li>{{ 'new.MIS reports' | i18next }}
                                    </li>
                                    <li>{{ 'new.Share invoices with recipient' | i18next }}</li>
                                    <li>{{ 'new.Store invoices for longer period' | i18next }}</li>
                                    <li>{{ 'new.Alerts to buyer' | i18next }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #whtsnew>
    <div class="modal-content">
        <div class="modal-header" style="background-color:#252525;cursor: pointer;">
            <h4 class="" style="color: #ffffff;">{{ 'new.Dear Taxpayer' | i18next }}</h4>
            <span class="close" style="color: #ffffff;" (click)="modalRef?.hide()">&times;</span>
        </div>
        <div class="modal-body text-center">

            <!-- <img src="assets/images/popUpImage.png"  class="news-img" alt="Image" class="img-fluid" style="width:50%;align-items: center;"/> -->
            <img [src]="newsDataPopup.moreInfoImageLink"
                *ngIf="newsDataPopup.moreInfoImageLink && newsDataPopup.moreInfoImageLink != null" class="news-img"
                alt="Image" class="img-fluid" style="width:50%;align-items: center;" />
        </div>
        <div class="modal-body py-0">
            <b>
                <h4 class="blackColor">{{newsDataPopup.newsHeader}}</h4>
            </b>
            <div class="d-block main-content">
                <!-- <div *ngFor="let news of newsDataPopup"> -->

                <!-- <img src="../../../../assets/images/API-integrator-home-new.png" alt="Image" class="img-fluid" style="background-color: #b2fcff;width:70%;"> -->
                <div class="content-text p-4">
                    <!-- <h4 class="mb-4">{{newsDataPopup.newsHeader}}</h4> -->
                    <p class="mb-4" [innerHtml]="newsDataPopup.newsBody">{{newsDataPopup.newsBody}}</p>
                    <p> <a [href]="newsDataPopup.moreInfoExternalLink"
                            *ngIf="newsDataPopup.moreInfoExternalLink && newsDataPopup.moreInfoExternalLink != null"
                            target="_blank" class="text-decoration-none" data-dismiss="modal">{{ 'label.From here' | i18next }}</a></p>
                    <div class="text-center">
                        <div class="ml-auto">

                            <!-- <a (click)="modalRef?.hide()" class="btn btn_primary_theme font-size-16 px-4">Close</a> -->
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</ng-template>