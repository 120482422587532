import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../login.service';
import { ToastrService } from 'ngx-toastr';
import { ThisReceiver } from '@angular/compiler';
import { Router } from '@angular/router';
import { API_STATUS } from 'src/app/constant';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { switchLanguage } from 'src/app/app.i18n';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  signUpForm: FormGroup;
  OTPForm: FormGroup;
  signUpSuccess = false;
  acceptTermsAndConditions = true;
  disabled = false;
  otpCheck: boolean = false;
  otpDetails = {
    otp: null
  }
  signUpDetails: any;
  submitted: boolean;
  fieldValid: boolean;
  hideForSandbox: boolean = false;
  // captcha: any;
  constructor(
    @Inject(I18NEXT_SERVICE) public i18nextSvc: ITranslationService,
    private formGroup: FormBuilder,
    private loginService: LoginService,
    private toastrService: ToastrService,
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.signUpForm = this.formGroup.group({
      role: ['TAXPAYER', [Validators.required]],
      userName: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.pattern(/^(?:\d{10}|\w+[\.-]?\w+@\w+[\.-]?\w+[\.-]?\w+\.\w{2,3})$/)]],
      // mobileNumber: [null, [Validators.required,Validators.minLength(10), Validators.maxLength(10)]],
    });

    this.OTPForm = this.formGroup.group({
      otp: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  /**
   * Initialize component
   */
  ngOnInit(): void {
    if (environment.env === 'sandbox'){
      this.hideForSandbox = true;
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.signUpForm.controls;
  }

  /**
   * create new user account for gstin
   * @returns void
   */


  async signUp() {
    this.submitted = true;

    if (this.signUpForm.valid) {
      if (this.acceptTermsAndConditions) {
        this.signUpDetails = {
          userName: this.signUpForm.value.userName,
          role: this.signUpForm.value.role,
          userId: this.signUpForm.value.email.toLowerCase(),
          termsAndCondition: this.acceptTermsAndConditions
        }


          try {
            const res = await this.loginService.createUserAccount(this.signUpDetails);
            if (res.status === API_STATUS.success) {
              // await this.sendOTP();
              this.trackAnalytics('SIGNUP_INITIATED');
              this.toastrService.success(res.message ? res.message : 'User signed up successfully', '', { enableHtml: true });
              this.signUpSuccess = true;
              this.submitted = false;
            } else if (res.status === API_STATUS.error) {
              if (res && res.response && res.response.signupStatus) {
                switch (res.response.signupStatus) {
                  case "0":
                    this.sendOTP();
                    break;
                  case "1":
                    this.toastrService.success(res.message, '', { enableHtml: true });
                    this.router.navigateByUrl('/login');
                    break;
                  case "2":
                    this.router.navigateByUrl(`/set-password-post-signup/${this.signUpDetails.userId}/${this.signUpDetails.userName}`);
                    this.toastrService.success(res.message, '', { enableHtml: true });

                    break;
                  case "3":
                    this.toastrService.success(res.message, '', { enableHtml: true });
                    this.router.navigateByUrl('/login');
                    break;
                  default:
                }
              } else {
                this.toastrService.error(res.message);

              }

            }
            if (res.status === 'FAILURE') {
              let errMessage = '';
              if (res.fieldErrors) {
                res.fieldErrors.forEach((err: any) => {
                  errMessage += err.message + '</br>';
                });
              }
              this.toastrService.error(errMessage, res.message, { enableHtml: true });
              return;
            }
          } catch (e) {
            this.toastrService.error('SignUp fails');
          }

      } else {
        this.toastrService.error('Please accept Terms and Conditions');
      }

    }

  }

  maskMobileNumber() {
    if (this.signUpDetails.userId) {
      this.signUpDetails.userId = this.signUpDetails.userId + '';
      return this.signUpDetails.userId.replace(this.signUpDetails.userId.substring(2, 8), 'XXXXX');
    }
  }

  async sendOTP() {
    let res: any = await this.loginService.genetateOTP(this.signUpDetails);
    if (res.status === API_STATUS.success) {
      this.toastrService.success('OTP sent to ' + this.signUpDetails.userId + ' successfully', '', { enableHtml: true });
    }

  }

  /**
  * OTP verification and send to complete-reg page if otp verified
  */
  async verifyOTP() {
    if (this.OTPForm.invalid) {
      return;
    }
    let OTPDetails = { userId: this.signUpDetails.userId, otp: this.OTPForm.value.otp.trim() };

    let res: any = await this.loginService.verifyOTP(OTPDetails);
    if (res.status === API_STATUS.success) {
      this.trackAnalytics('SIGNUP_OTP_VERIFIED');
      this.router.navigateByUrl(`/set-password-post-signup/${this.signUpDetails.userId}/${this.signUpDetails.userName}`);
    } else {
      this.toastrService.error(res.message);

    }
  }
  checkOTP() {
    var otp = this.otpDetails.otp;
    if (otp.length === 6) {
      this.otpCheck = true;
    } else {
      this.otpCheck = false;
    }
  }
  trackAnalytics(btnName) {
    this.googleAnalyticsService.trackAnalytics(btnName);
  }

  redirectToPrivacy() {
    window.scrollTo(0, 0);
    this.router.navigate([`/privacy`]);
  }
  redirectToTerms() {
    window.scrollTo(0, 0);
    this.router.navigate([`/terms-conditions`]);
  }
  toolTipMsg: string = '';
  toolTip() {
    if (this.signUpForm.controls.role.value === 'API_INTEGRATOR') {
      this.toolTipMsg = 'use work email else prod access will not be granted';
      return this.toolTipMsg;
    }else{
      return '';
    }
  }
  changeLanguage(event: Event) {
    const target = event.target as HTMLSelectElement;
    switchLanguage(target.value);
  }
}
