import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { LocalStorageKey, userType } from 'src/app/app.config';
import { SharedService } from 'src/app/shared/shared.service';
import { TaxPayerDashboardService } from 'src/app/shared/services/taxpayer-dashboard.service';
import { GSTDropdownService } from 'src/app/shared/services/gstDropdown.service';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { ProfileService } from '../../profile/profile.service';
import { switchLanguage } from 'src/app/app.i18n';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isGSTInAvailable: boolean;
  gstinList = [];
  gstinFilterData: any
  menuExpanded = false;
  showSection = false;
  mouseOvered;
  generateBtn;
  mouseOveredGenerate;
  mouseOveredTools;
  mouseOveredManage;
  mouseOveredAnalytics;
  mouseOverPurchases;
  gstList = []
  mouseOveredSettings;
  mouseOveredNotifications;
  @ViewChild('test') test;
  @ViewChild('dropdown') dropdown;
  userName: any;
  userId: any;
  isGSTINAvailable;
  // newsList: any;
  marquee: any;
  announcementList: any;
  showNotifications: boolean;
  activeMenu: string;
  newsList: any = [];
  newsData = [];
  news = [];
  selectedGstin: any;
  selectedPlan: any;
  isUpArrowVisible: boolean = true;
  isDownArrowVisible: boolean = false;
  isFooterVisible: boolean = false;

  constructor(
    @Inject(I18NEXT_SERVICE) public i18nextSvc: ITranslationService,
    private router: Router, private eRef: ElementRef,
    private profileService: ProfileService,
    private sharedService: SharedService,
    private taxPayerDashboardService: TaxPayerDashboardService,
    private gstDropdownService: GSTDropdownService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.i18nextSvc.language = localStorage.getItem('lang');
    document.addEventListener('click', this.offClickHandler.bind(this));
  }

  ngOnInit(): void {
    this.selectedGstin = localStorage.getItem("SELECTED_GSTIN");
    const userDetails = JSON.parse(this.sharedService.getCookie('userDetails'));
    this.userName = userDetails.userName;
    //Refactor: Change to localStorage
    this.getUserName();
    this.getNewsAndUpdates();
    this.getNewsData();
    this.userType = localStorage.getItem('UserRole');
    this.selectedPlan = localStorage.getItem('gstinuserPlan');
  }
  getUserName() {
    const userDetails = JSON.parse(this.sharedService.getCookie('userDetails'));
    this.userName = userDetails.userName;
    this.userId = userDetails.userId;

  }
  offClickHandler(event: any) {
    if (!this.test.nativeElement.contains(event.target)) { // check click origin
      this.resetFlags();
    }
  }

  toggleMenu() {
    setTimeout(() => {
      this.menuExpanded = !this.menuExpanded;
    }, 0);
  }
  toggle() {

  }
  userType: any;
  mouseOver(param) {
    this.userType = localStorage.getItem('UserRole');
    if (this.userType === null) {
      localStorage.setItem('UserRole', 'false');
    }

    this.mouseOvered = true;
    this.resetFlags();
    this[param] = true;


  }
  resetFlags() {
    this.mouseOveredGenerate = false;
    this.mouseOveredAnalytics = false;
    this.mouseOveredManage = false;
    this.mouseOveredAnalytics = false;
    this.mouseOveredTools = false;
    this.mouseOverPurchases = false;
    this.generateBtn = false;
    this.mouseOveredSettings = false;
    this.mouseOveredNotifications = false;

  }
  mouseOut(param) {
    this.mouseOvered = false;
    this[param] = false;

    var element = document.getElementById('onSettingId');
    element.style.removeProperty("display");
  }
  // @HostListener('window:click', ['$event'])
  // listenToOutsideClick(event: PointerEvent) {
  //   const target = event.target as HTMLElement;
  //   const isToggler = target.getAttribute('id') === 'navbarDropdown'
  //   if (!this.menuExpanded || isToggler) {
  //     return;
  //   }

  //   this.menuExpanded = false;
  // };
  redirectToPage(feature, isMenu = false) {
    const element = document.getElementById('navbarNavDarkDropdown');
    element.classList.remove('show');
    console.log("Fearyre", feature)
    this.resetFlags();
    this.isGSTINAvailable = JSON.parse(localStorage.getItem(LocalStorageKey.IS_GSTIN_AVAILABLE));
    switch (feature) {
      case 'home':
        this.redirectToLandingPage();
        break;
      case 'einvoice':
        if (this.isGSTINAvailable == true) {
          this.router.navigate(['/irp/gstin/e-invoice/generate']);
          this.activeMenu = 'Generate';
          if (isMenu) {
            this.trackAnalytics('Generate_EInvoice');

          } else {
            this.trackAnalytics('Single_IRN');

          }
        }

        break;

      case 'bulk-generate':
        if (this.isGSTINAvailable == true) {
          this.router.navigate(['/irp/gstin/bulk-operation/upload']);
          this.activeMenu = 'Generate';
          if (isMenu) {
            this.trackAnalytics('Generate_Bulk_Operations');

          } else {
            this.trackAnalytics('Bulk_IRN');

          }

        }
        break;

      case 'view-invoice':
        if (this.isGSTINAvailable == true) {
          this.router.navigate(['/irp/gstin/e-invoice/view']);
          this.activeMenu = 'Manage';

        }
        break;

      case 'verify-invoice':
        if (this.isGSTINAvailable == true) {
          this.router.navigate(['/irp/gstin/e-invoice/verify'])
          this.activeMenu = 'Tools';

        }
        break;
      case 'search-invoice':
        if (this.isGSTINAvailable == true) {
          this.router.navigate(['/irp/gstin/e-invoice/search'])
          this.activeMenu = 'Manage';

        }
        break;
      case 'viewShared-invoice':
        if (this.isGSTINAvailable == true) {
          this.trackAnalytics('MyPurchases_ViewSharedInvoices');

          this.router.navigate(['/irp/gstin/e-invoice/viewShared'])
          this.activeMenu = 'Purchase';

        }
        break;
      case 'sales-overview':
        this.router.navigate(['/irp/gstin/analytics/sales']);
        this.activeMenu = 'Analytics';

        break;
      case 'error-discrepancies':
        this.router.navigate(['/irp/gstin/analytics/errors']);
        this.activeMenu = 'Analytics';

        break;
      case 'hsn-finder':
        this.router.navigate(['/irp/gstin/utilities/hsn']);
        this.activeMenu = 'Tools';

        break;

      case 'masters-finder':
        this.router.navigate(['/irp/gstin/utilities/masters']);
        this.activeMenu = 'Tools';

        break;
      case 'gstin-status':
        this.router.navigate(['/irp/gstin/utilities/gstinStatus']);
        this.activeMenu = 'Tools';

        break;
      case 'einvoice-status':
        this.router.navigate(['/irp/gstin/utilities/einvoiceStatus']);
        this.activeMenu = 'Tools';

        break;
      case 'excel-finder':
        this.router.navigate(['/irp/gstin/utilities/excelEinvoice']);
        this.activeMenu = 'Tools';

        break;
      case 'gstin-finder':
        this.router.navigate(['/irp/gstin/utilities/hsn']);
        this.activeMenu = 'Tools';

        break;
      case 'my-gstin':
        this.router.navigate(['/irp/gstin/profile/gstins']);
        this.activeMenu = null;

        break;
      case 'customer':
        this.router.navigate(['/irp/gstin/profile/customer']);
        this.activeMenu = null;

        break;

      case 'transport':
        this.router.navigate(['/irp/gstin/profile/transporter']);
        this.activeMenu = null;

        break;

      case 'product-service':
        this.router.navigate(['/irp/gstin/profile/product-service']);
        this.activeMenu = null;

        break;
      case 'manage-api-access':
        this.router.navigate(['/irp/gstin/profile/gsp']);
        this.activeMenu = null;

        break;
      case 'manage-users':
        this.trackAnalytics('ManageUsers');

        this.router.navigate(['/irp/gstin/profile/invite-user']);
        this.activeMenu = null;

        break;
    }
  }
  trackAnalytics(btnName) {
    this.googleAnalyticsService.trackAnalytics(btnName);
  }


  redirectToLandingPage() {
    const loggedInUserInfo = JSON.parse(localStorage.getItem(LocalStorageKey.LoggedInUserInfo));
    var isDashboardTrue = localStorage.getItem('ISGstinLength');
    if (loggedInUserInfo) {
      const userTypeInfo = loggedInUserInfo['userType'];
      this.profileService.getGstinsNumberList().subscribe((res) => {
        // console.log('RES D', res);

        if (res && res['status'] !== 0) {
          if (
            userTypeInfo === userType.Taxpayer ||
            isDashboardTrue === 'true'
          ) {
            this.router.navigate(['/irp/gstin/dashboard']);
          } else {
            this.router.navigate(['/irp/api/dashboard']);
          }
        } else {
          this.router.navigate(['/irp/gstin/no-gstin']);
        }
      });
      // if (userTypeInfo === userType.Taxpayer) {
      //   this.router.navigate(['/irp/gstin/dashboard']);
      // } else {
      //   this.router.navigate(['/irp/api/dashboard']);
      // }
    }
  }
  logout() {
    // const lastLoginTime = localStorage.getItem('lastLogin');
    this.sharedService.logout();
    this.router.navigate(['/login']);
    // setTimeout(() => {
    //   localStorage.setItem("lastLogin", lastLoginTime);
    // }, 2000);
  }
  formatProfileName(name) {
    if (name) {
      return name.length < 40 ? name : name.substr(0, 40) + "...";
    }
    return null;
  }
  getNewsAndUpdates() {
    this.taxPayerDashboardService.getNews().subscribe(res => {
      if (res && res['response']) {
        localStorage.setItem(LocalStorageKey.NEWS, JSON.stringify(res['response']));
        this.marquee = res['response']['marquee'][0];

        this.gstDropdownService.marqueeAndNewsSharingData.next('response_recieved')
      }
    })
  }
  resetPassword() {
    this.router.navigate(['/irp/gstin/change-password']);
  }
  enableMFA() {
    this.router.navigate(['/irp/gstin/setup-MFA']);
  }
  toggleAlerts() {
    this.showNotifications = !this.showNotifications;
  }
  getNewsData() {
    const newsResponse = this.taxPayerDashboardService.getNews().subscribe(res => {
      this.newsList = res;

      const newsMarquee = this.newsList.response.marquee;
      this.newsData = this.newsList.response.marquee;
      for (let i = 0; i < this.newsData.length; i++) {
        if (this.newsData[i].userType === 'TAXPAYER') {
          this.news.push(this.newsData[i]);
          break;

        }
      }
    })

  }
  redirectToPlansPage(planType: string) {
    const navigationExtras: NavigationExtras = {
      state: {
        planType: planType
      }
    };
    this.router.navigate(['irp/gstin/dashboard/plans'], navigationExtras);
  }
  onSetting() {
    var element = document.getElementById('onSettingId');
    element?.setAttribute('style', 'display:block !important');
  }

  showFooter() {
    const element1 = document.getElementById('upArrow');
    const element2 = document.getElementById('downArrow');
    const element3 = document.getElementById('footerElem');

    element3.classList.add('showFooter');

    if (element1.getAttribute('class') != 'menuToggle menuToggle_rotate') {

      element1.classList.add('menuToggle_rotate');

    } else {
      element1.classList.remove('menuToggle_rotate');
      element3.classList.remove('showFooter');

    }
  }
  changeLanguage(event: Event) {
    const target = event.target as HTMLSelectElement;
    switchLanguage(target.value);
  }

}


