import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { LoginComponent } from './loginSignup/login/login.component';
import { SetPasswordComponent } from './loginSignup/set-password/set-password.component';
import { SignupComponent } from './loginSignup/signup/signup.component';
import { DashboardComponent } from './shared/dashboard/dashboard.component';
import { ApiIntegrationDashboardComponent } from './shared/api-integration-dashboard/api-integration-dashboard.component';
import { EmailTemplateComponent } from './email-template/email-template.component';
import { ApiIntegratorDashboardComponent } from './api-integrator-dashboard/api-integrator-dashboard.component';
import { TaxpayerDashboardComponent } from './taxpayer-dashboard/taxpayer-dashboard.component';
import { ChangePasswordPostLoginComponent } from './loginSignup/change-password-post-login/change-password-post-login.component';
import { ApiNoGstinScreenComponent } from './apiIntegration/api-dashboard/api-no-gstin-screen/api-no-gstin-screen.component';
import { SetPasswordSignupComponent } from './loginSignup/set-password-signup/set-password-signup.component';
import { ResetForgetPasswordComponent } from './loginSignup/reset-forget-password/reset-forget-password.component';
import { PasswordExpiredSetPasswordComponent } from './loginSignup/password-expired-set-password/password-expired-set-password.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { GenerateContentComponent } from './generate-content/generate-content.component';
import { ApiIntegratorWhomeContentComponent } from './api-integrator-whome-content/api-integrator-whome-content.component';
import { PlansPreLoginComponent } from './plans-pre-login/plans-pre-login.component';
import { MastersPreLoginComponent } from './masters-pre-login/masters-pre-login.component';
import { TaxpayerPreLoginComponent } from './taxpayer-pre-login/taxpayer-pre-login.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { HelpContentComponent } from './help-content/help-content.component';
import { GenericEinvoiceEnablementStatusComponent } from './shared/common-utility/generic-einvoice-enablement-status/generic-einvoice-enablement-status.component';
import { GenericGstinStatusComponent } from './shared/common-utility/generic-gstin-status/generic-gstin-status.component';
import { GenericVerifyEinvoiceComponent } from './shared/common-utility/generic-verify-einvoice/generic-verify-einvoice.component';
import { GenericVerifyQrCodeComponent } from './shared/common-utility/generic-verify-qr-code/generic-verify-qr-code.component';
import { InvoiceEnablementStatusComponent } from './pre-login-utility/invoice-enablement-status/invoice-enablement-status.component';
import { GstinStatusPreloginComponent } from './pre-login-utility/gstin-status-prelogin/gstin-status-prelogin.component';
import { VerifyQrPreloginComponent } from './pre-login-utility/verify-qr-prelogin/verify-qr-prelogin.component';
import { VerifyEinvoicePreloginComponent } from './pre-login-utility/verify-einvoice-prelogin/verify-einvoice-prelogin.component';
import { HsnSearchPreloginComponent } from './pre-login-utility/hsn-search-prelogin/hsn-search-prelogin.component';
import { MastersCodeComponent } from './pre-login-utility/masters-code/masters-code.component';
import { ContentComponentComponent } from './content-component/content-component.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
  },
  {
    path: 'api-integerator-dashboard',
    component: ApiIntegratorDashboardComponent,
  },
  {
    path: 'taxpayer-dashboard',
    component: TaxpayerDashboardComponent,
  },
  {
    path: 'email',
    component: EmailTemplateComponent,
  },
  {
    path: 'api-no-gstin',
    component: ApiNoGstinScreenComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
  },
  {
    path: 'api-dashboard',
    component: ApiIntegrationDashboardComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'signup',
    component: SignupComponent,
  },
  {
    path: 'contact-us',
    component: ContactUsComponent
  },
  {
    path: 'web-content/:subfeature',
    component: GenerateContentComponent
  },
  {
    path: 'api-integrator-detail-content',
    component: ApiIntegratorWhomeContentComponent

  },
  {
    path: 'privacy',
    component:PrivacyPolicyComponent
  },
  {
    path: 'content/',
    component:ContentComponentComponent
  },
  {
    path: 'terms-conditions',
    component:TermsConditionsComponent
  },
  {
    path: 'help-content',
    component:HelpContentComponent
  },
  {
    path: 'taxpayer-content',
    component: TaxpayerPreLoginComponent

  },
  {
    path: 'plans/:subfeature',
    component: PlansPreLoginComponent
  },

  {
    path: 'masters',
    component: MastersPreLoginComponent
  },
  {
    path: 'complete-reg/:userId/:userName',
    component: SetPasswordComponent,
  },
  {
    path: 'set-password-post-signup/:userId/:userName',
    component: SetPasswordSignupComponent,
  },
  {
    path: 'reset-forget-password/:userId',
    component: ResetForgetPasswordComponent,
  },
  {
    path: 'reset-expired-password/:userId',
    component: PasswordExpiredSetPasswordComponent,
  },
  {
    path: 'complete-reg/:userId',
    component: SetPasswordComponent,
  },
  {
    path: 'einvoice-enablement-status',
    component: InvoiceEnablementStatusComponent,
  },
  {
    path: 'gstin-status',
    component: GstinStatusPreloginComponent,
  },
  {
    path: 'verify-signed-invoice',
    component: VerifyEinvoicePreloginComponent,
  },
  {
    path: 'verify-qrcode',
    component: VerifyQrPreloginComponent,
  },
  {
    path: 'hsn-search',
    component: HsnSearchPreloginComponent,
  },
  {
    path:'masters-code',
    component:MastersCodeComponent
  },

  {
    path: 'irp',
    loadChildren: () =>
      import('./shared/shared.module').then((m) => m.SharedModule),
  },
  {
    path: '**',
    redirectTo: '/login',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
