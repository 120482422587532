<div class="custom_container">

  <div class="row">
    <div class="col-md-12" *ngIf="!setCaptch">
      <div class="iris-dashboard-cards2">
        <div class="bannerImg highDiv2">
          <img src="../../../../../assets/images/landingpage/bannerImg.png" height="200" />
          <a class="btn btn-primary rounded p13 btnContact" href="https://sandbox.einvoice6.gst.gov.in/login">{{ 'label.Try Sandbox today' | i18next }}</a>
        </div>
        <div class="smallDiv2">
          <div class="bannerDiv">
            <p>{{ 'label.E-invoicing mandatory' | i18next }}<sup>{{ 'label.st' | i18next }}</sup> {{ 'common.August' | i18next }}</p>
            <p>({{ 'label.GST Notification' | i18next }})</p>
            <p><a class="btn rounded p13 btnContact" href="https://sandbox.einvoice6.gst.gov.in/login">{{ 'label.Try Sandbox today' | i18next }}</a></p>
          </div>
          <!-- <a href="https://sandbox.einvoice6.gst.gov.in/login"><img
              src="../../../../../assets/images/landingpage/sandboximg.png" height="250" width="100%" /></a> -->
        </div>
      </div>
      </div>
      <div class="row">
        <div class="col-md-9 mt15">
          <div class="row">
            <div class="col-md-12">
              <div class="iris-dashboard-cards iris-news-updates h265">
                <h4 class="mb-4">{{ 'label.Verify Signed' | i18next }}</h4>
                <div class="content">
                  <div class="mb-3 row">
                    <label for="gstin" class="col-sm-3 col-form-label">{{ 'label.Upload JSON File' | i18next }}: </label>
                    <div class="col-sm-9">
                      <input type="file" class="form-control fileInput" [(ngModel)]="file"
                        (change)="onFileSelected($event)">&nbsp;
                    </div>
                  </div>
                  <div class="mb-3 row">
                    <div class="col-12 text-end">
                      <button class="btn btn-outline-dark px-4 me-2" (click)="resetEinvoice()">{{ 'common.Clear' | i18next }}</button>

                      <button class="btn btn-dark px-4" (click)="uploadFile()">{{ 'common.Submit' | i18next }}</button>
                    </div>
                  </div>
                </div>
                <div *ngIf="!showResults" class="font-size-14 text-start mt-3 text-muted" style="display: none;"><i
                    class="bi bi-info-circle-fill"></i>
                    {{ 'label.If the IRN is generated' | i18next }}.
                  <!-- <a href="#"
            class="footer-links anchor">Archived Data</a> -->
                </div>
              </div>
            </div>
            <!-- <div class="col-md-3" *ngIf="!setCaptch">
              <div class="iris-dashboard-cards2">
                <img src="../../../../../assets/images/landingpage/sandboximg.png" height="200" />
              </div>
            </div> -->
          </div>

        </div>
        <div class="col-md-3 mt15 highDiv">
          <div class="iris-dashboard-cards iris-news-updates h265">
            <h4 class="mb-4">{{ 'label.Verify Signed Invoice' | i18next }}</h4>
            <div class="content">
              <p>{{ 'label.IRIS IRPs verify signed' | i18next }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="iris-dashboard-cards iris-news-updates mt15 p15" *ngIf="showResults">
          <div class="visibleGstin" id="visibleGstin">
            <h4 *ngIf="!isErrorPresent" class="mb-4 success-field">{{ 'label.Invoice verified successfully' | i18next }}</h4>
            <h4 *ngIf="isErrorPresent" class="mb-4 error-field text-center">{{ 'common.Error Details' | i18next }}</h4>

            <div class="content text-start ms-0">
              <div class="mb-3 row">
                <div class="col-12">
                  <ng-container *ngIf="isErrorPresent">
                    <div class="row mt-3 text-center">
                      <div class="col-md-12">
                        <img src="../../../../../assets/images/No-Data-found.png" height="200" />
                        <p>{{errorMessage}}</p>
                      </div>

                    </div>
                  </ng-container>
                  <ng-container *ngIf="!isErrorPresent">
                    <div class="col-12 bg-primary bg-opacity-10 rounded px-3 pt-3" id="printTemplate">
                      <div class="overlay-div" *ngIf="printDetailResponse && printDetailResponse.status == 'CNL'">
                        <img src="../../../../assets/images/cancelled-print.png" class="img-fluid overlay-image">
                    </div>
                    <div class="overlay-div" *ngIf="printDetailResponse && printDetailResponse.status == 'REJ'">
                      <img src="../../../../assets/images/Rej_waterMark.png" class="img-fluid overlay-image">
                  </div>
                      <div class="row align-items-center highDivInline">
                        <div class="col-6">
                          <h3> {{printDetailResponse?.SellerDtls?.Gstin}}</h3>
                          <h4>{{printDetailResponse?.SellerDtls?.LglNm}}</h4>
                        </div>

                        <div class="col-2"></div>

                        <div class="col-4 text-end" *ngIf="printDetailResponse && printDetailResponse.signedQrCode">
                          <ngx-qrcode [elementType]="elementType" [errorCorrectionLevel]="correctionLevel"
                            [value]="printDetailResponse?.signedQrCode" cssClass="bshadow" [width]="300"></ngx-qrcode>

                        </div>
                        <div class="col-12 mt-3">
                          <div class="card mb-3">
                            <div class="bg-opacity-10 bg-warning card-header px-2">
                              <b>1. {{ 'label.e-Invoice Details' | i18next }}</b>
                            </div>
                            <div class="card-body p-2">
                              <div class="row">
                                <div class="col-4">
                                  <b>{{ 'tableHeader.generatedTablecols.IRN' | i18next }}:</b> <span class="word-next-line"> {{printDetailResponse?.Irn}}</span>
                                </div>
                                <div class="col-4">
                                  <b>{{ 'tableHeader.generatedTablecols.Ack No' | i18next }}:</b> {{printDetailResponse?.AckNo}}
                                </div>
                                <div class="col-4" *ngIf="printDetailResponse && printDetailResponse.AckDt">
                                  <b>{{ 'tableHeader.generatedTablecols.Ack Date' | i18next }}:</b> {{ formatDate(printDetailResponse.AckDt,'YYYY-MM-DD
                                  HH:mm:ss','DD-MM-YYYY HH:mm:ss') }}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="card mb-3">
                            <div class="bg-opacity-10 bg-warning card-header px-2">
                              <b>2. {{ 'formSteps.Transaction Details' | i18next }}</b>
                            </div>
                            <div class="card-body p-2">
                              <div class="row">
                                <div class="col-4">


                                  <b>{{ 'formSteps.Supply Type' | i18next }}:</b> {{(printDetailResponse && printDetailResponse.TranDtls
                                  &&
                                  printDetailResponse.TranDtls.SupTyp)?getSupplyTypeName(printDetailResponse?.TranDtls?.SupTyp)
                                  : ''}}

                                </div>
                                <div class="col-4">
                                  <b>{{ 'formSteps.Document No' | i18next }}:</b> {{printDetailResponse?.DocDtls?.No}}
                                </div>
                                <div class="col-4"
                                  *ngIf="printDetailResponse && printDetailResponse.TranDtls && printDetailResponse?.TranDtls?.IgstOnIntra">
                                  <b>{{ 'label.IGST applicable despite' | i18next }}:</b>
                                  {{optionValues[printDetailResponse?.TranDtls?.IgstOnIntra]}}
                                </div>
                              </div>
                              <hr class="my-2">
                              <div class="row">
                                <div class="col-4" *ngIf="printDetailResponse && printDetailResponse?.BuyerDtls?.Pos">
                                  <b>{{ 'formSteps.Place of Supply' | i18next }}:</b> {{statesMap[printDetailResponse?.BuyerDtls?.Pos] |
                                  titlecase}}
                                </div>
                                <div class="col-4">
                                </div>
                                <div class="col-4">
                                  <b>{{ 'label.Reverse charge' | i18next }}:</b> {{optionValues[printDetailResponse?.TranDtls?.RegRev]}}

                                </div>
                              </div>
                              <hr class="my-2">
                              <div class="row">
                                <div class="col-4">
                                  <b>{{ 'formSteps.Document Type' | i18next }}:</b> {{getDocumentType(printDetailResponse?.DocDtls?.Typ)}}
                                </div>
                                <div class="col-4"
                                  *ngIf="printDetailResponse && printDetailResponse.DocDtls && printDetailResponse.DocDtls.Dt">
                                  <b>{{ 'formSteps.Document Date' | i18next }}:</b>
                                  {{formatDate(printDetailResponse.DocDtls.Dt,'DD/MM/YYYY','DD-MM-YYYY')}}
                                </div>
                                <div class="col-4"
                                  *ngIf="printDetailResponse && printDetailResponse.TranDtls && printDetailResponse.TranDtls.EcmGstin">
                                  <b>{{ 'formSteps.e-Com GSTIN' | i18next }}:</b> {{printDetailResponse?.TranDtls?.EcmGstin}}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="card mb-3">
                            <div class="bg-opacity-10 bg-warning card-header px-2">
                              <b>3. {{ 'formSteps.Party Details' | i18next }}</b>
                            </div>
                            <div class="card-body p-2">
                              <div class="row">
                                <div class="col-6 border-end">
                                  <b>{{ 'common.Supplier' | i18next }}:</b>
                                  <p class="mb-0">{{ 'formSteps.GSTIN' | i18next }}: {{printDetailResponse?.SellerDtls?.Gstin}}</p>
                                  <p class="mb-0"> {{printDetailResponse?.SellerDtls?.LglNm}}</p>
                                  <p class="mb-0">{{printDetailResponse?.SellerDtls?.Addr1}}
                                    {{printDetailResponse?.SellerDtls?.Addr2}}
                                  </p>
                                  <p class="mb-0"> {{printDetailResponse?.SellerDtls?.Loc}}</p>
                                  <p class="mb-0"> {{printDetailResponse?.SellerDtls?.Pin}}
                                    {{statesMap[printDetailResponse?.SellerDtls?.Stcd]}}</p>
                                  <p class="mb-0"> {{printDetailResponse?.SellerDtls?.Ph}}</p>
                                  <p class="mb-0"> {{printDetailResponse?.SellerDtls?.Em}}</p>

                                  <hr class="my-2">
                                  <ng-container *ngIf="showDispatchDetails">
                                    <b>{{ 'formSteps.Dispatch From' | i18next }}:</b>
                                    <p class="mb-0">{{printDetailResponse?.DispDtls?.Nm}}</p>
                                    <p class="mb-0">{{printDetailResponse?.DispDtls?.Addr1}}
                                      {{printDetailResponse?.DispDtls?.Addr2}}
                                    </p>
                                    <p class="mb-0"> {{printDetailResponse?.DispDtls?.Loc}}</p>
                                    <p class="mb-0"> {{printDetailResponse?.DispDtls?.Pin}}

                                      {{ (printDetailResponse && printDetailResponse.DispDtls &&
                                      printDetailResponse.DispDtls.Stcd)?(statesMap[printDetailResponse.DispDtls.Stcd]):''}}
                                    </p>
                                    <p class="mb-0"> {{printDetailResponse?.DispDtls?.Ph}}</p>
                                    <p class="mb-0"> {{printDetailResponse?.DispDtls?.Em}}</p>
                                  </ng-container>

                                </div>
                                <div class="col-6">
                                  <b>{{ 'common.Recipient' | i18next }}:</b>
                                  <p class="mb-0">{{ 'formSteps.GSTIN' | i18next }}: {{printDetailResponse?.BuyerDtls?.Gstin}}</p>
                                  <p class="mb-0"> {{printDetailResponse?.BuyerDtls?.LglNm}}</p>
                                  <p class="mb-0">{{printDetailResponse?.BuyerDtls?.Addr1}}
                                    {{printDetailResponse?.BuyerDtls?.Addr2}}
                                  </p>
                                  <p class="mb-0"> {{printDetailResponse?.BuyerDtls?.Loc}}</p>
                                  <p class="mb-0"> {{printDetailResponse?.BuyerDtls?.Pin}}
                                    {{statesMap[printDetailResponse?.BuyerDtls?.Stcd]}}</p>
                                  <p class="mb-0"> {{printDetailResponse?.BuyerDtls?.Ph}}</p>
                                  <p class="mb-0"> {{printDetailResponse?.BuyerDtls?.Em}}</p>
                                  <hr class="my-2">
                                  <ng-container *ngIf="showShipDetails">
                                    <b>{{ 'formSteps.Ship To' | i18next }}:</b>
                                    <p class="mb-0"
                                      *ngIf="printDetailResponse && printDetailResponse.ShipDtls && printDetailResponse.ShipDtls.Gstin">
                                      {{ 'formSteps.GSTIN' | i18next }}: {{printDetailResponse?.ShipDtls?.Gstin}}</p>
                                    <p class="mb-0"> {{printDetailResponse?.ShipDtls?.LglNm}}</p>
                                    <p class="mb-0">{{printDetailResponse?.ShipDtls?.Addr1}}
                                      {{printDetailResponse?.ShipDtls?.Addr2}}
                                    </p>
                                    <p class="mb-0"> {{printDetailResponse?.ShipDtls?.Loc}}</p>
                                    <p class="mb-0"> {{printDetailResponse?.ShipDtls?.Pin}}
                                      {{(printDetailResponse && printDetailResponse.ShipDtls &&
                                      printDetailResponse.ShipDtls.Stcd
                                      )?(statesMap[printDetailResponse?.ShipDtls?.Stcd]):''}}</p>
                                  </ng-container>

                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="card mb-3">
                            <div class="bg-opacity-10 bg-warning card-header px-2">
                              <b>4. {{ 'label.Details of Goods/Services' | i18next }} (₹)</b>
                            </div>
                            <div class="card-body p-2 ">
                              <div class="row">
                                <div class="col-12" style="width: auto;">
                                  <div class="">
                                    <table class="table table-bordered table-sm"
                                      *ngIf="printDetailResponse && printDetailResponse.ItemList">
                                      <thead class="table-light">
                                        <tr>
                                          <th>{{ 'formSteps.Sr. No' | i18next }}</th>
                                          <th>{{ 'common.Item Description' | i18next }}</th>
                                          <th>{{ 'common.HSN Code' | i18next }}</th>
                                          <th>{{ 'formSteps.Free Qty' | i18next }}</th>
                                          <th>{{ 'formSteps.Quantity' | i18next }}</th>
                                          <th>{{ 'formSteps.Unit' | i18next }}</th>
                                          <th>{{ 'formSteps.Unit Price' | i18next }} </th>
                                          <th>{{ 'formSteps.Discount' | i18next }} </th>
                                          <th>{{ 'formSteps.Pre Tax' | i18next }}</th>
                                          <th>{{ 'formSteps.Taxable Amount' | i18next }} </th>
                                          <th>{{ 'formSteps.Tax Rate' | i18next }}</th>
                                          <th>{{ 'formSteps.Other Charges' | i18next }}</th>
                                          <th>{{ 'common.Total' | i18next }}</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let  item of printDetailResponse?.ItemList">
                                          <td>{{item?.SlNo}}</td>
                                          <td class="word-next-line">{{item?.PrdDesc}}</td>
                                          <td>{{item?.HsnCd}}</td>
                                          <td>{{item?.FreeQty?item?.FreeQty:0}}</td>

                                          <td>{{item?.Qty}}</td>
                                          <td>{{item?.Unit}}</td>
                                          <td>{{item?.UnitPrice}}</td>
                                          <td>{{(item && item.Discount) ? (item.Discount) : 0}}</td>
                                          <td>{{(item.PreTaxVal ? item.PreTaxVal :0)}}
                                          </td>
                                          <td>{{(item.AssAmt)?item.AssAmt.toLocaleString('en-IN',{
                                            minimumFractionDigits: 2 }):''}}
                                          </td>

                                          <td> {{item.GstRt ? item.GstRt : 0}} +
                                            {{item.CesRt ? item.CesRt : 0}} +
                                            {{item.StateCesRt ? item.StateCesRt : 0}} +
                                            {{item.CesNonAdvlAmt ? item.CesNonAdvlAmt : 0}}
                                          </td>
                                          <td>{{item.OthChrg ? (item.OthChrg.toLocaleString('en-IN',{
                                            minimumFractionDigits: 2 })) :0}}
                                          </td>
                                          <td>{{item.TotItemVal ?item.TotItemVal.toLocaleString('en-IN',{
                                            minimumFractionDigits: 2 })
                                            :0}}
                                          </td>

                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="">
                                    <table class="table table-bordered table-sm">
                                      <thead class="table-light">
                                        <tr>
                                          <th>{{ 'formSteps.Taxable Amount' | i18next }}</th>
                                          <th>{{ 'common.CGST Amt' | i18next }}</th>
                                          <th>{{ 'common.SGST Amt' | i18next }}</th>
                                          <th>{{ 'common.IGST Amt' | i18next }}</th>
                                          <th>{{ 'common.CESS Amt' | i18next }}</th>
                                          <th>{{ 'common.State CESS Amt' | i18next }}</th>
                                          <th>{{ 'formSteps.Discount' | i18next }}/th>
                                          <th>{{ 'formSteps.Other Charges' | i18next }}</th>
                                          <th>{{ 'common.Round off Amt' | i18next }}</th>
                                          <th>{{ 'common.Total Inv Amt' | i18next }}</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>{{printDetailResponse?.ValDtls?.AssVal
                                            ?printDetailResponse.ValDtls.AssVal.toLocaleString('en-IN',{
                                            minimumFractionDigits: 2 })
                                            :0
                                            }}</td>
                                          <td>{{printDetailResponse?.ValDtls?.CgstVal?
                                            printDetailResponse.ValDtls.CgstVal.toLocaleString('en-IN',{
                                            minimumFractionDigits: 2 })
                                            :0}}</td>
                                          <td>{{printDetailResponse?.ValDtls?.SgstVal?
                                            printDetailResponse.ValDtls.SgstVal.toLocaleString('en-IN',{
                                            minimumFractionDigits: 2 })
                                            :0}}</td>
                                          <td>{{printDetailResponse?.ValDtls?.IgstVal
                                            ?printDetailResponse?.ValDtls?.IgstVal.toLocaleString('en-IN',{
                                            minimumFractionDigits: 2 }):0}}
                                          </td>
                                          <td>{{printDetailResponse?.ValDtls?.CesVal?
                                            printDetailResponse?.ValDtls?.CesVal.toLocaleString('en-IN',{
                                            minimumFractionDigits: 2 })
                                            :0}}</td>
                                          <td>{{printDetailResponse?.ValDtls?.StCesVal?
                                            printDetailResponse?.ValDtls?.StCesVal.toLocaleString('en-IN',{
                                            minimumFractionDigits: 2 }):0}}
                                          </td>
                                          <td>{{printDetailResponse?.ValDtls?.Discount
                                            ?printDetailResponse?.ValDtls?.Discount.toLocaleString('en-IN',{
                                            minimumFractionDigits: 2 }):0}}
                                          </td>
                                          <td>{{printDetailResponse?.ValDtls?.OthChrg?
                                            printDetailResponse?.ValDtls?.OthChrg.toLocaleString('en-IN',{
                                            minimumFractionDigits: 2 }):0}}
                                          </td>
                                          <td>{{printDetailResponse?.ValDtls?.RndOffAmt?
                                            printDetailResponse?.ValDtls?.RndOffAmt.toLocaleString('en-IN',{
                                            minimumFractionDigits: 2 }):0}}
                                          </td>
                                          <td>{{printDetailResponse?.ValDtls?.TotInvVal?
                                            printDetailResponse?.ValDtls?.TotInvVal.toLocaleString('en-IN',{
                                            minimumFractionDigits: 2 })
                                            :0}}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>

                         

                          <div class="card mb-3">
                            <div class="bg-opacity-10 bg-warning card-header px-2">
                              <b>5. {{ 'common.DSC Details' | i18next }}</b>
                            </div>
                            <div class="card-body p-2">
                              <div class="row">
                                <div class="col-4">
                                  <p class="mb-0"><b>{{ 'common.Issued to' | i18next }}:</b> {{printDetailResponse?.iss}}
                                  </p>
                                </div>
                                <div class="col-4">
                                  <p class="mb-0"><b>{{ 'common.DSC Valid From' | i18next }}:</b>
                                    {{formatDate(printDetailResponse?.dscValidFrom,'DD-MM-YYYY HH:mm:ss',
                                    'DD-MM-YYYY')}}
                                  </p>
                                </div>
                                <div class="col-4">
                                  <p class="mb-0"><b>{{ 'common.DSC Valid To' | i18next }}:</b>
                                    {{formatDate(printDetailResponse?.dscValidTo,'DD-MM-YYYY HH:mm:ss', 'DD-MM-YYYY')}}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="card mb-3" *ngIf="printDetailResponse?.EwbNo && printDetailResponse?.EwbDt && printDetailResponse?.EwbValidtil">
                            <div class="bg-opacity-10 bg-warning card-header px-2">
                                <b>6. {{ 'common.E-way Bill Details' | i18next }}</b>
                            </div>
                            <div class="card-body p-2">
                                <div class="row">
                                    <div class="col-4">
                                        <b>{{ 'common.Eway Bill No' | i18next }}:</b> {{printDetailResponse?.EwbNo}}
                                    </div>
                                    <div class="col-4">
                                        <b>{{ 'common.Eway Bill Date' | i18next }}:</b> {{ formatDate(printDetailResponse.EwbDt,'YYYY-MM-DD
                                        HH:mm:ss','DD-MM-YYYY HH:mm:ss') }}
                                    </div>
                                    <div class="col-4">
                                        <b>{{ 'common.Valid Till Date' | i18next }}:</b> {{ formatDate(printDetailResponse.EwbValidtil,'YYYY-MM-DD
                                        HH:mm:ss','DD-MM-YYYY HH:mm:ss') }}
                                    </div>
                                </div>

                            </div>
                        </div>
                          <div class="card mb-3">
                            <div class="card-body p-2">
                              <div class="row">
                                <div class="col-4">
                                  <p class="mb-0"><b>{{ 'common.Generated By' | i18next }}:</b> {{printDetailResponse?.SellerDtls?.Gstin}}
                                  </p>

                                </div>
                                <div class="col-4">
                                  <!-- https://yobryon.github.io/ngx-barcode/demo/ -->
                                  <ngx-barcode [bc-value]="'281414462691875'" [bc-height]="60"
                                    [bc-display-value]="false"></ngx-barcode>

                                  <!-- <div [hidden]="!barcodeValue">
                                {{ barcodeValue }}
                              </div> -->
                                </div>
                                <div class="col-4" *ngIf="printDetailResponse && printDetailResponse.AckDt">
                                  <p class="mb-0"><b>{{ 'common.Digitally Signed By' | i18next }} {{printDetailResponse.iss}} {{ 'common.on' | i18next }}on:</b> {{
                                    formatDate(printDetailResponse.AckDt,'YYYY-MM-DD HH:mm:ss','DD-MM-YYYY
                                    HH:mm:ss') }}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div class="row smallDiv">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="col-5">
                              <h4> {{printDetailResponse?.SellerDtls?.Gstin}}</h4>
                              <h4>{{printDetailResponse?.SellerDtls?.LglNm}}</h4>
                            </div>
                            <div class="col-3 text-end" *ngIf="printDetailResponse && printDetailResponse.signedQrCode">
                              <ngx-qrcode [elementType]="elementType" [errorCorrectionLevel]="correctionLevel"
                                [value]="printDetailResponse?.signedQrCode" cssClass="bshadow" [width]="200"
                                class="shrink-qr"></ngx-qrcode>

                            </div>
                          </div>
                          <!-- <div class="col-md-6">
                          <h4> {{printDetailResponse?.SellerDtls?.Gstin}}</h4>
                          <h4>{{printDetailResponse?.SellerDtls?.LglNm}}</h4>
                        </div> -->

                          <!-- <div class="col-3 text-end"
                          *ngIf="printDetailResponse && printDetailResponse.signedQrCode">
                          <ngx-qrcode [elementType]="elementType" [errorCorrectionLevel]="correctionLevel"
                            [value]="printDetailResponse?.signedQrCode" cssClass="bshadow" [width]="200"
                            class="shrink-qr"></ngx-qrcode>

                        </div> -->


                        </div>
                        <div class="col-md-12 card mb-3">
                          <div class="row ">
                            <div class="bg-opacity-10 bg-warning card-header px-2">
                              <b>1. {{ 'common.E-Invoice Details' | i18next }}</b>
                            </div>
                          </div>
                          <div class="col-md-4 ptb15">
                            <b>{{ 'tableHeader.generatedTablecols.IRN' | i18next }}:</b><br><span class="word-next-line"> {{printDetailResponse?.Irn}} </span>
                          </div>
                          <div class="col-md-4 ptb15">
                            <b>{{ 'tableHeader.generatedTablecols.Ack No' | i18next }}:</b><br> {{printDetailResponse?.AckNo}}
                          </div>
                          <div class="col-md-4" *ngIf="printDetailResponse && printDetailResponse.AckDt">
                            <b>{{ 'tableHeader.generatedTablecols.Ack Date' | i18next }}:</b><br> {{ formatDate(printDetailResponse.AckDt,'YYYY-MM-DD
                            HH:mm:ss','DD-MM-YYYY HH:mm:ss') }}
                          </div>
                        </div>
                        <div class="col-md-12 card mb-3 nopadding">
                          <div class="col-md-12 ">
                            <div class="bg-opacity-10 bg-warning card-header px-2">
                              <b>2. {{ 'formSteps.Transaction Details' | i18next }}</b>
                            </div>
                          </div>
                          <div class="col-md-4 p15">
                            <b>{{ 'formSteps.Supply Type' | i18next }}:</b><br> {{(printDetailResponse && printDetailResponse.TranDtls
                            &&
                            printDetailResponse.TranDtls.SupTyp)?getSupplyTypeName(printDetailResponse?.TranDtls?.SupTyp)
                            : ''}}
                          </div>
                          <div class="col-md-4 p15">
                            <b>{{ 'formSteps.Document No' | i18next }}:</b><br> {{printDetailResponse?.DocDtls?.No}}
                          </div>
                          <div class="col-md-4 p15">
                            <div class=""
                              *ngIf="printDetailResponse && printDetailResponse.TranDtls && printDetailResponse?.TranDtls?.IgstOnIntra">
                              <b>{{ 'label.IGST applicable despite' | i18next }}:</b>
                              <br>{{optionValues[printDetailResponse?.TranDtls?.IgstOnIntra]}}
                            </div>

                          </div>
                          <div class="col-md-4 p15">
                            <div *ngIf="printDetailResponse && printDetailResponse?.BuyerDtls?.Pos">
                              <b>{{ 'formSteps.Place of Supply' | i18next }}:</b>
                              <br>{{ statesMap[printDetailResponse?.BuyerDtls?.Pos] |
                              titlecase}}
                            </div>
                          </div>
                          <div class="col-md-4 p15">
                            <div *ngIf="printDetailResponse && printDetailResponse?.TranDtls?.RegRev">
                              <b>{{ 'label.Reverse charge' | i18next }}:</b>
                              <br>{{optionValues[printDetailResponse?.TranDtls?.RegRev]}}
                            </div>
                          </div>
                          <div class="col-md-4 p15">
                            <b>{{ 'formSteps.Document Type' | i18next }}:</b><br> {{getDocumentType(printDetailResponse?.DocDtls?.Typ)}}
                          </div>
                          <div class="col-md-4 p15"
                            *ngIf="printDetailResponse && printDetailResponse.DocDtls && printDetailResponse.DocDtls.Dt">
                            <b>{{ 'formSteps.Document Date' | i18next }}:</b>
                            <br>{{formatDate(printDetailResponse.DocDtls.Dt,'DD/MM/YYYY','DD-MM-YYYY')}}
                          </div>
                          <div class="col-md-4"
                            *ngIf="printDetailResponse && printDetailResponse.TranDtls && printDetailResponse.TranDtls.EcmGstin">
                            <b>{{ 'formSteps.e-Com GSTIN' | i18next }}:</b> <br>{{printDetailResponse?.TranDtls?.EcmGstin}}
                          </div>
                        </div>
                        <div class="col-md-12 mb-3 card nopadding">
                          <div class="col-md-12 ">
                            <div class="bg-opacity-10 bg-warning card-header px-2">
                              <b>3. {{ 'formSteps.Party Details' | i18next }}</b>
                            </div>
                          </div>
                          <div class="col-md-6 p15">
                            <b>{{ 'common.Supplier' | i18next }}:</b>
                            <p class="mb-0">{{ 'formSteps.GSTIN' | i18next }}: <br>{{printDetailResponse?.SellerDtls?.Gstin}}</p>
                            <p class="mb-0"> {{printDetailResponse?.SellerDtls?.LglNm}}</p>
                            <p class="mb-0">{{printDetailResponse?.SellerDtls?.Addr1}}
                              {{printDetailResponse?.SellerDtls?.Addr2}}
                            </p>
                            <p class="mb-0"> {{printDetailResponse?.SellerDtls?.Loc}}</p>
                            <p class="mb-0"> {{printDetailResponse?.SellerDtls?.Pin}}
                              {{statesMap[printDetailResponse?.SellerDtls?.Stcd]}}</p>
                            <p class="mb-0"> {{printDetailResponse?.SellerDtls?.Ph}}</p>
                            <p class="mb-0"> {{printDetailResponse?.SellerDtls?.Em}}</p>

                            <hr class="my-2">
                            <ng-container *ngIf="showDispatchDetails">
                              <b>{{ 'formSteps.Dispatch From' | i18next }}:</b>
                              <p class="mb-0">{{printDetailResponse?.DispDtls?.Nm}}</p>
                              <p class="mb-0">{{printDetailResponse?.DispDtls?.Addr1}}
                                {{printDetailResponse?.DispDtls?.Addr2}}
                              </p>
                              <p class="mb-0"> {{printDetailResponse?.DispDtls?.Loc}}</p>
                              <p class="mb-0"> {{printDetailResponse?.DispDtls?.Pin}}

                                {{ (printDetailResponse && printDetailResponse.DispDtls &&
                                printDetailResponse.DispDtls.Stcd)?(statesMap[printDetailResponse.DispDtls.Stcd]):''}}
                              </p>
                              <p class="mb-0"> {{printDetailResponse?.DispDtls?.Ph}}</p>
                              <p class="mb-0"> {{printDetailResponse?.DispDtls?.Em}}</p>
                            </ng-container>

                          </div>
                          <div class="col-md-6 p15">
                            <b>{{ 'common.Recipient' | i18next }}:</b>
                            <p class="mb-0">{{ 'formSteps.GSTIN' | i18next }}: <br>{{printDetailResponse?.BuyerDtls?.Gstin}}</p>
                            <p class="mb-0"> {{printDetailResponse?.BuyerDtls?.LglNm}}</p>
                            <p class="mb-0">{{printDetailResponse?.BuyerDtls?.Addr1}}
                              {{printDetailResponse?.BuyerDtls?.Addr2}}
                            </p>
                            <p class="mb-0"> {{printDetailResponse?.BuyerDtls?.Loc}}</p>
                            <p class="mb-0"> {{printDetailResponse?.BuyerDtls?.Pin}}
                              {{statesMap[printDetailResponse?.BuyerDtls?.Stcd]}}</p>
                            <p class="mb-0"> {{printDetailResponse?.BuyerDtls?.Ph}}</p>
                            <p class="mb-0"> {{printDetailResponse?.BuyerDtls?.Em}}</p>
                            <hr class="my-2">
                            <ng-container *ngIf="showShipDetails">
                              <b>{{ 'formSteps.Ship To' | i18next }}:</b>
                              <p class="mb-0"
                                *ngIf="printDetailResponse && printDetailResponse.ShipDtls && printDetailResponse.ShipDtls.Gstin">
                                {{ 'formSteps.GSTIN' | i18next }}: {{printDetailResponse?.ShipDtls?.Gstin}}</p>
                              <p class="mb-0"> {{printDetailResponse?.ShipDtls?.LglNm}}</p>
                              <p class="mb-0">{{printDetailResponse?.ShipDtls?.Addr1}}
                                {{printDetailResponse?.ShipDtls?.Addr2}}
                              </p>
                              <p class="mb-0"> {{printDetailResponse?.ShipDtls?.Loc}}</p>
                              <p class="mb-0"> {{printDetailResponse?.ShipDtls?.Pin}}
                                {{(printDetailResponse && printDetailResponse.ShipDtls &&
                                printDetailResponse.ShipDtls.Stcd
                                )?(statesMap[printDetailResponse?.ShipDtls?.Stcd]):''}}</p>
                            </ng-container>

                          </div>
                        </div>
                        <div class="col-md-12 card mb-3 nopadding">
                          <div class="col-md-12 ">
                            <div class="bg-opacity-10 bg-warning card-header px-2">
                              <b>4. {{ 'label.Details of Goods/Services' | i18next }}</b>
                            </div>
                          </div>

                          <div class="col-md-12 p15">
                            <div style="overflow-x:auto;">
                              <table class="table table-bordered table-sm"
                                *ngIf="printDetailResponse && printDetailResponse.ItemList">
                                <thead class="table-light">
                                  <tr>
                                    <th>{{ 'formSteps.Sr. No' | i18next }}</th>
                                    <th>{{ 'common.Item Description' | i18next }}</th>
                                    <th>{{ 'common.HSN Code' | i18next }}</th>
                                    <th>{{ 'formSteps.Free Qty' | i18next }}</th>
                                    <th>{{ 'formSteps.Quantity' | i18next }}</th>
                                    <th>{{ 'formSteps.Unit' | i18next }}</th>
                                    <th>{{ 'formSteps.Unit Price' | i18next }} </th>
                                    <th>{{ 'formSteps.Discount' | i18next }} </th>
                                    <th>{{ 'formSteps.Pre Tax' | i18next }}</th>
                                    <th>{{ 'formSteps.Taxable Amount' | i18next }} </th>
                                    <th>{{ 'formSteps.Tax Rate' | i18next }}</th>
                                    <th>{{ 'formSteps.Other Charges' | i18next }}</th>
                                    <th>{{ 'common.Total' | i18next }}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let  item of printDetailResponse?.ItemList">
                                    <td>{{item?.SlNo}}</td>
                                    <td class="word-next-line">{{item?.PrdDesc}}</td>
                                    <td>{{item?.HsnCd}}</td>
                                    <td>{{item?.FreeQty?item?.FreeQty:0}}</td>

                                    <td>{{item?.Qty}}</td>
                                    <td>{{item?.Unit}}</td>
                                    <td>{{item?.UnitPrice}}</td>
                                    <td>{{(item && item.Discount) ? (item.Discount) : 0}}</td>
                                    <td>{{(item.PreTaxVal ? item.PreTaxVal :0)}}
                                    </td>
                                    <td>{{(item.AssAmt)?item.AssAmt.toLocaleString('en-IN',{
                                      minimumFractionDigits: 2 }):''}}
                                    </td>

                                    <td> {{item.GstRt ? item.GstRt : 0}} +
                                      {{item.CesRt ? item.CesRt : 0}} +
                                      {{item.StateCesRt ? item.StateCesRt : 0}} +
                                      {{item.CesNonAdvlAmt ? item.CesNonAdvlAmt : 0}}
                                    </td>
                                    <td>{{item.OthChrg ? (item.OthChrg.toLocaleString('en-IN',{
                                      minimumFractionDigits: 2 })) :0}}
                                    </td>
                                    <td>{{item.TotItemVal ?item.TotItemVal.toLocaleString('en-IN',{
                                      minimumFractionDigits: 2 })
                                      :0}}
                                    </td>

                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div style="overflow-x:auto;">
                              <table class="table table-bordered table-sm">
                                <thead class="table-light">
                                  <tr>
                                    <th>{{ 'formSteps.Taxable Amount' | i18next }}</th>
                                    <th>{{ 'common.CGST Amt' | i18next }}</th>
                                    <th>{{ 'common.SGST Amt' | i18next }}</th>
                                    <th>{{ 'common.IGST Amt' | i18next }}</th>
                                    <th>{{ 'common.CESS Amt' | i18next }}</th>
                                    <th>{{ 'common.State CESS Amt' | i18next }}</th>
                                    <th>{{ 'formSteps.Discount' | i18next }}/th>
                                    <th>{{ 'formSteps.Other Charges' | i18next }}</th>
                                    <th>{{ 'common.Round off Amt' | i18next }}</th>
                                    <th>{{ 'common.Total Inv Amt' | i18next }}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>{{printDetailResponse?.ValDtls?.AssVal
                                      ?printDetailResponse.ValDtls.AssVal.toLocaleString('en-IN',{
                                      minimumFractionDigits: 2 })
                                      :0
                                      }}</td>
                                    <td>{{printDetailResponse?.ValDtls?.CgstVal?
                                      printDetailResponse.ValDtls.CgstVal.toLocaleString('en-IN',{
                                      minimumFractionDigits: 2 })
                                      :0}}</td>
                                    <td>{{printDetailResponse?.ValDtls?.SgstVal?
                                      printDetailResponse.ValDtls.SgstVal.toLocaleString('en-IN',{
                                      minimumFractionDigits: 2 })
                                      :0}}</td>
                                    <td>{{printDetailResponse?.ValDtls?.IgstVal
                                      ?printDetailResponse?.ValDtls?.IgstVal.toLocaleString('en-IN',{
                                      minimumFractionDigits: 2 }):0}}
                                    </td>
                                    <td>{{printDetailResponse?.ValDtls?.CesVal?
                                      printDetailResponse?.ValDtls?.CesVal.toLocaleString('en-IN',{
                                      minimumFractionDigits: 2 })
                                      :0}}</td>
                                    <td>{{printDetailResponse?.ValDtls?.StCesVal?
                                      printDetailResponse?.ValDtls?.StCesVal.toLocaleString('en-IN',{
                                      minimumFractionDigits: 2 }):0}}
                                    </td>
                                    <td>{{printDetailResponse?.ValDtls?.Discount
                                      ?printDetailResponse?.ValDtls?.Discount.toLocaleString('en-IN',{
                                      minimumFractionDigits: 2 }):0}}
                                    </td>
                                    <td>{{printDetailResponse?.ValDtls?.OthChrg?
                                      printDetailResponse?.ValDtls?.OthChrg.toLocaleString('en-IN',{
                                      minimumFractionDigits: 2 }):0}}
                                    </td>
                                    <td>{{printDetailResponse?.ValDtls?.RndOffAmt?
                                      printDetailResponse?.ValDtls?.RndOffAmt.toLocaleString('en-IN',{
                                      minimumFractionDigits: 2 }):0}}
                                    </td>
                                    <td>{{printDetailResponse?.ValDtls?.TotInvVal?
                                      printDetailResponse?.ValDtls?.TotInvVal.toLocaleString('en-IN',{
                                      minimumFractionDigits: 2 })
                                      :0}}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>

                       
                        <div class="col-md-12 card mb-3 nopadding">
                          <div class="col-md-12 ">
                            <div class="bg-opacity-10 bg-warning card-header px-2">
                              <b>5. {{ 'common.DSC Details' | i18next }}</b>
                            </div>
                          </div>
                          <div class="col-md-6 p15">
                            <p class="mb-0"><b>{{ 'common.Issued to' | i18next }}:</b><br> {{printDetailResponse?.iss}}
                            </p>
                          </div>
                          <div class="col-md-6 p15">
                            <p class="mb-0"><b>{{ 'common.DSC Valid From' | i18next }}:</b><br>
                              {{formatDate(printDetailResponse?.dscValidFrom,'DD-MM-YYYY HH:mm:ss', 'DD-MM-YYYY')}}
                            </p>
                          </div>
                          <div class="col-md-6 p15">
                            <p class="mb-0"><b>{{ 'common.DSC Valid To' | i18next }}:</b><br>
                              {{formatDate(printDetailResponse?.dscValidTo,'DD-MM-YYYY
                              HH:mm:ss', 'DD-MM-YYYY')}}
                            </p>
                          </div>


                        </div>
                        <div class="col-md-12 card mb-3 nopadding" *ngIf="printDetailResponse?.EwbNo && printDetailResponse?.EwbDt && printDetailResponse?.EwbValidtil">
                          <div class="col-md-12 " >
                              <div class="bg-opacity-10 bg-warning card-header px-2">
                                  <b>6. {{ 'common.E-way Bill Details' | i18next }}</b>
                              </div>
                          </div>
                          <div class="col-md-4 p15" >
                              <b>{{ 'common.Eway Bill No' | i18next }}:</b> {{printDetailResponse?.EwbNo}}
                          </div>
                          <div class="col-md-4 p15" >
                              <b>{{ 'common.Eway Bill Date' | i18next }}:</b> {{ formatDate(printDetailResponse.EwbDt,'YYYY-MM-DD
                              HH:mm:ss','DD-MM-YYYY HH:mm:ss') }}
                          </div>
                          <div class="col-md-4 p15" >
                              <b>{{ 'common.Valid Till Date' | i18next }}:</b> {{ formatDate(printDetailResponse.EwbValidtil,'YYYY-MM-DD
                              HH:mm:ss','DD-MM-YYYY HH:mm:ss') }}  
                          </div>
          
                          </div>
                        <div class="col-md-12 card mb-3">
                          <div class="col-md-3 p15">
                            <p class="mb-0"><b>{{ 'common.Generated By' | i18next }}:</b><br> {{printDetailResponse?.SellerDtls?.Gstin}}
                            </p>


                            <!-- <p class="mb-0"><b>{{ 'common.Print Date' | i18next }}:</b><br> {{currentDate}}</p> -->
                          </div>

                          <div class="col-3 p15">
                            <!-- https://yobryon.github.io/ngx-barcode/demo/ -->
                            <ngx-barcode [bc-value]="'281414462691875'" class="shrink-barcode" [bc-height]="60"
                              [bc-display-value]="false"></ngx-barcode>

                            <!-- <div [hidden]="!barcodeValue">
                  {{ barcodeValue }}
              </div> -->
                          </div>
                          <div class="col-md-5" *ngIf="printDetailResponse && printDetailResponse.AckDt">
                            <p class="mb-0"><b>{{ 'common.Digitally Signed By' | i18next }} {{printDetailResponse.iss}} {{ 'common.on' | i18next }}:</b> <br>{{
                              formatDate(printDetailResponse.AckDt,'YYYY-MM-DD HH:mm:ss','DD-MM-YYYY
                              HH:mm:ss') }}</p>
                          </div>


                        </div>



                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-9 mt15 smallDiv">
        <div class="iris-dashboard-cards iris-news-updates">
          <h4 class="mb-4">{{ 'label.Verify Signed Invoice' | i18next }}</h4>
          <div class="content">
            <p>{{ 'label.IRIS IRPs verify signed' | i18next }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>