<div class="custom_container">
  <div class="row">
    <div class="col-md-12" *ngIf="!setCaptch">
      <div class="iris-dashboard-cards2">
        <div class="bannerImg highDiv">
          <img src="../../../../../assets/images/landingpage/bannerImg.png" height="200" />
          <a class="btn btn-primary rounded p13 btnContact" href="https://sandbox.einvoice6.gst.gov.in/login">{{ 'label.Try Sandbox today' | i18next }}</a>
        </div>
        <div class="smallDiv">
          <div class="bannerDiv">
            <p>{{ 'label.E-invoicing mandatory' | i18next }}<sup>{{ 'label.st' | i18next }}</sup> {{ 'common.August' | i18next }}</p>
            <p>({{ 'label.GST Notification' | i18next }})</p>
            <p><a class="btn rounded p13 btnContact" href="https://sandbox.einvoice6.gst.gov.in/login">{{ 'label.Try Sandbox today' | i18next }}</a></p>
          </div>
          <!-- <a href="https://sandbox.einvoice6.gst.gov.in/login"><img
              src="../../../../../assets/images/landingpage/sandboximg.png" height="250" width="100%" /></a> -->
        </div>
      </div>
      </div>
      <div class="row">
        <div class="col-md-9 mt15">
          <div class="row">
            <div class="col-md-12">
              <div class="iris-dashboard-cards iris-news-updates h265">
                <h4 class="mb-4">{{ 'label.Verify e-invoice by qr' | i18next }}</h4>
                <div class="content">
                  <div class="mb-3 row">
                    <label for="gstin" class="col-sm-3 col-form-label">{{ 'common.QR Code' | i18next }}:</label>
                    <div class="col-sm-9">
                      <input type="text" class="form-control" [(ngModel)]="qrCode" />&nbsp;
                    </div>
                    <div class="mb-3 row ">
                      <div class="col-md-12 text-end">
                        <button class="btn btn-outline-dark px-4 me-2" (click)="resetQR()">{{ 'common.Clear' | i18next }}</button>

                        <button class="btn btn-dark px-4" (click)="verifyQR()">{{ 'common.Verify' | i18next }}</button>
                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-md-3" *ngIf="!setCaptch">
              <div class="iris-dashboard-cards2">
                <img src="../../../../../assets/images/landingpage/sandboximg.png" height="200" />
              </div>
            </div> -->
          </div>
          <div class="iris-dashboard-cards iris-news-updates mt15" *ngIf="showResultsQR">
            <div class="visibleGstin" id="qrVisible">
              <h4 *ngIf="!isErrorPresent" class="mb-4 success-field"> {{ 'label.Digitally signed E-Invoice' | i18next }}</h4>
              <h4 *ngIf="isErrorPresent" class="mb-4 error-field text-center">{{ 'common.Error Details' | i18next }}</h4>

              <div class="content text-start ms-0">
                <div class="mb-3 row">
                  <div class="col-12">
                    <ng-container *ngIf="isErrorPresent">
                      <div class="card-body "><br />
                        <div class="row text-center">
                          <div class="col-md-12">
                            <img src="../../../../../assets/images/No-Data-found.png" height="200" />
                            <p>{{errorMessage}}</p>
                          </div>

                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="!isErrorPresent">
                      <div class="card-body" style=" min-height: 200px;"><br />
                        <div class="row">
                          <div class="col-md-12 col-12 font-size-14">
                            <div class="row mb-3">
                              <div class="col-md-3 col-12">
                                <label class="static-label lbl">{{ 'common.Supplier GSTIN' | i18next }} : </label>
                              </div>
                              <div class="col-md-9 col-12">
                                <span class="textBreakAll">{{qrcodeData?.SellerGstin}}</span>
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col-md-3 col-12">
                                <label class="static-label lbl">{{ 'common.Recipient GSTIN' | i18next }} : </label>
                              </div>
                              <div class="col-md-9 col-12">
                                <span class="textBreakAll">{{qrcodeData?.BuyerGstin}}</span>
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col-md-3 col-12">
                                <label class="static-label lbl">{{ 'formSteps.Document No' | i18next }} : </label>
                              </div>
                              <div class="col-md-9 col-12">
                                <span class="textBreakAll">{{qrcodeData?.DocNo}}</span>
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col-md-3 col-12">
                                <label class="static-label lbl">{{ 'formSteps.Document Type' | i18next }} : </label>
                              </div>
                              <div class="col-md-9 col-12">
                                <span class="textBreakAll">{{invTypeMap && qrcodeData &&
                                  qrcodeData.DocTyp?invTypeMap[qrcodeData?.DocTyp]:''}}</span>
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col-md-3 col-12">
                                <label class="static-label lbl">{{ 'formSteps.Document Date' | i18next }} : </label>
                              </div>
                              <div class="col-md-9 col-12">
                                <span
                                  class="textBreakAll">{{formatDate(qrcodeData?.DocDt,'DD/MM/YYYY','DD-MM-YYYY')}}</span>
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col-md-3 col-12">
                                <label class="static-label lbl">{{ 'common.Total Invoice value' | i18next }} : </label>
                              </div>
                              <div class="col-md-9 col-12">
                                <span class="textBreakAll">{{qrcodeData?.TotInvVal.toLocaleString('en-IN',{
                                  minimumFractionDigits: 2 })}}</span>
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col-md-3 col-12">
                                <label class="static-label lbl">{{ 'common.No of line items' | i18next }} : </label>
                              </div>
                              <div class="col-md-9 col-12">
                                <span class="textBreakAll">{{qrcodeData?.ItemCnt}}</span>
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col-md-3 col-12">
                                <label class="static-label lbl">{{ 'label.Main HSN Code' | i18next }} : </label>
                              </div>
                              <div class="col-md-9 col-12">
                                <span class="textBreakAll">{{qrcodeData?.MainHsnCode}}</span>
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col-md-3 col-12">
                                <label class="static-label lbl">{{ 'tableHeader.generatedTablecols.IRN' | i18next }} : </label>
                              </div>
                              <div class="col-md-9 col-12">
                                <span class="textBreakAll">{{qrcodeData?.Irn}}</span>
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col-md-3 col-12">
                                <label class="static-label lbl">{{ 'tableHeader.generatedTablecols.IRN Date' | i18next }} : </label>
                              </div>
                              <div class="col-md-9 col-12">
                                <span class="textBreakAll">{{formatDate(qrcodeData?.IrnDt,'YYYY-MM-DD HH:mm:ss','DD-MM-YYYY HH:mm:ss')}}</span>
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col-md-3 col-12">
                                <label class="static-label lbl">{{ 'label.Issued by' | i18next }} : </label>
                              </div>
                              <div class="col-md-9 col-12">
                                <span class="textBreakAll">{{qrcodeData?.iss}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 mt15">
          <div class="iris-dashboard-cards iris-news-updates">
            <h4 class="mb-4">{{ 'label.Verify QR Code' | i18next }}</h4>
            <div class="content">
              <p>{{ 'label.IRIS IRP verify QR Code' | i18next }}.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>