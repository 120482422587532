export const environment = {
  env: 'development',
  baseUrl: 'https://dev.portal.api.irisirp.com/portal',
  apiUrl: 'https://api.sandbox.portal.irisirp.com/portal',
  ewbUrl: 'https://dev.portal.api.irisirp.com/ewbportal',

  excelUrl: 'assets/IRIS_IRP_v0.09_Excel Utility-DEV.xlsm',
  excelFileName: 'IRIS_IRP_v0.09_Excel Utility-DEV.xlsm',

  sandboxUrl: 'https://api.sandbox.portal.irisirp.com/portal',
};
