import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TaxPayerDashboardService } from 'src/app/shared/services/taxpayer-dashboard.service';
import { EInvoiceService } from '../../e-invoice/e-invoice.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-no-gstin-screen',
  templateUrl: './no-gstin-screen.component.html',
  styleUrls: ['./no-gstin-screen.component.scss']
})
export class NoGstinScreenComponent implements OnInit {
  popUpSubscription: any;
  newsDataPopup: any;
  newsListpopup: any = [];
  modalRef: BsModalRef;
  hideForSandbox:boolean = false;
  @ViewChild("whtsnew", { static: true }) whtsnew: ElementRef;

  constructor(private router: Router, private taxPayerDashboardService: TaxPayerDashboardService, private invoiceService: EInvoiceService, private modalService: BsModalService) { }

  ngOnInit(): void {
    if (environment.env === 'sandbox'){
      this.hideForSandbox = true;
    }
    this.popUpSubscription = this.taxPayerDashboardService.getPopUpNoGstin().subscribe((emited: string) => {
      if (emited == "true") {
        setTimeout(() => {
          if (this.newsDataPopup) {
            this.openModalWhatsnew(this.whtsnew);
            this.taxPayerDashboardService.setPopUpNoGstin("false");
          }
        }, 500);
      }
    });

    // setTimeout(()=>{
    //   if(this.newsDataPopup){
    //     this.openModalWhatsnew(this.whtsnew);
    //   }
    // },2000)

    const newsResponse = this.taxPayerDashboardService.getNews().subscribe(res => {
      this.newsListpopup = res;
      this.newsDataPopup = this.newsListpopup.response.popup[0];


    })
  }
  addGSTIn() {
    this.router.navigate(['/irp/gstin/dashboard/registration']);

  }
  openModalWhatsnew(whtsnew) {
    const pid = document.getElementById('myPopup') as HTMLButtonElement || null;

    this.modalRef = this.modalService.show(
      whtsnew,
      Object.assign({}, { class: 'gray modal-lg' })
    );



    this.getNewsMarqueeData();
  }
  getNewsMarqueeData() {
    const newsResponse = this.taxPayerDashboardService.getNews().subscribe(res => {
      this.newsListpopup = res;
      // tbl.appendChild(this.newsList.response.news[0].moreInfoModalBody);
      this.newsDataPopup = this.newsListpopup.response.popup[0];
      console.log(this.newsDataPopup.moreInfoModalBody)
      // if(this.newsDataPopup.length){

      //   for (const news in this.newsDataPopup) {
      //     this.news.push(this.newsDataPopup[news])
      //   }
      // }

    })

  }
  ngOnDestroy() {
    if (this.popUpSubscription) {
      this.popUpSubscription.unsubscribe();
    }
  }
}
