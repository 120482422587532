<div class="custom-container">
  <div class="row justify-content-center mt-5 mb-5">
    <h3 class="text-center">Setup MFA</h3>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-6">
      <ol class="mb-2">
        <li class="mb-2">To set up MFA, use the Microsoft Authenticator or Google Authenticator app to scan the QR code. (You can download these apps from the Play Store) </li>
        <li class="mb-2">Scan the QR code below in your authenticator app, generate a One-Time Password (OTP), and enter it into the designated field before it expires. </li>
        <li class="mb-2">To use the authenticator OTP successfully, it is essential that the date and time settings on your mobile device and the system you're logging in from are synchronized. </li>
        <li class="mb-2">Once the MFA setup is successful, you will be required to authenticate yourself using the authenticator OTP every time you log in. </li>
      </ol>
    </div>
  </div>
  <div class="row justify-content-center mt-5">
    <div class="col-md-6">
      <div class="row">
        <div class="col-5 d-flex justify-content-center align-items-center">
          <img *ngIf="qrCode" [src]="'data:image/png;base64,' + qrCode" alt="" srcset="">
        </div>
        <div class="col-7  d-flex justify-content-center align-items-center">
          <input [type]="otpInputType" class="form-control" placeholder="Enter OTP" [(ngModel)]="otp" (ngModelChange)="otpChanged($event)" aria-label="Enter OTP" aria-describedby="basic-addon1">
          <i class="bi bi-eye icon password-visibility" title="Hide OTP" *ngIf="otpInputType === 'text'" (click)="toggleOTPInputType()"></i>
          <i class="bi bi-eye-slash icon password-visibility" title="Show OTP" *ngIf="otpInputType === 'password'" (click)="toggleOTPInputType()"></i>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-center mt-5">
    <div class="col-md-2 justify-content-center">
      <div class="row justify-content-center">
        <button class="btn btn-dark px-5 py-2" [disabled]="!(otp && otp.length == 6)" (click)="submitMFA()">
          Submit
        </button>
      </div>
    </div>
  </div>
</div>
