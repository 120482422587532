import { Component, OnInit } from '@angular/core';
import { QRCodeSettings } from '../app.config';
import { LoginService } from '../loginSignup/login.service';
import { NgxQrcodeElementTypes } from '@techiediaries/ngx-qrcode';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { API_STATUS } from '../constant';

@Component({
  selector: 'app-setup-mfa',
  templateUrl: './setup-mfa.component.html',
  styleUrls: ['./setup-mfa.component.scss']
})
export class SetupMFAComponent implements OnInit {

  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = QRCodeSettings.CORRECTION_LEVEL;
  qrWidth = QRCodeSettings.WIDTH;
  userDetails: string;
  detailsLoginData: any;
  qrCode: string = '';
  otp = ''
  secretKey = '';
  otpInputType = 'password';

  constructor(
    private loginService: LoginService,
    private toastrService: ToastrService,
    private router: Router,

  ) { }

  ngOnInit(): void {
    this.userDetails = localStorage.getItem('logged-in-userinfo');
    this.detailsLoginData = JSON.parse(this.userDetails);
    this.fetchMFAQRCode();
  }

  async fetchMFAQRCode() {
    const mfaResponse = await this.loginService.registerMFA({ userId: this.detailsLoginData.userId });
    this.qrCode = mfaResponse.response.qrcode;
    this.secretKey = mfaResponse.response.mfaSecretKey;
  }

  async submitMFA() {
    // Sending for first time
    const mfaResponse = await this.loginService.verifyMFA({ userId: this.detailsLoginData.userId, otp: this.otp, secretKey: this.secretKey });
    if (mfaResponse.response && mfaResponse.response.token && mfaResponse.status !== API_STATUS.error) {
      this.toastrService.success('MFA setup successfully');
      this.router.navigate(['/irp/gstin/dashboard']);
    } else if(mfaResponse.status === API_STATUS.error) {
      this.toastrService.error(mfaResponse.message);
    }

  }

  toggleOTPInputType() {
    this.otpInputType = this.otpInputType === 'password' ? 'text' : 'password';
  }

  otpChanged(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const sanitizedValue = inputElement.value.replace(/[^0-9]/g, ''); // Allow only numbers
    this.otp = sanitizedValue; // Update the component variable
    inputElement.value = sanitizedValue; // Update the input element value
  }
}
