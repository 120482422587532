<div class="custom_container">
  <div class="row">
    <div class="col-md-12" *ngIf="!setCaptch">
      <div class="iris-dashboard-cards2">
        <div class="bannerImg highDiv2">
          <img src="../../../../../assets/images/landingpage/bannerImg.png" height="200" />
          <a class="btn btn-primary rounded p13 btnContact" href="https://sandbox.einvoice6.gst.gov.in/login">{{ 'label.Try Sandbox today' | i18next }}</a>
        </div>
        <div class="smallDiv2">
          <div class="bannerDiv">
            <p>{{ 'label.E-invoicing mandatory' | i18next }}<sup>{{ 'label.st' | i18next }}</sup> {{ 'common.August' | i18next }}</p>
            <p>({{ 'label.GST Notification' | i18next }})</p>
            <p><a class="btn rounded p13 btnContact" href="https://sandbox.einvoice6.gst.gov.in/login">{{ 'label.Try Sandbox today' | i18next }}</a></p>
          </div>
          <!-- <a href="https://sandbox.einvoice6.gst.gov.in/login"><img
              src="../../../../../assets/images/landingpage/sandboximg.png" height="250" width="100%" /></a> -->
        </div>
      </div>
      </div>
      <div class="row">
        <div class="col-md-9 mt15 ">
          <div class="row">
            <div class="col-md-12">
              <div class="iris-dashboard-cards iris-news-updates h265">
                <h4 class="mb-4">{{ 'label.Search GSTIN details' | i18next }}</h4>
                <!-- <div class="content">
          <div class="mb-3 row d-flex ">
            <div class="col-lg-9 col-sm-12 d-flex">
              <label for="gstin" class="col-form-label me-2 text-nowrap">GSTIN :</label>
              <div class="w-100">
                <input type="text" class="form-control" id="gstin" name="gstin" placeholder="GSTIN" [(ngModel)]="gstin">
              </div>
            </div>
            <div class="text-end col-lg-5 col-sm-12">
              <button class="btn btn-outline-dark px-4 me-2 mb-3 " (click)="cancel()">CLEAR</button>
              <button class="btn btn-dark px-4 mb-3" (click)="searchGstin()">SEARCH</button>
            </div>
          </div>


        </div> -->
                <div class="content">
                  <div class="mb-3 row">
                    <label for="gstin" class="col-sm-3 col-form-label">{{ 'formSteps.GSTIN' | i18next }}: </label>
                    <div class="col-sm-9">
                      <input type="text" class="form-control" id="gstin" name="gstin" placeholder="{{ 'formSteps.GSTIN' | i18next }}"
                        [(ngModel)]="gstin" (input)="checkCaptcha()">

                    </div>
                  </div>
                  <div class="mb-3 row" *ngIf="!setCaptch && captchaVisible1">
                    <label for="gstin" class="col-sm-3 col-form-label"></label>
                    <label for="gstin" *ngIf="!setCaptch"
                      class="col-sm-3 text-center font-size-30 captchaTxt">{{this.captchString}}</label>
                    <!-- <label *ngIf="!setCaptch">{{this.captchString}}</label> -->
                    <div class="col-sm-3">
                      <button title="{{ 'label.Refresh Data' | i18next }}" *ngIf="!setCaptch" class="btn btn-sm custom-icon-button"
                        (click)="generateRandomString(5)">
                        <!-- <i class="bi bi-arrow-clockwise "></i> -->
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.0625 3.3457C15.25 1.5332 12.7625 0.408203 9.99995 0.408203C4.47495 0.408203 0.0124512 4.8832 0.0124512 10.4082C0.0124512 15.9332 4.47495 20.4082 9.99995 20.4082C14.6625 20.4082 18.5499 17.2207 19.6625 12.9082H17.0625C16.0375 15.8207 13.2625 17.9082 9.99995 17.9082C5.86245 17.9082 2.49995 14.5457 2.49995 10.4082C2.49995 6.2707 5.86245 2.9082 9.99995 2.9082C12.075 2.9082 13.9249 3.7707 15.275 5.1332L11.25 9.1582H20V0.408203L17.0625 3.3457Z"
                            fill="#252525" />
                        </svg>

                      </button>
                    </div>
                    <label for="gstin" class="col-sm-3 col-form-label"></label>



                  </div>
                  <div class="mb-3 row">
                    <label for="gstin" class="col-sm-3 col-form-label"></label>
                    <div class="col-sm-4" *ngIf="captchaVisible1">
                      <input type="text" class="form-control" id="gstin" *ngIf="!setCaptch" name="gstin"
                        [(ngModel)]="captcha" placeholder="Enter Captcha">
                    </div>
                    <div class="text-end" [ngClass]="captchaVisible1 ? 'col-sm-5' : 'col-md-9'">

                      <button class="btn btn-outline-dark px-4 me-2 mb-3 " (click)="cancel()">{{ 'common.Clear' | i18next }}</button>
                      <button class="btn btn-dark px-4 mb-3" *ngIf="setCaptch" (click)="searchGstin()">{{ 'label.Search' | i18next }}</button>
                      <button class="btn btn-dark px-4 mb-3" *ngIf="!setCaptch" (click)="searchGst()">{{ 'label.Search' | i18next }}</button>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-md-3" *ngIf="!setCaptch">
        <div class="iris-dashboard-cards2">
          <img src="../../../../../assets/images/landingpage/sandboximg.png" height="200" />
        </div>
      </div> -->
          </div>
          <div class="iris-dashboard-cards iris-news-updates mt15" *ngIf="showResults">
            <div class="visibleGstin" id="visibleGstin">
              <h4 *ngIf="!isErrorPresent" class="mb-4 success-field">{{ 'label.GSTIN details fetched successfully' | i18next }}</h4>
              <h4 *ngIf="isErrorPresent" class="mb-4 error-field text-center">{{ 'common.Error Details' | i18next }}</h4>

              <div class="content text-start ms-0">
                <div class="mb-3 rowIndex">
                  <div class="col-12">
                    <ng-container *ngIf="isErrorPresent">
                      <div class="row mt-3  text-center">
                        <div class="col-md-12">
                          <img src="../../../../../../assets/images/No-Data-found.png" height="200" />
                          <p>{{errorMessage}}</p>
                        </div>

                      </div>
                      <div class="col-12 text-end">
                        <button class="btn btn-dark px-4 mb-3" (click)="getUpdatedData()">{{ 'label.Update From Common Portal' | i18next }}</button>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="!isErrorPresent">
                      <div class="row mt-3">
                        <div class="col-md-12 col-12 font-size-14">

                          <div class="row mb-3">
                            <div class="col-md-3 col-12">
                              <label class="static-label lbl">{{ 'formSteps.GSTIN' | i18next }} : </label>
                            </div>
                            <div class="col-md-9 col-12">
                              <span class="textBreakAll">{{gstinDetail?.gstin}}</span>
                            </div>
                          </div>

                          <div class="row mb-3">
                            <div class="col-md-3 col-12">
                              <label class="static-label lbl">{{ 'formSteps.Trade Name' | i18next }} : </label>
                            </div>
                            <div class="col-md-9 col-12">
                              <span class="textBreakAll">{{gstinDetail?.tradeNm}}</span>
                            </div>
                          </div>

                          <div class="row mb-3">
                            <div class="col-md-3 col-12">
                              <label class="static-label lbl">{{ 'formSteps.Legal Name' | i18next }} : </label>
                            </div>
                            <div class="col-md-9 col-12">
                              <span class="textBreakAll">{{gstinDetail?.legalNm}}</span>
                            </div>
                          </div>

                          <div class="row mb-3">
                            <div class="col-md-3 col-12">
                              <label class="static-label lbl">{{ 'formSteps.Address' | i18next }} : </label>
                            </div>
                            <div class="col-md-9 col-12">
                              <span class="word-next-line">{{generateAddress()}}
                              </span>
                            </div>
                          </div>

                          <div class="row mb-3">
                            <div class="col-md-3 col-12">
                              <label class="static-label lbl">{{ 'formSteps.State' | i18next }} : </label>
                            </div>
                            <div class="col-md-9 col-12">
                              <span class="textBreakAll">{{gstinDetail?.state}}</span>
                            </div>
                          </div>

                          <div class="row mb-3">
                            <div class="col-md-3 col-12">
                              <label class="static-label lbl">{{ 'formSteps.Pincode' | i18next }} : </label>
                            </div>
                            <div class="col-md-9 col-12">
                              <span class="textBreakAll">{{gstinDetail?.pin}}</span>
                            </div>
                          </div>

                          <div class="row mb-3">
                            <div class="col-md-3 col-12">
                              <label class="static-label lbl">{{ 'formSteps.Type' | i18next }} : </label>
                            </div>
                            <div class="col-md-9 col-12">
                              <span class="textBreakAll">{{gstinDetail?.type}}</span>
                            </div>
                          </div>

                          <div class="row mb-3">
                            <div class="col-md-3 col-12">
                              <label class="static-label lbl">{{ 'common.Status' | i18next }} : </label>
                            </div>
                            <div class="col-md-9 col-12">
                              <span class="textBreakAll">{{gstinDetail?.status}}</span>
                            </div>
                          </div>

                          <div class="row mb-3">
                            <div class="col-md-3 col-12">
                              <label class="static-label lbl">{{ 'common.Last Updated Date' | i18next }} : </label>
                            </div>
                            <div class="col-md-9 col-12">
                              <span
                                class="textBreakAll">{{formatDate(gstinDetail.lstupdt,'DD/MM/YYYY','DD-MM-YYYY')}}</span>
                            </div>
                          </div>

                          <div class="row mb-3">
                            <div class="col-md-3 col-12">
                              <label class="static-label lbl">{{ 'common.Is SEZ ' | i18next }}: </label>
                            </div>
                            <div class="col-md-9 col-12">
                              <span class="textBreakAll">{{gstinDetail?.isSez}}</span>
                            </div>
                          </div>

                          <div class="row mb-3">
                            <div class="col-md-3 col-12">
                              <label class="static-label lbl">{{ 'common.Einvoice status' | i18next }} : </label>
                            </div>
                            <div class="col-md-9 col-12">
                              <span class="textBreakAll">{{gstinDetail?.einvoiceEnable}}</span>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="col-12 text-end">
                        <button class="btn btn-dark px-4 mb-3" (click)="getUpdatedData()">{{ 'label.Update From Common Portal' | i18next }}</button>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <ng-container *ngIf="isNotGstin">ABC</ng-container> -->
        <div class="col-md-3 mt15 ">
          <div class="iris-dashboard-cards iris-news-updates h265">
            <h4 class="mb-4">{{ 'label.Search GSTIN' | i18next }}</h4>
            <div class="content">
              <p>{{ 'label.IRIS IRP Search GSTIN' | i18next }}.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>