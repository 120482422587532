<div class="">
  <!-- <app-auth-header></app-auth-header> -->
  <!-- Marquee moved to header-->
  <!-- <div class="col-12 font-size-12 top-space-55">
    <marquee class="marquee-body" *ngIf="marquee && marquee.newsBody">
      {{marquee && marquee.newsBody ? marquee.newsBody :'N/A'}}
    </marquee>
  </div> -->
  <!-- <div class="custom_container mt-3 px-3">
    <div [class.marquee-space]="!marquee ">
       <app-common-gstin-dropdown></app-common-gstin-dropdown>

    </div>

  </div> -->
  <div class="container-fluid custom_container_fluid cursor-pointer landingHeader" (click)="redirectToLogin()">
    <div class="row align-items-center headerDiv">
      <div class="col-sm-3 text-center">
        <img src="../../../../assets/images/gstnlogo.png" alt="..." class="imgClass">
      </div>
      <div class="col-sm-6 text-center">
        <p class="text-center my-0 font-size-28">E-Invoice Registration Portal - IRP 6</p>
        <span class="text-center my-0 highDiv powerTxt"> Powered by IRIS Business Services Ltd. for Goods and Services
          Tax Network </span>
        <span class="text-center my-0 smallDiv powerTxt"> Powered by IRIS for GSTN </span>
      </div>
      <div class="col-sm-3 text-center">
        <img src="../../../../assets/images/IRISIRP-Logo-1.png" class="imgClass">
      </div>
    </div>

  </div>
  <nav class="navbar navbar-expand-lg navbar-light bg-transparent custom_navbar">
    <div class="container-fluid px-5">
      <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
        aria-label="Toggle navigation">
        <span class=""><i class="bi bi-list"></i></span>
      </button>
      <span class="mr10 smallDiv mt-10">
        <a [class.active]="isMenuSelected == 'Sandbox'" class="btn btn-primary rounded p13 btnContact1"  href="https://sandbox.einvoice6.gst.gov.in/signup" target="_blank" >{{ 'new.Sandbox' | i18next }}</a>
        </span>
        <span class="mr10 smallDiv mt-10">
          <a [class.active]="isMenuSelected == 'Login'" class="btn btn-primary rounded p13 btnContact" (click)="redirectTOLoginPage()" >{{ 'label.Login' | i18next }}</a>
          </span>
          <span class="nav-item dropdown pl0 smallDiv mr10 mt-10">
            <a class="btnLang " href="javascript:;" id="navbarDropdown02" role="button" data-bs-toggle="dropdown"
              aria-expanded="false"><img src="../../../../assets/images/landingpage/default.png" class="btnLangImg"> </a>
            <ul class="dropdown-menu t60 left-3" aria-labelledby="navbarDropdown02">
              <select (change)="changeLanguage($event)" class="language-switch" [value]="i18nextSvc.language">
                <option value="en">English</option>
                <option value="marathi">Marathi</option>
                <!-- <option value="tamil">Tamil</option> -->
                <option value="telugu">Telugu</option>
              </select>
            </ul>
          </span>
      <div class="navbar-collapse collapse" id="navbarSupportedContent" >
        <ul class="mb-2 mb-lg-0 ms-auto navbar-nav custom_navbar_nav">
          <li class="nav-item dropdown">
            <a class="nav-link " (mouseenter)="isMenuSelected = 'Taxpayers'" [class.active]="isMenuSelected == 'Taxpayers'" href="javascript:;" id="navbarDropdown0" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              {{ 'new.Taxpayers' | i18next }} <span><i class="bi bi-chevron-down"></i></span>
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown0">
              <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/e-invoicing-for-taxpayers/">{{ 'new.E-invoicing for Taxpayers' | i18next }}</a></li>
              <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/kb/registration/">{{ 'new.Registering with IRIS IRP' | i18next }}</a></li>
              <li class="dropdown dropend"><a class="dropdown-item" href="javascript:;" id="navbarDropdown101" role="button"  (mouseenter)="isMenuSelected = 'ForWhom'" data-bs-toggle="dropdown"
                aria-expanded="false">{{ 'new.Solutions' | i18next }} <span class="">></span></a>
              <ul class="dropdown-menu submenu" aria-labelledby="navbarDropdown101">
                <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/irp-web-portal/">{{ 'new.Web Portal' | i18next }}</a></li>
                <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/excel-utility/">{{ 'new.Excel Utility' | i18next }}</a></li>
                <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/kbtopic/developerconsole/">{{ 'new.API Integrators' | i18next }}</a></li>
                <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/erp-billing-systems/">{{ 'new.ERP / Billing Systems' | i18next }}</a></li>
                <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/mobile-web-application/">{{ 'new.Mobile Web Application' | i18next }}</a></li>
              </ul>
            </li>
              <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/kbtopic/developerconsole/">{{ 'new.Product Guide' | i18next }}</a></li>
              <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/pricing-plan/">{{ 'new.Pricing Plan' | i18next }}</a></li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link" (mouseenter)="isMenuSelected = 'Developers'" [class.active]="isMenuSelected == 'Developers'" href="javascript:;"id="navbarDropdown" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              {{ 'new.API Developers' | i18next }} <span><i class="bi bi-chevron-down"></i></span>
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/kb/api-integrator-registration/" >{{ 'new.Registering with IRIS IRP' | i18next }}</a></li>
            <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/kbtopic/developerconsole/" >{{ 'new.API Documentation' | i18next }}</a></li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link " (mouseenter)="isMenuSelected = 'Help'" [class.active]="isMenuSelected == 'Help'" href="javascript:;" id="navbarDropdown0" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              {{ 'new.Help' | i18next }} <span><i class="bi bi-chevron-down"></i></span>
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown0">
              <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/faq-powered-by-irisirp/">{{ 'new.FAQ' | i18next }}</a></li>
              <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/public-keys/">{{ 'new.Public Keys' | i18next }} <span class="blink_me">{{ 'new.Updated' | i18next }}</span></a></li>
              <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/validation-rules/">{{ 'new.Validation Rules' | i18next }}</a></li>
              <li class="dropdown dropend"><a class="dropdown-item" href="javascript:;" id="navbarDropdown101" role="button"  (mouseenter)="isMenuSelected = 'ForWhom'" data-bs-toggle="dropdown"
                aria-expanded="false">{{ 'new.E-invoice Schema' | i18next }} <span class="">></span></a>
              <ul class="dropdown-menu submenu" aria-labelledby="navbarDropdown101">
                <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/general-master/">{{ 'new.General Master' | i18next }}</a></li>
                <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/irn-2/">{{ 'new.IRN' | i18next }}</a></li>
              </ul>
            </li>
              <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/kb/troubleshooting-common-errors/">{{ 'new.Resolving Common Errors' | i18next }}</a></li>
              <li><a class="dropdown-item"  href="https://selfservice.gstsystem.in/ReportIssue.aspx">{{ 'new.Report Issue' | i18next }}</a></li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link" (mouseenter)="isMenuSelected = 'Search'" [class.active]="isMenuSelected == 'Search'" href="javascript:;"id="navbarDropdown" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              {{ 'label.Search' | i18next }} <span><i class="bi bi-chevron-down"></i></span>
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item"  (click)="redirectTo('master-search')" >{{ 'label.Masters' | i18next }}</a></li>
            <li><a class="dropdown-item" (click)="redirectTo('enablement-status')"  >{{ 'new.EInvoice Enablement Status' | i18next }}</a></li>
            <li><a class="dropdown-item" (click)="redirectTo('verify-qrcode')" >{{ 'new.Verify QR Code' | i18next }}</a></li>
            <li><a class="dropdown-item" (click)="redirectTo('verify-signedInvoice')" >{{ 'new.Verify Signed E-Invoice' | i18next }}</a></li>
            <li><a class="dropdown-item" (click)="redirectTo('gstin-status')" >{{ 'new.Gstin Status' | i18next }}</a></li>


            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link" (mouseenter)="isMenuSelected = 'Resources'" [class.active]="isMenuSelected == 'Resources'" href="javascript:;"id="navbarDropdown" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              {{ 'new.Resources' | i18next }} <span><i class="bi bi-chevron-down"></i></span>
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/einvoice-mandate/" >{{ 'new.E-Invoice Mandat' | i18next }}</a></li>
            <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/irp-for-e-invoicing/" >{{ 'new.IRIS IRP for E-Invoicing' | i18next }}</a></li>
            <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/notifications/" >{{ 'new.GST Notifications' | i18next }}</a></li>
            <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/blog/" >{{ 'new.Blog' | i18next }}</a></li>
            <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/news/" >{{ 'new.E-invoice and GST in News' | i18next }}</a></li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link" (mouseenter)="isMenuSelected = 'About'" [class.active]="isMenuSelected == 'About'" href="javascript:;"id="navbarDropdown" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              {{ 'new.About Us' | i18next }} <span><i class="bi bi-chevron-down"></i></span>
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item"  href="https://irisirp.com/" >{{ 'new.About IRIS IRP' | i18next }}</a></li>
            <li><a class="dropdown-item"  href="https://irisgst.com/" >{{ 'new.About IRIS GST' | i18next }}</a></li>
            <li><a class="dropdown-item"  href="https://www.irisbusiness.com/" >{{ 'new.About IRIS Business' | i18next }}</a></li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link" (mouseenter)="isMenuSelected = 'Contact Us'" [class.active]="isMenuSelected == 'Contact Us'" href="https://einvoice6.gst.gov.in/content/contact-us/" id="navbarDropdown" role="button"
              aria-expanded="false">
              {{ 'new.Contact Us' | i18next }}
            </a></li>

          <li class="nav-item highDiv pl0">
            <a class="nav-link" [class.active]="isMenuSelected == 'Sandbox'" class="btn btn-primary rounded p13 btnContact1"  href="https://sandbox.einvoice6.gst.gov.in/signup" target="_blank" >{{ 'new.Sandbox' | i18next }}</a>
          </li>
          <li class="nav-item highDiv pl0">
            <a class="nav-link" [class.active]="isMenuSelected == 'Login'" class="btn btn-primary rounded p13 btnContact"  (click)="redirectTOLoginPage()">{{ 'label.Login' | i18next }}</a>
          </li>
          <li class="nav-item dropdown pl0">
            <a class="btnFont" href="javascript:;" id="navbarDropdown02" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">A </a>
            <ul class="dropdown-menu t60" aria-labelledby="navbarDropdown02">
              <li><a (click)="changeSizeByBtn('20px')" class="mr dropdown-item text-center" href="javascript:;">A<sup>+</sup></a></li>
              <li><a href="javascript:;" class="dropdown-item text-center" (click)="changeSizeByBtn('unset')">A<sup>-</sup></a></li>
            </ul>
          </li>
          <li class="nav-item dropdown pl0">
            <!-- <a class="btnLang hideBtnLang" href="javascript:;" id="navbarDropdown02" role="button" data-bs-toggle="dropdown"
              aria-expanded="false"><img src="../../../../assets/images/landingpage/default.png" class="btnLangImg"> </a>
             -->
              <select class="langSelection" aria-labelledby="navbarDropdown02" (change)="changeLanguage($event)"  [value]="i18nextSvc.language">
                <option value="en">English</option>
                <option value="marathi">Marathi</option>
                <!-- <option value="tamil">Tamil</option> -->
                <option value="telugu">Telugu</option>
              </select>

          </li>


        </ul>

      </div>
    </div>
  </nav>
  <!-- <div class="flasingInfo">
    <p>Generate e-invoices, send payment links and reminders, and streamline cashflow with <a class="blink_me" style="color: #319645;font-size: 18px;font-weight: 500;" title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website" href="https://irisperidot.com/login" target="_blank" rel="noopener" (click)="generateEvent()">IRIS Peridot Plus</a></p>
  </div> -->
  <div class="container-fluid px-0" style="position: relative;">
    <marquee class="marque mt-0 font-size-14" *ngIf="marquee && marquee.newsBody">
      {{marquee && marquee.newsBody ? marquee.newsBody :'N/A'}} <a
        style="color: #007ad9; font-size: 14px; font-weight:500;" *ngIf="marquee.moreInfoExternalLink"
        [href]="marquee.moreInfoExternalLink" target="_blank" class="font-size-14">from here</a>
    </marquee>
  </div>

  <ng-template #template>
    <div class="modal-body">
      <p>{{ 'new.The link shall take you' | i18next }}</p>
    </div>
    <div class="modal-footer">
      <a class="text_black text-decoration-none" (click)="modalRef?.hide()">{{ 'new.Skip' | i18next }}</a>

      <button type="button" class="btn btn_primary_theme font-size-14 ms-2" (click)="redirectToPage()">
        {{ 'new.Ok' | i18next }}
      </button>

    </div>
  </ng-template>
  <router-outlet></router-outlet>
</div>
<div class="container-fluid" id="increaseFont" style="background-color: #faf9fa;">
  <div class="row vh-100 align-items-center banner-sidebar customVh">
    <div class="col-lg-5 text-center diplayFooter">
      <img src="assets/images/Infographic.png" class="img-fluid" />
      <div class="row">
        <div class="col-12  px-lg-5 text-lg-start">
          <h6>IRIS IRP for an easy, efficient and enhanced E-invoicing journey</h6>
          <ul>
            <li>Manage e-invoicing for multiple GSTINs from single login</li>
            <li>Bulk IRN generation as well as cancellation</li>
            <li>Multiple modes for generation – API, Web and Desktop Utility</li>
            <li>Simple and intuitive UI designed for business teams</li>
          </ul>
          <h6>Sign-up and explore the value-added services, extending beyond e-invoicing</h6>
        </div>
      </div>
    </div>
    <div class="col-lg-7">
      <div class="card border-0 shadow py-4 px-3 mx-auto" style="max-width: 33.5rem;">
        <div class="card-body">
          <form [formGroup]="setPasswordForm">
            <h2 class="card-title mb-5" style="font-weight: 700 !important; color: #252525;">Set your
              password</h2>
            <div class="row">
              <div class="col-12 small text-muted mb-3">
                <i class="bi bi-info-circle-fill"></i> Password shall have:
                <ul>
                  <li>Minimum 8 and Maximum 16 characters</li>
                  <li>Atleast one character in uppercase and lowercase
                  </li>
                  <li>Atleast one special character
                  </li>
                  <li>Atleast one numeric value
                  </li>
                </ul>
              </div>
              <div class="col-12 mb-3">
                <label for="exampleFormControlInput2" class="form-label"><b>New Password</b><span
                    style="color: red">*</span></label>
                <div class="col-12 position-relative">
                  <div class="custom_input">
                    <div class="input-icons">

                      <i class="bi bi-eye icon" *ngIf="show" (click)="onClick()"></i>
                      <i class="bi bi-eye-slash icon" *ngIf="!show" (click)="onClick()"></i>
                      <input [type]="password" class="form-control" name="password" formControlName="password"
                        (ngModelChange)="checkPasswordMatch()" placeholder="********">
                    </div>
                    <div *ngIf="setPasswordForm.get('password')!.invalid" class="text-danger m-1">
                      <p class="m-0 text-danger font-size-12" *ngIf="setPasswordForm.get('password')!.touched && setPasswordForm.get('password')!.value === ''
                                          ">* Password is required
                      </p>
                      <p *ngIf="setPasswordForm.get('password')!.touched && setPasswordForm.get('password')!.value !== '' && setPasswordForm.get('password')!.errors!['minlength']
                                          " class="m-0 text-danger font-size-12">* Your password must have minimum 8
                        characters
                      </p>
                      <p *ngIf="setPasswordForm.get('password')!.touched && setPasswordForm.get('password')!.value !== '' && setPasswordForm.get('password')!.errors!['maxlength']
                                      " class="m-0 text-danger font-size-12">* Your Password must have maximum 16
                        characters
                      </p>
                      <p *ngIf="setPasswordForm.get('password')!.touched && setPasswordForm.get('password')!.value !== '' && setPasswordForm.get('password')!.errors!['hasCapitalCase']
                                          " class="m-0 text-danger font-size-12">* Your password should contain at
                        least 1 capital letter
                      </p>
                      <p *ngIf="setPasswordForm.get('password')!.touched && setPasswordForm.get('password')!.value !== '' && setPasswordForm.get('password')!.errors!['hasSpecialCharacters']
                                          " class="m-0 text-danger font-size-12">* Your password should contain at
                        least 1 special character
                      </p>
                      <p *ngIf="setPasswordForm.get('password')!.touched && setPasswordForm.get('password')!.value !== '' && setPasswordForm.get('password')!.errors!['hasNumber']
                                          " class="m-0 text-danger font-size-12">* Your password should contain at
                        least 1 number
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 mb-3">
                <label for="exampleFormControlInput2" class="form-label"><b>Confirm Password</b> <span
                    style="color: red">*</span></label>
                <div class="col-12 position-relative">
                  <div class="custom_input">
                    <div class="input-icons">

                      <i class="bi bi-eye icon" *ngIf="showNew" (click)="onClickNew()"></i>
                      <i class="bi bi-eye-slash icon" *ngIf="!showNew" (click)="onClickNew()"></i>
                      <input type="password" class="form-control" name="confirmPassword"
                        formControlName="confirmPassword" [type]="passwords" (ngModelChange)="checkPasswordMatch()"
                        placeholder="********">
                    </div>
                  </div>
                  <div *ngIf="!passwordMatched">
                    <span class="font-size-12 text-danger">New Password and Confirm Password
                      should
                      match</span>
                  </div>
                </div>
              </div>
              <div class="col-12 my-3 text-center">
                <button type="submit" class="btn btn-dark  py-2"
                  [disabled]="(!setPasswordForm.value.password ||  !setPasswordForm.value.confirmPassword) || !passwordMatched"
                  (click)="setPassword()">Set Password</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

</div>
<div class="container-fluid footer">
  <div class="row footerDiv">
    <div class="col-md-9">
      <h3 class="display-4 text-white">{{ 'new.IRIS IRP is govt authorised E-Invoice Registration Portal' | i18next }}</h3>
    </div>
    <div class="col-md-3">
      <a href="https://einvoice6.gst.gov.in/content/contact-us/" target="blank"
        class="btn btn-primary rounded mb-0 text-nowrap">{{ 'label.Contact Us' | i18next }}</a>
    </div>
    <hr class="hrdiv">
    <div class="col-md-3">
      <h4 class="widget-title text-white mb-3">{{ 'new.Important links' | i18next }}</h4>
      <ul class="text-white" style="list-style: none; padding-left: 0px;">
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://www.gstn.org.in/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.GSTN' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://cbic-gst.gov.in/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.CBIC' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisirp.com/notifications/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.GST notifications' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://www.india.gov.in/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.National Portal' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://einvoice6.gst.gov.in/content/wp-content/uploads/2023/04/Letter-ON-NEW-IRPS-20April23.pdf"
            target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.GSTN Authorization of IRIS IRP' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://sandbox.einvoice6.gst.gov.in/login" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.IRIS IRP Sandbox' | i18next }}</a>
        </li>
      </ul>
    </div>
    <div class="col-md-3">
      <h4 class="widget-title text-white mb-3">{{ 'new.IRIS IRP Policies' | i18next }}</h4>
      <ul class="text-white" style="list-style: none; padding-left: 0px;">
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://einvoice6.gst.gov.in/content/terms-and-conditions/">{{ 'new.Terms and Condition' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://einvoice6.gst.gov.in/content/privacy/">{{ 'new.Privacy Policy' | i18next }}</a>
        </li>

      </ul>

    </div>
    <div class="col-md-3">
      <h4 class="widget-title text-white mb-3">{{ 'new.About IRIS' | i18next }}</h4>
      <ul class="text-white" style="list-style: none; padding-left: 0px;">
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisirp.com/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.IRIS IRP' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisgst.com/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.IRIS GST' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisbusiness.com/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.IRIS Business' | i18next }}</a>
        </li>

      </ul>
      <h4 class="widget-title text-white mb-3">{{ 'new.Need Help' | i18next }}</h4>
      <ul class="text-white" style="list-style: none; padding-left: 0px;">
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="mailto:support@irisirp.com">{{ 'new.Write to: support@irisirp.com' | i18next }}</a>
        </li>
      </ul>
    </div>
    <div class="col-md-3">
      <h4 class="widget-title text-white mb-3">{{ 'new.Other Products' | i18next }}</h4>
      <ul class="text-white" style="list-style: none; padding-left: 0px;">
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;"> <a
            href="https://irisgst.com/irissapphire/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.IRIS GST Software' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisgst.com/iris-onyx/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.IRIS E-Invoicing' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisgst.com/gst-litigation-management-system/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.IRIS Litigation Management' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisgst.com/iristopaz/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.IRIS E-Way Bill' | i18next }}  </a>
        </li>

        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisgst.com/iris-lookup/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.IRIS LookUp' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisgst.com/irisperidot/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.IRIS Peridot' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://easywaybill.in/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.EWB app for transporters' | i18next }}</a>
        </li>
      </ul>
    </div>
    <hr class="hrdiv mb10">
    <div class="row">
      <div class="col-md-3">
        <div class="social social-white social-center">
          <span><a href="https://twitter.com/IRIS_IRP" target="blank"><i
                style="color:#fff; font-size: 18px;     padding-right: 16px; font-weight: 400;padding-bottom: 15px;"
                class="bi bi-twitter"></i></a></span>
          <span><a href="https://www.linkedin.com/showcase/82795027/admin/" target="blank"><i
                style="color:#fff; font-size: 18px;     padding-right: 16px; font-weight: 400;padding-bottom: 15px;"
                class="bi bi-linkedin"></i></a></span>
          <span><a href="https://www.youtube.com/@irisirp" target="blank"><i
                style="color:#fff; font-size: 18px;     padding-right: 16px; font-weight: 400;padding-bottom: 15px;"
                class="bi bi-youtube"></i></a></span>
        </div>
      </div>
      <div class="col-md-6">
        <p class="text-center text-white">© {{ 'new.2023 IRIS Business Services Ltd' | i18next }}.</p>
      </div>
      <div class="col-md-3 social-center">
        <span><img class="responsive" style="height: 40px;width: 100px;"
            src="../../../../assets/images/brands/sslcertificate.png" alt=""></span>
        <span><img class="responsive" style="height: 40px;width: 100px;" src="../../../../assets/images/brands/iso.png"
            alt=""></span>

      </div>
    </div>
  </div>
</div>
