<div class="gradient_wrapper">
  <!--nav-->
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark custom-navbar">
    <div class="custom_container">
      <span class="menu-open-icon"><i class="bi bi-list font-size-30"></i></span>
      <div id="mySidenav" class="sidenav d-flex">
        <span class="closebtn"><i class="bi bi-x font-size-30"></i></span>
        <ul #test class="font-size-16" class="navbar-nav me-auto ps-lg-0" style="padding-left: 0.15rem"
          style="font-weight: 700; display: flex; align-items: center">
          <li (click)="redirectToPage('home')">
            <img src="assets/images/iris-reverse-white-logo.png" class="logo" />
          </li>

          <li class="nav-item dropdown dropdown-mega position-static" (mouseover)="mouseOver('mouseOveredGenerate')"
            (mouseout)="mouseOut('mouseOveredGenerate')">
            <a class="nav-link dropdown-toggle " [class.display]="mouseOveredGenerate" href="javascript:;"
              [class.highlight]="activeMenu == 'Generate'" data-bs-toggle="dropdown" data-bs-auto-close="outside"
              aria-expanded="true">{{ 'label.Generate' | i18next }}</a>
            <div class="dropdown-menu2 shadow  " [class.display]="mouseOveredGenerate" data-bs-popper="static">
              <div class="mega-content p-4">
                <div class="container-fluid">
                  <div class="row">
                    <!-- <h5>Explicabo voluptas</h5> -->
                    <div class="col-12 col-sm-4 col-md-6 py-3" (click)="redirectToPage('einvoice',true)">
                      <div class="header-menu-list">
                        <img src="assets/images/Invoice1.png" class="img-fluid shadow-1-strong rounded"
                          alt="Invoice" />

                        <div >
                          <h6 class="">
                            {{ 'label.E-Invoice' | i18next }}
                          </h6>
                          <p class="wrap">{{ 'label.A simple web-form' | i18next }}</p>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-12 col-sm-4 col-md-6 py-3">
                      <div class="header-menu-list">
                        <img src="../../../../assets/images/procurement.png" class="img-fluid shadow-1-strong rounded"
                          alt="Procurement" />

                        <div>
                          <h6 class="">
                            E-way Bill by IRN
                            <span class="badge bg-warning custom-badge"> Upcoming </span>
                          </h6>
                          <p class="wrap">For e-invoices already available, generate eway bill</p>
                        </div>
                      </div>
                    </div> -->
                    <div class="col-12 col-sm-4 col-md-6 py-3" (click)="redirectToPage('bulk-generate',true)">
                      <div class="header-menu-list">
                        <img src="assets/images/bulk Invoice.png" class="img-fluid shadow-1-strong rounded"
                          alt="Bill" />

                        <div >
                          <h6 class="">
                            {{ 'label.Bulk Operations' | i18next }}
                          </h6>
                          <p class="wrap"> {{ 'label.Generate and cancel' | i18next }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>

          <!-- Manage -->
          <li class="nav-item dropdown dropdown-mega position-static" (mouseover)="mouseOver('mouseOveredManage')"
            (mouseout)="mouseOut('mouseOveredManage')">
            <a class="nav-link dropdown-toggle " [class.display]="mouseOveredManage" href="javascript:;"
              [class.highlight]="activeMenu == 'Manage'" data-bs-toggle="dropdown" data-bs-auto-close="outside"
              aria-expanded="true">{{ 'label.Manage' | i18next }} </a>
            <div class="dropdown-menu2 shadow  " [class.display]="mouseOveredManage" data-bs-popper="static">
              <div class="mega-content p-4">
                <div class="container-fluid">
                  <div class="row">
                    <!-- <h5>Explicabo voluptas</h5> -->
                    <div class="col-12 col-sm-4 col-md-6 py-3" (click)="redirectToPage('view-invoice')">
                      <div class="header-menu-list">
                        <img src="assets/images/file.png" class="img-fluid shadow-1-strong rounded"
                          alt="File" />
                        <div >
                          <h6 class="">
                            {{ 'label.View Invoices' | i18next }}
                          </h6>
                          <p class="wrap">{{ 'label.And take actions' | i18next }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-4 col-md-6 py-3" (click)="redirectToPage('search-invoice')">
                      <div class="header-menu-list">
                        <img src="assets/images/loupe.png" class="img-fluid shadow-1-strong rounded"
                          alt="Loupe" />
                        <div >
                          <h6 class="">
                            {{ 'label.Search' | i18next }}
                          </h6>
                          <p class="wrap"> {{ 'label.Get or search' | i18next }}</p>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-12 col-sm-4 col-md-6 py-3">
                      <div class="header-menu-list">
                        <img src="../../../../assets/images/verify.png" class="img-fluid shadow-1-strong rounded"
                          alt="Verify" />
                        <div (click)="redirectToPage('verify-invoice')">
                          <h6 class="">
                            Verify
                          </h6>
                          <p class="wrap">Check the authenticity of IRN, QR code or a signed e-invoice
                          </p>
                        </div>
                      </div>
                    </div> -->

                    <!-- <div class="col-12 col-sm-4 col-md-6 py-3">
                      <div class="header-menu-list">

                        <img src="../../../../assets/images/folder.png" class="img-fluid shadow-1-strong rounded"
                          alt="Archived Invoice" />
                        <div>
                          <h6 class="">

                            Archived Invoices
                            <span class="badge bg-success custom-badge"> VAS </span>

                          </h6>
                          <p class="wrap">View and access historical einvoices and eway bills
                          </p>
                        </div>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </li>


          <!-- Analytics-->

          <li class="nav-item dropdown dropdown-mega position-static" (mouseover)="mouseOver('mouseOveredAnalytics')"
            (mouseout)="mouseOut('mouseOveredAnalytics')">
            <a class="nav-link dropdown-toggle " [class.display]="mouseOveredAnalytics" href="javascript:;"
              [class.highlight]="activeMenu == 'Analytics'" data-bs-toggle="dropdown" data-bs-auto-close="outside"
              aria-expanded="true">{{ 'label.Analytics' | i18next }}</a>
            <div class="dropdown-menu2 shadow purchase-mega-menu " [class.display]="mouseOveredAnalytics"
              data-bs-popper="static">
              <div class="mega-content p-4">
                <div class="container-fluid">
                  <div class="row">
                    <!-- <h5>Explicabo voluptas</h5> -->
                    <div class="col-12 col-sm-4 col-md-6 py-3" (click)="redirectToPage('sales-overview')">
                      <div class="header-menu-list">
                        <img src="assets/images/sales.png" class="img-fluid shadow-1-strong rounded"
                          alt="Hollywood Sign on The Hill" />
                        <div >
                          <h6 class="">
                            {{ 'label.Sales Overview' | i18next }}
                          </h6>
                          <p class="wrap">{{ 'label.Stay on top' | i18next }} </p>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-12 col-sm-4 col-md-6 py-3">
                      <div class="header-menu-list">
                        <img src="../../../../assets/images/purchase-order.png"
                          class="img-fluid shadow-1-strong rounded" alt="Hollywood Sign on The Hill" />
                        <div>
                          <h6 class="">
                            Purchase Snapshot
                            <span class="badge bg-warning custom-badge"> Upcoming </span>

                          </h6>
                          <p class="wrap">Get insights from your B2B purchases and maximise ITC</p>
                        </div>
                      </div>
                    </div> -->
                    <div class="col-12 col-sm-4 col-md-6 py-3" (click)="redirectToPage('error-discrepancies')">
                      <div class="header-menu-list">
                        <img src="../../../../assets/images/warning.png" class="img-fluid shadow-1-strong rounded"
                          alt="Hollywood Sign on The Hill" />
                        <div>
                          <h6 class="">
                            {{ 'label.Errors and discrepancies' | i18next }}
                            <!-- <span class="badge bg-warning custom-badge"> Upcoming </span> -->

                          </h6>
                          <p class="wrap">{{ 'label.Keep a tab' | i18next }}
                          </p>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </li>


          <!-- My Purchases-->
          <li class="nav-item dropdown dropdown-mega position-static " (mouseover)="mouseOver('mouseOverPurchases')"
            (mouseout)="mouseOut('mouseOverPurchases')">
            <a class="nav-link dropdown-toggle " [class.display]="mouseOverPurchases" href="javascript:;"
              [class.highlight]="activeMenu == 'Purchase'" data-bs-toggle="dropdown" data-bs-auto-close="outside"
              aria-expanded="true">{{ 'label.My Purchase' | i18next }}</a>
            <div class="dropdown-menu2 shadow  purchase-mega-menu" [class.display]="mouseOverPurchases"
              data-bs-popper="static">
              <div class="mega-content p-4">
                <div class="container-fluid">
                  <div class="row">
                    <!-- <h5>Explicabo voluptas</h5> -->
                    <div class="col-12 col-sm-4 col-md-6 py-3" (click)="redirectToPage('viewShared-invoice')">
                      <div class="header-menu-list">
                        <img src="assets/images/data-transfer.png" class="img-fluid shadow-1-strong rounded"
                          alt="Hollywood Sign on The Hill" />
                        <div >
                          <h6 class="">
                            {{ 'label.View Shared' | i18next }}
                          </h6>
                          <p class="wrap">{{ 'label.Get access' | i18next }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-4 col-md-6 py-3">
                      <div class="header-menu-list">
                        <img src="assets/images/report.png" class="img-fluid shadow-1-strong rounded"
                          alt="Hollywood Sign on The Hill" />
                        <div class="wrapTxt">
                          <h6 class="">
                            {{ 'label.Get GSTR 2B' | i18next }}
                            <span class="badge bg-warning custom-badge">{{ 'label.Upcoming' | i18next }} </span>
                          </h6>
                          <p class="wrap">{{ 'label.Fetch data' | i18next }}</p>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </li>


          <!-- Tools-->
          <li class="nav-item dropdown dropdown-mega position-static" (mouseover)="mouseOver('mouseOveredTools')"
            (mouseout)="mouseOut('mouseOveredTools')">
            <a class="nav-link dropdown-toggle " [class.display]="mouseOveredTools" href="javascript:;"
              [class.highlight]="activeMenu == 'Tools'" data-bs-toggle="dropdown" data-bs-auto-close="outside"
              aria-expanded="true">{{ 'label.Tools' | i18next }}</a>
            <div class="dropdown-menu2 shadow tools-mega-menu" [class.display]="mouseOveredTools" data-bs-popper="static">
              <div class="mega-content p-4">
                <div class="container-fluid">
                  <div class="row">
                    <!-- <h5>Explicabo voluptas</h5> -->
                    <div class="col-12 col-sm-4 col-md-6 py-3" (click)="redirectToPage('verify-invoice')">
                      <div class="header-menu-list">
                        <img src="assets/images/verify.png" class="img-fluid shadow-1-strong rounded"
                          alt="Verify" />
                        <div >
                          <h6 class="">
                            {{ 'label.Verify' | i18next }}
                          </h6>
                          <p class="wrap">  {{ 'label.Check the authenticity' | i18next }}.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-4 col-md-6 py-3" (click)="redirectToPage('gstin-status')">
                      <div class="header-menu-list">
                        <img src="assets/images/GSTIN.png" class="img-fluid shadow-1-strong rounded"
                          alt="Hollywood Sign on The Hill" />
                        <div >
                          <h6 class="wrap">
                            {{ 'label.GSTIN Status' | i18next }}

                          </h6>
                          <p class="wrap">
                            {{ 'label.Check GSTIN' | i18next }}

                          </p>
                        </div>
                      </div>
                    </div>


                    <div class="col-12 col-sm-4 col-md-6 py-3" (click)="redirectToPage('einvoice-status')">
                      <div class="header-menu-list">
                        <img src="assets/images/E-Invoice Enanblement.png"
                          class="img-fluid shadow-1-strong rounded" alt="Hollywood Sign on The Hill" />
                        <div >
                          <h6 class="wrap">
                            {{ 'label.E-invoice Enablement' | i18next }}
                          </h6>
                          <p class="wrap"> {{ 'label.Check e-invoice' | i18next }}


                          </p>
                        </div>
                      </div>


                    </div>


                    <div class="col-12 col-sm-4 col-md-6 py-3" (click)="redirectToPage('hsn-finder')">
                      <div class="header-menu-list">
                        <img src="assets/images/HSN Finder.png" class="img-fluid shadow-1-strong rounded"
                          alt="Hollywood Sign on The Hill" />
                        <div >
                          <h6 class="">
                            {{ 'label.HSN/SAC Finder' | i18next }}
                          </h6>
                          <p class="wrap">
                            {{ 'label.Find HSN/SAC' | i18next }} </p>
                        </div>
                      </div>
                    </div>



                    <div class="col-12 col-sm-4 col-md-6 py-3"  (click)="redirectToPage('masters-finder')">
                      <div class="header-menu-list">
                        <img src="assets/images/Master Codes.png" class="img-fluid shadow-1-strong rounded"
                          alt="Hollywood Sign on The Hill" />
                        <div>
                          <h6 class="">
                            {{ 'label.Master Codes' | i18next }}
                          </h6>
                          <p class="wrap">
                            {{ 'label.Know the masters' | i18next }}.

                          </p>
                        </div>
                      </div>

                    </div>
                    <div class="col-12 col-sm-4 col-md-6 py-3" (click)="redirectToPage('excel-finder')">
                      <div class="header-menu-list">
                        <img src="assets/images/Excel.png" class="img-fluid shadow-1-strong rounded"
                          alt="Hollywood Sign on The Hill" />
                        <div class="wrapTxt">
                          <h6 class="">
                            {{ 'label.Excel Tool' | i18next }}
                          </h6>
                          <p class="wrap">
                            {{ 'label.Desktop based utility' | i18next }}
                          </p>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </li>

          <!-- Resources (On Web also)-->

          <!-- <li class="nav-item dropdown dropdown-mega position-static">
            <a class="nav-link dropdown-toggle " (mouseover)="mouseOver('mouseOveredRes')" (mouseout)="mouseOut('mouseOveredRes')"
              [class.display]="mouseOveredRes" href="javascript:;" data-bs-toggle="dropdown" data-bs-auto-close="outside"
              aria-expanded="true">Resources</a>
            <div class="dropdown-menu2 shadow  " [class.display]="mouseOveredRes" data-bs-popper="static">
              <div class="mega-content p-4">
                <div class="container-fluid">
                  <div class="row">
                     <h5>Explicabo voluptas</h5>
                    <div class="col-12 col-sm-4 col-md-6 py-3">
                      <div class="header-menu-list">
                        <img src="https://mdbcdn.b-cdn.net/img/new/standard/city/041.webp"
                          class="img-fluid shadow-1-strong rounded" alt="Hollywood Sign on The Hill" />
                        <div>
                          <h6 class="">
                            GST Law Updates
                          </h6>
                          <p class="wrap">Quick Excel for IRN Generation
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-4 col-md-6 py-3">
                      <div class="header-menu-list">
                        <img src="https://mdbcdn.b-cdn.net/img/new/standard/city/042.webp"
                          class="img-fluid shadow-1-strong rounded" alt="Palm Springs Road" />
                        <div>
                          <h6 class="">
                            User Manual

                          </h6>
                          <p class="wrap">Look for any State, Country, Pincode, Port and more

                          </p>
                        </div>
                      </div>
                    </div>


                    <div class="col-12 col-sm-4 col-md-6 py-3">
                      <div class="header-menu-list">
                        <img src="https://mdbcdn.b-cdn.net/img/new/standard/city/042.webp"
                          class="img-fluid shadow-1-strong rounded" alt="Palm Springs Road" />
                        <div>
                          <h6 class="">
                            FAQs
                          </h6>
                          <p class="wrap">Compute PIN to PIN distance

                          </p>
                        </div>
                      </div>
                    </div>



                  </div>
                </div>
              </div>
            </div>
          </li>  -->
        </ul>
        <ul class="d-flex justify-content-end align-items-center">
          <li>
            <div class="dropdown" (mouseover)="mouseOver('generateBtn')" (mouseout)="mouseOut('generateBtn')">
              <button class="generate-btn" data-bs-toggle="dropdown" id="dropdownMenuButton1">
                {{ 'label.Generate Quickly' | i18next }}
              </button>

              <ul [class.display]="generateBtn"
                class="dropdown-menu-select generate-btn-inner flip-in-hor-bottom right-open"
                aria-labelledby="dropdownMenuButton1">
                <li>
                  <button (click)="redirectToPage('einvoice')"
                    class="btn btn-success iris-quick-generate-btn yellow py-2">
                    {{ 'label.Single IRN' | i18next }}
                  </button>
                  <button (click)="redirectToPage('bulk-generate')"
                    class="btn btn-success iris-quick-generate-btn green py-2">
                    {{ 'label.Bulk IRN' | i18next }}
                  </button>
                </li>
              </ul>
            </div>
          </li>



          <!-- <li class="nav-item dropdown dropdown-mega  position-static">
            <a class="nav-link" (mouseover)="mouseOver('mouseOveredNotifications')"
              (mouseout)="mouseOut('mouseOveredNotifications')" [class.display]="mouseOveredNotifications"
              href="javascript:;" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="true">
              <i class="font-size-24 bi bi-bell badge-wrapper">

              </i>
            </a>
            <div class="dropdown-menu2 shadow  notifications-mega-menu" [class.display]="mouseOveredNotifications"
              data-bs-popper="static">
              <div class="mega-content p-4">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-12  notification-body">
                      <div class="header-menu-list">




                        <div (click)="redirectToPage('einvoice')">
                          <h6 class="">
                            Bulk Upload EInvoice > Download CSV
                          </h6>
                          <div>
                            <p class=""> <i class="icon-notification bi bi-check-circle-fill font-size-24"></i>
                              Download:28-10-2022 17:50:49</p>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 py-3">
                      <div class="header-menu-list">

                        <div (click)="redirectToPage('einvoice')">
                          <h6 class="">
                            Bulk Upload EInvoice > Download CSV
                          </h6>
                          <div>
                            <p class="">

                              <img src="../../../../assets/images/processing-time.png" class="notification-img" />


                              InProgress:28-10-2022 17:50:49</p>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </li> -->


          <!-- Settings starts here -->
          <li class="nav-item dropdown dropdown-mega  position-static" (mouseover)="mouseOver('mouseOveredSettings')"
            (mouseout)="mouseOut('mouseOveredSettings')">
            <a class="nav-link" [class.display]="mouseOveredSettings" (click)="onSetting()" href="javascript:;" data-bs-toggle="dropdown"
              data-bs-auto-close="outside" aria-expanded="true"> <i class="bi bi-gear font-size-24"></i></a>
            <div class="dropdown-menu2 shadow  settings-mega-menu" id="onSettingId" [class.display]="mouseOveredSettings"
              data-bs-popper="static">
              <div class="mega-content p-4">
                <div class="container-fluid">
                  <div class="row">
                    <!-- <h5>Explicabo voluptas</h5> -->
                    <!-- <div class="col-12 col-sm-3 col-md-3 py-3">
                      <div class="header-menu-list">
                        <img src="../../../../assets/images/user.png" class="img-fluid shadow-1-strong rounded"
                          alt="Hollywood Sign on The Hill" />
                        <div>
                          <h6 class="">
                            General
                          </h6>
                          <div>

                            <p class="wrap">Personal Settings
                            </p>
                            <p class="wrap">Business Profile and Catalog

                            </p>
                            <p>Reset Password

                            </p>
                            <p>Delete my Account

                            </p>
                            <p>Audit Log

                            </p>
                          </div>

                        </div>
                      </div>
                    </div> -->

                    <div class="col-12 col-sm-6 col-md-6 py-3">
                      <div class="header-menu-list">
                        <img src="assets/images/relation.png" class="img-fluid shadow-1-strong rounded"
                          alt="Hollywood Sign on The Hill" />
                        <div>
                          <h6 class="">
                            {{ 'label.Organisation' | i18next }}
                          </h6>
                          <div>

                            <p (click)="redirectToPage('my-gstin')">{{ 'label.My GSTINs' | i18next }}</p>
                            <p (click)="redirectToPage('manage-api-access')" *ngIf="userType === 'false'" class="wrap">{{ 'label.Manage API Access' | i18next }} </p>
                            <p (click)="redirectToPage('manage-users')"  *ngIf="userType === 'false'">{{ 'label.Manage Users' | i18next }} </p>

                          </div>

                        </div>
                      </div>
                    </div>

                    <!-- <div class="col-12 col-sm-4 col-md-4 py-3">
                      <div class="header-menu-list">
                        <img src="../../../../assets/images/template.png" class="img-fluid shadow-1-strong rounded"
                          alt="Hollywood Sign on The Hill" />
                        <div>
                          <h6 class="">
                            Template
                          </h6>
                          <div>

                            <p class="wrap">Invoice Template </p>
                            <p class="wrap">Email Template </p>

                          </div>

                        </div>
                      </div>
                    </div> -->

                    <div class="col-12 col-sm-6 col-md-6 py-3">
                      <div class="header-menu-list">
                        <img src="assets/images/clipboard.png" class="img-fluid shadow-1-strong rounded"
                          alt="Hollywood Sign on The Hill" />
                        <div>
                          <h6 class="">
                            {{ 'label.Masters' | i18next }}
                          </h6>
                          <div>

                            <!-- <p>Supplier </p> -->
                            <p (click)="redirectToPage('customer')">{{ 'label.Customer' | i18next }}</p>
                            <!-- <p>Transporter </p> -->
                            <p (click)="redirectToPage('transport')">{{ 'label.Transporter' | i18next }}</p>

                            <p  (click)="redirectToPage('product-service')">{{ 'label.Product and Service' | i18next }} </p>

                          </div>

                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </li>
          <!-- Settings ends here -->
          <li class="d-flex align-items-center">
            <!-- <div class="d-inline-block px-3">
              <i class="bi bi-gear font-size-24"></i>
            </div> -->

            <!-- <div class="">
              <div class=""> -->
            <!-- <div class="d-flex align-items-center cursor_pointer" data-bs-toggle="dropdown">
                  <span class="user_pic"><img src="../../../../assets/images/admin_user.png" /></span>
                  <span class="font-size-14 text-white">{{ formatProfileName(userName) }} <i
                      class="bi bi-chevron-down ps-1"></i></span>
                </div> -->

            <div class="btn-group profile-btn-group">
              <button type="button" class="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                {{ formatProfileName(userName) }}
              </button>
              <ul class="dropdown-menu drop-end profile-dropdown">
                <li class="bg-white text-center p-3 pb-0 d-flex align-items-center">
                  <div class="circle rounded-pill bg-light logo-background">
                    <img src="assets/images/logo.png" class="" width="80">
                  </div>
                  <div class="text-start ps-3 ">

                    <h6 class="text-dark"> {{ formatProfileName(userName) }}
                    </h6>

                    <span class="text-dark me-2 d-block w-100"><a href="#"  style="color: #007ad9"> {{ formatProfileName(userId) }}</a>
                    </span>
                    <span class="text-dark me-2"><i class="bi bi-file-lock2-fill" style="color:#f7b92f;"></i><a (click)="resetPassword()">{{ 'label.Change Password' | i18next }}</a>
                    </span>
                    <span class="text-dark me-2"><i class="bi bi-file-lock2-fill" style="color:#f7b92f;"></i><a (click)="enableMFA()">{{ 'label.Setup MFA' | i18next }}</a>
                    </span>
                    <!-- <span class="text-dark me-2"><a   (click)="resetPassword()">Change
                                      Password</a>
                              </span><span class="text-dark me-2"><a href="#">Settings</a></span> -->
                  </div>
                </li>
                <li class="bg-white px-3" style="height: 0.5px;">
                  <hr class="dropdown-divider m-0">
                </li>
                <li class="bg-white p-2">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-12 ps-3">
                        <h6 class="text-dark"><b>{{ 'label.Need Help' | i18next }}?</b></h6>
                        <span class="content py-2 me-3"><i class="bi bi-info-circle-fill" style="color:#5dab00;"></i> <a  target="_blank" href="https://einvoice6.gst.gov.in/content/kb/generate-through-web-form/">{{ 'label.Product Help' | i18next }}</a></span>
                        <span class="content py-2"  title="{{ 'label.This link shall' | i18next }}"><i class="bi bi-youtube me-1" style="vertical-align: bottom;color: red;"></i><a  href="https://www.youtube.com/channel/UCcn-R6DrQwhyKJBZosmkCnw" target="_blank"> {{ 'label.Help Videos' | i18next }}</a></span>

                      </div>
                    </div>
                  </div>
                </li>
                <li class="bg-white px-3">
                  <hr class="dropdown-divider m-0 h-auto">
                </li>
                <li class="bg-white p-2">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-12 ps-3 pe-0">
                        <h6 class="text-dark"><b>{{ 'label.Contact Us' | i18next }}</b></h6>
                        <ul class="normal-list">
                          <li class="py-2"><img src="assets/images/feedback.png" class="mailIcon"> <a title="{{ 'label.This link shall' | i18next }}"  href="https://forms.irisirp.com/IRISBusiness/form/ProductFeedback1/formperma/EBW8CklkhLZg9b1vPpJxbK0q4KfZY-5wiHKfVMvcIvw"  target="_blank"> {{ 'label.Feedback Suggestions' | i18next }}</a></li>
                          <li class="py-2"><i class="bi bi-envelope-fill" style="color:#007ad9;"></i>
                            {{ 'label.Write to us at' | i18next }} <a class="d-inline-block mail-anchor p-0"
                              href="mailto: support&#64;irisirp.com"  style="color:#007ad9;">support&#64;irisirp.com</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="bg-white">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-6 px-0">
                        <a class="btn btn-dark w-100 rounded-0"
                          style="border-bottom-left-radius: 5px !important;color: white;" (click)="redirectToPlansPage(selectedPlan)">{{ 'label.My Subscription' | i18next }}</a>
                      </div>
                      <div class="col-6 px-0">
                        <button class="btn btn-light w-100 rounded-0"
                          style="border-bottom-right-radius: 5px !important;" (click)="logout()">{{ 'label.Sign Out' | i18next }}</button>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <!-- </div>
            </div> -->
          </li>
          <li>
            <select (change)="changeLanguage($event)" class="language-switch" [value]="i18nextSvc.language">
              <option value="en">English</option>
              <option value="marathi">Marathi</option>
              <!-- <option value="tamil">Tamil</option> -->
              <option value="telugu">Telugu</option>
            </select>
            </li>
        </ul>
      </div>
    </div>
  </nav>
  <!--nav-END-->
  <!-- responsive menu start -->
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark custom-navbar-2">
    <div class="container noPadding">
        <!--<div data-bs-toggle="dropdown" class=" d-inline-block align-items-center cursor_pointer">
          <!-<span class="user_pic">
                <img src="../../../../assets/images/dummy logo.png" class="me-2"   style="display:none;height: 50px; border-radius: 50%"></span>
                    <span class="font-size-14 text-white"></span> ->
        </div>-->
        <button class="navbar-toggler border-0" type="button" data-bs-toggle="collapse"
        data-bs-target="#navbarNavDarkDropdown" aria-controls="navbarNavDarkDropdown" aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon" style="width:1.2em;margin-left: 15px;"></span>
    </button>


        <a class="navbar-brand" (click)="redirectToPage('home')"><img src="assets/images/iris-reverse-white-logo.png"
          style="max-height: 50px;"></a>

          <button type="button" class="btn dropdown-toggle userBtn" data-bs-toggle="dropdown" aria-expanded="false">
            {{ formatProfileName(userName) }}
          </button>

          <ul class="dropdown-menu drop-end profile-dropdown" id="profileDropdown" >
            <li class="bg-white text-center p-3 pb-0 d-flex align-items-center firstLi">
              <div class="circle rounded-pill bg-light logo-background">
                <img src="assets/images/logo.png" class="" width="80">
              </div>
              <div class="text-start ps-3 w280">

                <h6 class="text-dark"> {{ formatProfileName(userName) }}
                </h6>

                <span class="text-dark me-2 d-block w-100"><a href="#"  style="color: #007ad9"> {{ formatProfileName(userId) }}</a>
                </span>
                <span class="text-dark me-2"><i class="bi bi-file-lock2-fill" style="color:#f7b92f;"></i><a (click)="enableMFA()">{{ 'label.Change Password' | i18next }}</a>
                </span>
                <!-- <span class="text-dark me-2"><a   (click)="resetPassword()">Change
                                  Password</a>
                          </span><span class="text-dark me-2"><a href="#">Settings</a></span> -->
              </div>
            </li>
            <li class="bg-white px-3" style="height: 0.5px;">
              <hr class="dropdown-divider m-0">
            </li>
            <li class="bg-white p-2">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-12 ps-3">
                    <h6 class="text-dark"><b>{{ 'label.Need Help' | i18next }}?</b></h6>
                    <span class="content py-2 me-3"><i class="bi bi-info-circle-fill" style="color:#5dab00;"></i>{{ 'label.Product Help' | i18next }} </span>
                    <span class="content py-2"  title="{{ 'label.This link shall' | i18next }}"><i class="bi bi-youtube me-1" style="vertical-align: bottom;color: red;"></i><a  href="https://www.youtube.com/channel/UCcn-R6DrQwhyKJBZosmkCnw" target="_blank">{{ 'label.Help Videos' | i18next }}</a></span>

                  </div>
                </div>
              </div>
            </li>
            <li class="bg-white px-3">
              <hr class="dropdown-divider m-0 h-auto">
            </li>
            <li class="bg-white p-2">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-12 ps-3 pe-0">
                    <h6 class="text-dark"><b>{{ 'label.Contact Us' | i18next }}</b></h6>
                    <ul class="normal-list">
                      <li class="py-2"><img src="assets/images/feedback.png" class="mailIcon"> <a title="{{ 'label.This link shall' | i18next }}"  href="https://forms.irisirp.com/IRISBusiness/form/ProductFeedback1/formperma/EBW8CklkhLZg9b1vPpJxbK0q4KfZY-5wiHKfVMvcIvw"  target="_blank">{{ 'label.Feedback Suggestions' | i18next }}</a></li>
                      <li class="py-2"><i class="bi bi-envelope-fill" style="color:#007ad9;"></i>
                        {{ 'label.Write to us at' | i18next }} <a class="d-inline-block mail-anchor p-0"
                          href="mailto: support&#64;irisirp.com"  style="color:#007ad9;">support&#64;irisirp.com</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>
            <li class="bg-white">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-6 px-0">
                    <a class="btn btn-dark w-100 rounded-0"
                      style="border-bottom-left-radius: 5px !important;color: white;" href="https://dev.portal.irisirp.com/irp/gstin/dashboard/plans">{{ 'label.My Subscription' | i18next }}</a>
                  </div>
                  <div class="col-6 px-0">
                    <button class="btn btn-light w-100 rounded-0"
                      style="border-bottom-right-radius: 5px !important;" (click)="logout()">{{ 'label.Sign Out' | i18next }}</button>
                  </div>
                </div>
              </div>
            </li>
          </ul>

        <div class="collapse navbar-collapse mt-9 navbar-dark bg-dark prl15" id="navbarNavDarkDropdown">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <!-- <li class="nav-item">
                    <a class="nav-link active" aria-current="page" href="#">
                      Generate</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">Link</a>
                </li> -->
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle active" href="#" id="navbarDropdown" role="button"
                        data-bs-toggle="dropdown" aria-expanded="false">{{ 'label.Generate' | i18next }}
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><a class="dropdown-item"(click)="redirectToPage('einvoice',true)">{{ 'label.E-Invoice' | i18next }}</a></li>
                        <li><a class="dropdown-item" (click)="redirectToPage('bulk-generate',true)">{{ 'label.Bulk Operations' | i18next }}</a></li>
                    </ul>
                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle " href="#" id="navbarDropdown" role="button"
                      data-bs-toggle="dropdown" aria-expanded="false">{{ 'label.Manage' | i18next }}
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                      <li><a class="dropdown-item"(click)="redirectToPage('view-invoice')">{{ 'label.View Invoices' | i18next }} </a></li>
                      <li><a class="dropdown-item" (click)="redirectToPage('search-invoice')">{{ 'label.Search' | i18next }}</a></li>
                  </ul>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle " href="#" id="navbarDropdown" role="button"
                    data-bs-toggle="dropdown" aria-expanded="false">{{ 'label.Analytics' | i18next }}
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li><a class="dropdown-item" (click)="redirectToPage('sales-overview')">{{ 'label.Sales Overview' | i18next }}</a></li>
                    <li><a class="dropdown-item" (click)="redirectToPage('error-discrepancies')">{{ 'label.Errors and discrepancies' | i18next }} </a></li>
                </ul>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle " href="#" id="navbarDropdown" role="button"
                  data-bs-toggle="dropdown" aria-expanded="false">{{ 'label.My Purchase' | i18next }}
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li><a class="dropdown-item" (click)="redirectToPage('viewShared-invoice')">{{ 'label.View Shared' | i18next }}</a></li>
                  <li><a class="dropdown-item" href="#">{{ 'label.Get GSTR 2B' | i18next }}  <span class="badge bg-warning custom-badge">{{ 'label.Upcoming' | i18next }}  </span></a></li>
              </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle " href="#" id="navbarDropdown" role="button"
                data-bs-toggle="dropdown" aria-expanded="false">{{ 'label.Tools' | i18next }}
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><a class="dropdown-item"(click)="redirectToPage('verify-invoice')">{{ 'label.Verify' | i18next }} </a></li>
                <li><a class="dropdown-item"(click)="redirectToPage('gstin-status')">{{ 'label.GSTIN Status' | i18next }}</a></li>
                <li><a class="dropdown-item"(click)="redirectToPage('einvoice-status')">{{ 'label.E-invoice Enablement' | i18next }}</a></li>
                <li><a class="dropdown-item"  (click)="redirectToPage('hsn-finder')">{{ 'label.HSN/SAC Finder' | i18next }}</a></li>
                <li><a class="dropdown-item"  (click)="redirectToPage('masters-finder')">{{ 'label.Master Codes' | i18next }}</a></li>
                <li><a class="dropdown-item"  (click)="redirectToPage('excel-finder')">{{ 'label.Excel Tool' | i18next }}</a></li>
            </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle " href="#" id="navbarDropdown" role="button"
              data-bs-toggle="dropdown" aria-expanded="false">{{ 'label.Settings' | i18next }}
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a class="dropdown-item"  (click)="redirectToPage('my-gstin')">{{ 'label.My GSTINs' | i18next }}</a></li>
              <li><a class="dropdown-item" (click)="redirectToPage('manage-api-access')">{{ 'label.Manage API Access' | i18next }}</a></li>
              <li><a class="dropdown-item" (click)="redirectToPage('manage-users')">{{ 'label.Manage Users' | i18next }}</a></li>
              <!-- <li><a class="dropdown-item" href="#">Supplier</a></li> -->
              <li><a class="dropdown-item" (click)="redirectToPage('customer')">{{ 'label.Customer' | i18next }}</a></li>
              <!-- <li><a class="dropdown-item">Transporter</a></li> -->
              <li><a class="dropdown-item" (click)="redirectToPage('transport')">{{ 'label.Transporter' | i18next }}</a></li>
              <li><a class="dropdown-item" (click)="redirectToPage('product-service')">{{ 'label.Product and Service' | i18next }}</a></li>
          </ul>
      </li>


                <!-- <li class="nav-item">
                    <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
                </li> -->
            </ul>
        </div>
    </div>
</nav>
  <!-- responsive menu end -->

  <div class="">
    <!-- Marquee moved to header-->
    <div class="col-12 font-size-14">
    <div *ngFor="let news of news">
      <marquee class="marquee-body" *ngIf="marquee && news.newsBody">
        {{news && news.newsBody ? news.newsBody :'N/A'}} <a style="color: #007ad9; font-size: 14px;font-weight: 500;"
        *ngIf="news.moreInfoExternalLink" [href]="news.moreInfoExternalLink" target="_blank"
       class="font-size-14">{{ 'label.from here' | i18next }}</a>
      </marquee>
    </div></div>
    <div class="custom_container mt-3 px-3">
      <div [class.marquee-space]="!marquee " [hidden]="!showSection">
        <app-common-gstin-dropdown></app-common-gstin-dropdown>

      </div>

    </div>

    <router-outlet></router-outlet>
  </div>

  <!--footer-->
  <div class="menuToggle" id="upArrow">
    <a (click)="showFooter()"><i class="bi bi-arrow-bar-up"></i></a>
  </div>

  <footer class="main-footer" id="footerElem">
    <div>
      <!-- <a class="footer-links" href="#"> Help</a>
      <a class="footer-links" href="#"> Feedback</a>
      <a class="footer-links" href="#"> Contact us</a> -->

      <h6 class="mt-2  ">© {{ 'label.from here' | i18next }}. | <a routerLink="/privacy">{{ 'label.Privacy Policy' | i18next }}</a> | <a routerLink="/terms-conditions">{{ 'label.Terms & Conditions' | i18next }}</a></h6>
    </div>
  </footer>

  <!--footer-END-->
</div>

<!-- <script>
  $(document).ready(function () {
    $(".dropdown-toggle").click(function () {
      $(".dropdown-menu").toggleClass("show");
    });
  });
</script> -->
