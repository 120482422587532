<app-auth-header></app-auth-header>
<div class="container">
    <div class="row">
        <div class="col-12">
            <div class="row">
                <div class="col-6">
                    <div class="column_attr mfn-inline-editor clearfix mobile_align_center">
                        <h2>Powerful Web Portal</h2>
                        <p></p>
                        <p></p>

                        <h5 class="h5DetailContent">Best suited for managing all
                            activities from
                            generation to sharing of invoices to preparing GST Returns </h5>

                        <p>Web form to quickly get started for single and/or bulk e-invoice generation for
                            multiple GSTINs from single Log In. </p>
                        <p>View detailed invoice, Print, Bulk Print, Share
                            Mail, Download JSON/CSV, Cancel, Masters, manage settings, Analytics and all options
                            are accessible from web interface</p>
                        <p>Start your e-invoicing journey with simple Email id or Mobile from here!</p>
                        <button type="submit" routerLink="/signup" class="btn btn_primary_theme font-size-16">Sign
                            Up</button>
                    </div>

                </div>

                <div class="col-6">
                    <div class="mcb-wrap-inner mcb-wrap-inner-b40b07d70">
                        <div class="column mcb-column mcb-item-9f38091bf one tablet-one mobile-one column_image">
                            <div class="mcb-column-inner mcb-column-inner-9f38091bf mcb-item-image-inner">
                                <div
                                    class="image_frame element_classes image_item no_link scale-with-grid alignnone no_border mfn-mask-shape ">
                                    <div class="image_wrapper"><img class="scale-with-grid imgClass"
                                            src="../../assets/images/E-Invoicing-for-taxpayers-new1.png" alt="Home"
                                            title=""></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-12">
            <div class="row">
                <div class="col-6">
                    <div class="mcb-wrap-inner mcb-wrap-inner-cf5691f8e">
                        <div class="column mcb-column mcb-item-02789b8bf one tablet-one mobile-one column_image">
                            <div class="mcb-column-inner mcb-column-inner-02789b8bf mcb-item-image-inner">
                                <div
                                    class="image_frame element_classes image_item no_link scale-with-grid alignnone no_border mfn-mask-shape ">
                                    <div class="image_wrapper"><img class="scale-with-grid imgClass"
                                            src="../../assets/images/API-integrator-home-new.png" alt="Home" title="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="col-6" id="api-content" #targetDiv>
                    <div class="column_attr mfn-inline-editor clearfix mobile_align_center">
                        <h2>API </h2>
                        <p></p>
                        <p></p>
                        <h5 class="h5DetailContent">Best suited for ERPs, Billing
                            Software, ASP and GSP solution
                            providers </h5>
                        <p>Integrate with core, enhanced and value added APIs to provide seamless experience and
                            innovative solutions to end user. </p>
                        <p>Be it for sending invoices for e-invoice generation or retrieving IRN at later date, you
                            have flexibility to design the entire user experience in your application </p>
                        <p>For a successful and seamless e-invoice generation via APIs, your systems need to follow
                            authentication protocols, gracefully handle errors, and provide options to taxpayers to
                            retrieve information as and when demanded. </p>
                        <button type="submit" routerLink="/signup" class="btn btn_primary_theme font-size-16">Sign
                            Up</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-12">
            <div class="row">
                <div class="col-6" id="excel">
                    <div class="column_attr mfn-inline-editor clearfix mobile_align_center">
                        <h2>Excel Utility</h2>
                        <p></p>
                        <p></p>
                        <b>
                            <h5 class="h5DetailContent">The bulk generation facility by
                                the IRIS IRP for multiple
                                invoices generation at once. </h5>
                        </b>
                        <p>It is an offline excel-based tool to generate e-invoices by exporting invoice data from the
                            billing system/accounting software or ERP of the taxpayer.
                        </p>
                        <p>IRIS IRP would then accept a bulk
                            request for uploading the JSON file of these invoices.</p>
                        <p> After authentication, the IRP will
                            generate a unique Invoice reference number (IRN) and Quick Response (QR) code for each
                            e-invoice and digitally sign the e-invoice. And in case of error, Response will be shared
                        </p>
                        <button type="submit" class="btn btn_primary_theme font-size-16">Download</button>
                    </div>

                </div>

                <div class="col-6">
                    <div class="mcb-wrap-inner mcb-wrap-inner-b40b07d70">
                        <div class="column mcb-column mcb-item-9f38091bf one tablet-one mobile-one column_image">
                            <div class="mcb-column-inner mcb-column-inner-9f38091bf mcb-item-image-inner">
                                <div
                                    class="image_frame element_classes image_item no_link scale-with-grid alignnone no_border mfn-mask-shape ">
                                    <div class="image_wrapper"><img class="scale-with-grid imgClass"
                                            src="../../assets/images/ExcelImage.png" alt="Home" title=""></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-12">
            <div class="row">
                <div class="col-6" id="mobile">
                    <div class="mcb-wrap-inner mcb-wrap-inner-cf5691f8e">
                        <div class="column mcb-column mcb-item-02789b8bf one tablet-one mobile-one column_image">
                            <div class="mcb-column-inner mcb-column-inner-02789b8bf mcb-item-image-inner">
                                <div
                                    class="image_frame element_classes image_item no_link scale-with-grid alignnone no_border mfn-mask-shape ">
                                    <div class="image_wrapper">
                                        <img class="scale-with-grid imgClass" src="../../assets/images/mobile.png"
                                            alt="Home" title="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="col-6">
                    <div class="column_attr mfn-inline-editor clearfix mobile_align_center">
                        <h2>Mobile </h2>
                        <p></p>
                        <p></p>
                        <h5 class="h5DetailContent">The ultimate app for all your
                            E-invoicing and business needs
                        </h5>
                        <p>Even before you get an IRN, you need to create an invoice for your transaction. IRIS IRP+
                            lets you define all your inventories, customers, tax rates and more and create invoices
                            quickly. </p>
                        <p>Grow your business swiftly. Share your inventory catelogue and let buyer discover you and get
                            instance purchase requisitiions </p>
                        <p>What next after e-invoicing? Getting the money in your account. Receivables management made
                            easy with IRIS IRP + </p>

                        <p>Be credit ready to enjoy strong cashflow. With rich business data scoring on the platform,
                            businesses get easy access to relevant credit products from FIs.</p>
                        <button type="submit" class="btn btn_primary_theme font-size-16">Download</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="container-fluid footer">
  <div class="row footerDiv">
    <div class="col-md-9">
      <h3 class="display-4 text-white">{{ 'new.IRIS IRP is govt authorised E-Invoice Registration Portal' | i18next }}</h3>
    </div>
    <div class="col-md-3">
      <a href="https://einvoice6.gst.gov.in/content/contact-us/" target="blank"
        class="btn btn-primary rounded mb-0 text-nowrap">{{ 'label.Contact Us' | i18next }}</a>
    </div>
    <hr class="hrdiv">
    <div class="col-md-3">
      <h4 class="widget-title text-white mb-3">{{ 'new.Important links' | i18next }}</h4>
      <ul class="text-white" style="list-style: none; padding-left: 0px;">
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://www.gstn.org.in/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.GSTN' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://cbic-gst.gov.in/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.CBIC' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisirp.com/notifications/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.GST notifications' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://www.india.gov.in/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.National Portal' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://einvoice6.gst.gov.in/content/wp-content/uploads/2023/04/Letter-ON-NEW-IRPS-20April23.pdf"
            target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.GSTN Authorization of IRIS IRP' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://sandbox.einvoice6.gst.gov.in/login" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.IRIS IRP Sandbox' | i18next }}</a>
        </li>
      </ul>
    </div>
    <div class="col-md-3">
      <h4 class="widget-title text-white mb-3">{{ 'new.IRIS IRP Policies' | i18next }}</h4>
      <ul class="text-white" style="list-style: none; padding-left: 0px;">
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://einvoice6.gst.gov.in/content/terms-and-conditions/">{{ 'new.Terms and Condition' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://einvoice6.gst.gov.in/content/privacy/">{{ 'new.Privacy Policy' | i18next }}</a>
        </li>
      
      </ul>

    </div>
    <div class="col-md-3">
      <h4 class="widget-title text-white mb-3">{{ 'new.About IRIS' | i18next }}</h4>
      <ul class="text-white" style="list-style: none; padding-left: 0px;">
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisirp.com/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.IRIS IRP' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisgst.com/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.IRIS GST' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisbusiness.com/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.IRIS Business' | i18next }}</a>
        </li>

      </ul>
      <h4 class="widget-title text-white mb-3">{{ 'new.Need Help' | i18next }}</h4>
      <ul class="text-white" style="list-style: none; padding-left: 0px;">
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="mailto:support@irisirp.com">{{ 'new.Write to: support@irisirp.com' | i18next }}</a>
        </li>
      </ul>
    </div>
    <div class="col-md-3">
      <h4 class="widget-title text-white mb-3">{{ 'new.Other Products' | i18next }}</h4>
      <ul class="text-white" style="list-style: none; padding-left: 0px;">
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;"> <a
            href="https://irisgst.com/irissapphire/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.IRIS GST Software' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisgst.com/iris-onyx/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.IRIS E-Invoicing' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisgst.com/gst-litigation-management-system/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.IRIS Litigation Management' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisgst.com/iristopaz/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.IRIS E-Way Bill' | i18next }}  </a>
        </li>

        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisgst.com/iris-lookup/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.IRIS LookUp' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisgst.com/irisperidot/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.IRIS Peridot' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://easywaybill.in/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.EWB app for transporters' | i18next }}</a>
        </li>
      </ul>
    </div>
    <hr class="hrdiv mb10">
    <div class="row">
      <div class="col-md-3">
        <div class="social social-white social-center">
          <span><a href="https://twitter.com/IRIS_IRP" target="blank"><i
                style="color:#fff; font-size: 18px;     padding-right: 16px; font-weight: 400;padding-bottom: 15px;"
                class="bi bi-twitter"></i></a></span>
          <span><a href="https://www.linkedin.com/showcase/82795027/admin/" target="blank"><i
                style="color:#fff; font-size: 18px;     padding-right: 16px; font-weight: 400;padding-bottom: 15px;"
                class="bi bi-linkedin"></i></a></span>
          <span><a href="https://www.youtube.com/@irisirp" target="blank"><i
                style="color:#fff; font-size: 18px;     padding-right: 16px; font-weight: 400;padding-bottom: 15px;"
                class="bi bi-youtube"></i></a></span>
        </div>
      </div>
      <div class="col-md-6">
        <p class="text-center text-white">© {{ 'new.2023 IRIS Business Services Ltd' | i18next }}.</p>
      </div>
      <div class="col-md-3 social-center">
        <span><img class="responsive" style="height: 40px;width: 100px;"
            src="../../../../assets/images/brands/sslcertificate.png" alt=""></span>
        <span><img class="responsive" style="height: 40px;width: 100px;" src="../../../../assets/images/brands/iso.png"
            alt=""></span>

      </div>
    </div>
  </div>
</div>
