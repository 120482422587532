import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedRoutingModule } from './shared.routing.module';
import { ApiIntegrationHeaderComponent } from './api-integration-header/api-integration-header.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { GreivanceComponent } from './greivance/greivance.component';
import { GreivanceStatusComponent } from './greivance-status/greivance-status.component';
import { AuthHeaderComponent } from '../auth/components/auth-header/auth-header.component';
import { TestComponent } from './test/test.component';
import { CommonGstinDropdownComponent } from '../taxPayer/taxpayer-dashboard/common-gstin-dropdown/common-gstin-dropdown.component';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { EditorModule } from 'primeng/editor';

import { TableModule } from 'primeng/table';
import { NgxBarcodeModule } from 'ngx-barcode';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { GenericEinvoiceEnablementStatusComponent } from './common-utility/generic-einvoice-enablement-status/generic-einvoice-enablement-status.component';
import { GenericGstinStatusComponent } from './common-utility/generic-gstin-status/generic-gstin-status.component';
import { GenericVerifyEinvoiceComponent } from './common-utility/generic-verify-einvoice/generic-verify-einvoice.component';
import { GenericVerifyQrCodeComponent } from './common-utility/generic-verify-qr-code/generic-verify-qr-code.component';
import { GenericHsnSearchComponent } from './common-utility/generic-hsn-search/generic-hsn-search.component';
import { GenericMastersCodeComponent } from './common-utility/generic-masters-code/generic-masters-code.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { I18NextModule } from 'angular-i18next';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule,
    SharedRoutingModule,
    NgSelectModule,
    TableModule,
    NgxQRCodeModule,
    NgxBarcodeModule,
    EditorModule,
    PopoverModule.forRoot(),
    NgxCaptchaModule,
    I18NextModule.forRoot()
  ],
  declarations: [
    ApiIntegrationHeaderComponent,
    GreivanceComponent,
    GreivanceStatusComponent,
    TestComponent,
    CommonGstinDropdownComponent,
    GenericEinvoiceEnablementStatusComponent,
    GenericGstinStatusComponent,
    GenericVerifyEinvoiceComponent,
    GenericVerifyQrCodeComponent,
    GenericHsnSearchComponent,
    GenericMastersCodeComponent
  ],
  exports: [CommonGstinDropdownComponent, TableModule, NgxBarcodeModule, EditorModule, PopoverModule,
    GenericEinvoiceEnablementStatusComponent, GenericGstinStatusComponent, GenericVerifyEinvoiceComponent,
    GenericVerifyQrCodeComponent, GenericHsnSearchComponent,GenericMastersCodeComponent],
  providers: [],
})
export class SharedModule { }
