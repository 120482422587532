<div class="row mb-1 align-items-center mt1" *ngIf="isGSTInAvailable">
  <div class="col-md-3" *ngIf="visibleDropDown == 'true'">
    <ng-select [items]="gstinList" bindLabel="taxpayerName" class="custom-img-dropdown" bindValue="gstin"
      [clearable]="false" placeholder="Select GSTIN" [(ngModel)]="selectedGstin" (change)="updateGstin()">
      <ng-template ng-option-tmp let-item="item">
        <div title="{{ item.taxpayerName }}">{{ item.taxpayerName }}</div>
      </ng-template>
    </ng-select>
  </div>
  <div class="col-md-9" *ngIf="hideForSandbox">
    <p class="blink sandboxInfo">{{ 'new.Sandbox for Taxpayer' | i18next }}</p>
  </div>
</div>
