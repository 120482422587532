<!-- <nav class=" navbar-expand-lg">
  <div class="custom_container">
    <img src="../../../../assets/images/iris-reverse-white-logo.png" class="logo" />
  </div>
</nav> -->

<app-auth-header  *ngIf="isNavbarLoginVisible"></app-auth-header>
<app-auth-login  *ngIf="!isNavbarLoginVisible"></app-auth-login>
<div class="">
  <!-- <app-auth-login></app-auth-login> -->
  <!-- Marquee moved to header-->
  <!-- <div class="col-12 font-size-12 top-space-55">
    <marquee class="marquee-body" *ngIf="marquee && marquee.newsBody">
      {{marquee && marquee.newsBody ? marquee.newsBody :'N/A'}}
    </marquee>
  </div> -->
  <!-- <div class="custom_container mt-3 px-3">
    <div [class.marquee-space]="!marquee ">
       <app-common-gstin-dropdown></app-common-gstin-dropdown>

    </div>

  </div> -->

  <router-outlet></router-outlet>
</div>

<div class="container-fluid" id="increaseFont" style="background-color: #faf9fa;"
  *ngIf="!formList.showResetPasswordForm">
  <!-- <div class="elementor-container elementor-column-gap-default"><div class="elementor-row"><div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-1bcab72" data-id="1bcab72" data-element_type="column"><div class="elementor-column-wrap elementor-element-populated"><div class="elementor-widget-wrap"><div class="elementor-element elementor-element-d00eb21 elementor-widget elementor-widget-text-editor" data-id="d00eb21" data-element_type="widget" data-widget_type="text-editor.default"><div class="elementor-widget-container"><div class="elementor-text-editor elementor-clearfix">
    <marquee  id="advisoryModelOpen" (click)="advisoryModel.show()" behavior="alternate" class="popmake-8633 pum-trigger" style="    text-decoration: underline;cursor: pointer;padding: 10px 0;color: #319645;font-size: 12px;"><strong>Important Advisory on Verifying Signed Invoices / QR Code for E-Invoices Generated Between 21st Nov 2024 to 26th Nov 2024 from IRIS IRP</strong></marquee></div></div></div></div></div></div></div></div> -->
  <!-- <button type="button" class="btn btn_gradient font-size-16" id="advisoryModelOpen" (click)="advisoryModel.show()">
    My Plans
  </button> -->
  <div class="row align-items-center banner-sidebar customVh">
    <!-- <div class="col-lg-1"></div> -->
    <div class="col-md-12" *ngIf="hideForSandbox">
        <div class="link">
          <!-- <a href="https://einvoice6.gst.gov.in/content/"
            class="text-decoration-none custom-text anchorLink" target="_new">
            {{ 'new.Home' | i18next }}
          </a> &bull;
          <a routerLink="/signup" class="text-decoration-none custom-text anchorLink">
            {{ 'label.Sign Up' | i18next }}
          </a>
          &bull; &nbsp; -->
          Check out <a href="https://einvoice6.gst.gov.in/login" target="_blank">IRIS IRP Live Portal</a>
          &bull; &nbsp;
          <select (change)="changeLanguage($event)" class="language-switch" [value]="i18nextSvc.language">
            <option value="en">English</option>
            <option value="marathi">Marathi</option>
            <!-- <option value="tamil">Tamil</option> -->
            <option value="telugu">Telugu</option>
          </select>
        </div>
    </div>
    <div class="col-lg-5  text-center diplayFooter">
      <img src="assets/images/Infographic.png" class="img-fluid" />
      <div class="row">
        <div class="col-12 px-lg-5 text-lg-start">
          <h6>{{ 'label.IRIS IRP for an easy' | i18next }} </h6>
          <ul>
            <li>{{ 'label.Manage e-invoicing' | i18next }}</li>
            <li>{{ 'label.Bulk IRN generation' | i18next }}</li>
            <li>{{ 'label.Multiple modes' | i18next }}</li>
            <li>{{ 'label.Simple and intuitive' | i18next }}</li>
          </ul>
          <h6>{{ 'label.Sign-up and explore' | i18next }}</h6>
        </div>
      </div>
    </div>
    <!--login Form-->
    <div class="col-lg-7">
      <div class="signup_form">
        <div class="card border-0 shadow py-4 px-3 mx-auto" style="max-width: 33.5rem;">
          <div class="card-body" *ngIf="!formList.showForgotPassword && formList.showLogin && !mfaEnabled">
            <form [formGroup]="loginForm">
              <h2 class="card-title mb-2" style="font-weight: 700 !important; color: #252525;">{{ 'label.Log In' | i18next }}</h2>
              <div class="row">
                <div class="col-12 mb-5">
                  <span style="font-weight:600">{{ 'label.Welcome to IRIS' | i18next }}</span> <span style="font-weight:600;padding-left:5px;" *ngIf="hideForSandbox">{{ 'new.Sandbox for Taxpayer' | i18next }}</span>
                </div>
                <ngx-invisible-recaptcha #captchaElem [siteKey]="siteKey" [badge]="'bottomleft'" (reset)="handleReset()"
                  (success)="handleSuccess($event)" (expire)="handleExpire()" (error)="handleError()"
                  [ngModel]="recaptcha" [ngModelOptions]="{ standalone: true }" (ready)="handleReady()"
                  (load)="handleLoad()">
                </ngx-invisible-recaptcha>
                <div class="col-12 mb-3">
                  <label for="exampleFormControlInput2" class="form-label"><b>{{ 'label.Email id' | i18next }}</b></label>
                  <input type="text" class="form-control" id="exampleFormControlInput2" formControlName="userId"
                    placeholder="{{ 'formSteps.Email Id/ Mobile' | i18next }}" required>
                    <!-- <div class="error" *ngIf="loginForm.controls['userId'].errors">
                    {{ 'validations.valid email id' | i18next }}</div> -->
                  <div class="error"
                    *ngIf="loginForm.controls['userId'].hasError('required') && (loginForm.controls['userId'].dirty || loginForm.controls['userId'].touched)">
                    {{ 'validations.valid email id' | i18next }}</div>
                  <div class="error"
                    *ngIf="loginForm.controls['userId'].hasError('pattern') && (loginForm.controls['userId'].dirty || loginForm.controls['userId'].touched)">
                    {{ 'validations.valid email id' | i18next }}</div>
                </div>
                <div class="col-12 mb-3">
                  <label for="exampleFormControlInput1" class="form-label"><b>{{ 'label.Password' | i18next }}</b></label>
                  <div class="input-icons">
                    <i class="bi bi-eye icon" *ngIf="show" (click)="onClick()"></i>
                    <i class="bi bi-eye-slash icon" *ngIf="!show" (click)="onClick()"></i>
                    <input type="password"  (input)="checkCaptcha()" class="form-control" id="exampleFormControlInput1" [type]="passwords"
                      placeholder="{{ 'label.Password' | i18next }}" formControlName="password" required>
                  </div>
                  <div *ngIf="!password?.valid && (password?.dirty ||password?.touched)">
                    <div class="error" [hidden]="!password?.errors?.required">
                      {{ 'validations.Password is required' | i18next }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <label class="custom_checkbox form-check-label">{{ 'label.Remember me' | i18next }}
                    <input type="checkbox" name="rememberMe" formControlName="rememberMe" />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="col-md-6 mb-3 text-end">
                  <span class="text-primary"><a href="javascript:;" (click)="forgotPasswordFormShow()"
                      class="text-decoration-none custom-text anchorLink">{{ 'label.Forgot Password' | i18next }}</a></span>
                </div>
                <div class="mb-3 row" *ngIf="captchaVisible1">
                  <!-- <label for="gstin" class="col-sm-3 col-form-label"></label> -->
                  <label for="gstin" class="col-sm-5 text-center font-size-30 captchaTxt">{{captchString}}</label>
                  <!-- <label *ngIf="!setCaptch">{{this.captchString}}</label> -->
                  <div class="col-sm-1">
                    <button title="{{ 'label.Refresh Data' | i18next }}" class="btn btn-sm custom-icon-button p12"
                      (click)="generateRandomString(5)">
                      <!-- <i class="bi bi-arrow-clockwise "></i> -->
                      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M17.0625 3.3457C15.25 1.5332 12.7625 0.408203 9.99995 0.408203C4.47495 0.408203 0.0124512 4.8832 0.0124512 10.4082C0.0124512 15.9332 4.47495 20.4082 9.99995 20.4082C14.6625 20.4082 18.5499 17.2207 19.6625 12.9082H17.0625C16.0375 15.8207 13.2625 17.9082 9.99995 17.9082C5.86245 17.9082 2.49995 14.5457 2.49995 10.4082C2.49995 6.2707 5.86245 2.9082 9.99995 2.9082C12.075 2.9082 13.9249 3.7707 15.275 5.1332L11.25 9.1582H20V0.408203L17.0625 3.3457Z"
                          fill="#252525" />
                      </svg>

                    </button>
                  </div>
                  <div class="col-sm-5" *ngIf="captchaVisible1">
                    <input type="text" class="form-control ml15" id="gstin" name="gstin"
                    formControlName="captcha" placeholder="{{ 'common.Enter Captcha' | i18next }}">
                  </div>



                </div>
                <div class="col-12 my-3 text-center">
                  <button class="btn btn-dark px-5 py-2" (click)="login('login')">{{ 'label.Login' | i18next }}</button>
                </div>
                <div class="col-12 text-center">
                  <p>{{ 'label.Not a member' | i18next }} <span class="text-primary"><a routerLink="/signup"
                        class="text-decoration-none custom-text anchorLink">{{ 'label.Sign up now' | i18next }}</a></span></p>
                </div>
              </div>
            </form>
          </div>
          <div class="card-body" *ngIf="mfaEnabled && !troubleLoggingIn">
            <form>
              <h2 class="card-title mb-2" style="font-weight: 700 !important; color: #252525;">{{ 'label.OTP Verification' | i18next }}</h2>
              <div class="row">
                <div class="col-12 mb-5">
                  <span style="font-weight:600">{{ 'label.Please Enter OTP' | i18next }}</span> <span style="font-weight:600;padding-left:5px;" *ngIf="hideForSandbox">{{ 'new.Sandbox for Taxpayer' | i18next }}</span>
                </div>
                <div class="col-12 mb-5">
                  <div class="position-relative">
                    <label class="form-label"><b>{{ 'label.OTP' | i18next }}</b></label>
                    <input
                      [type]="otpInputType"
                      class="form-control"
                      placeholder="{{ 'label.OTP' | i18next }}"
                      [value]="otp"
                      (input)="otpChanged($event)"
                      aria-label="Enter OTP"
                      aria-describedby="basic-addon1"
                    />
                    <i class="bi bi-eye icon password-visibility" title="Hide OTP" *ngIf="otpInputType === 'text'" (click)="toggleOTPInputType()"></i>
                    <i class="bi bi-eye-slash icon password-visibility" title="Show OTP" *ngIf="otpInputType === 'password'" (click)="toggleOTPInputType()"></i>
                  </div>
                </div>
              </div>
              <div class="col-12 my-3 text-center">
                <button class="btn btn-dark px-5 py-2" [disabled]="!(otp && otp.length == 6)" (click)="verifyOTP()">{{ 'label.Login' | i18next }}</button>
              </div>
              <div class="col-12 mb-3 text-center">
                <button class="link-button blue-link-button" (click)="loginTrouble()">{{ 'label.Having Trouble logging in?' | i18next }}</button>
              </div>
            </form>
          </div>
          <div class="card-body" *ngIf="troubleLoggingIn">
            <form>
              <h2 class="card-title mb-2" style="font-weight: 700 !important; color: #252525;">{{ 'label.Trouble Logging' | i18next }}</h2>
              <div class="row">
                <div class="col-12 mb-5">
                  <span style="font-weight:600">{{ 'label.Click on GET OTP' | i18next }}</span> <span style="font-weight:600;padding-left:5px;" *ngIf="hideForSandbox">{{ 'new.Sandbox for Taxpayer' | i18next }}</span>
                </div>
                <div class="col-12 mb-5">
                  <label class="form-label"><b>{{ 'label.Email ID Mobile' | i18next }}</b></label>
                  <input type="text" class="form-control" disabled [(ngModel)]="troubleEmail" [ngModelOptions]="{standalone: true}" placeholder="{{ 'label.Email ID Mobile' | i18next }}">
                  <button class="link-button mt-2" (click)="getOTP()">{{ 'label.Get OTP' | i18next }}</button>
                </div>
                <div class="col-12 mb-5">
                  <div class="position-relative">
                    <label class="form-label"><b>{{ 'label.OTP' | i18next }}</b></label>
                    <input
                      [type]="otpInputType"
                      class="form-control"
                      placeholder="{{ 'label.OTP' | i18next }}"
                      [value]="troubleOTP"
                      (input)="troubleOTPChanged($event)"
                      aria-label="Enter OTP"
                      aria-describedby="basic-addon1"
                    />
                    <i class="bi bi-eye icon password-visibility" title="Hide OTP" *ngIf="otpInputType === 'text'" (click)="toggleOTPInputType()"></i>
                    <i class="bi bi-eye-slash icon password-visibility" title="Show OTP" *ngIf="otpInputType === 'password'" (click)="toggleOTPInputType()"></i>
                  </div>
                </div>

              </div>
              <div class="col-12 my-3 text-center">
                <button class="btn btn-dark px-5 py-2" [disabled]="!(troubleOTP && troubleOTP.length == 6)" (click)="verifyTroubleOTP()">{{ 'label.Verify' | i18next }}</button>
              </div>
            </form>
          </div>
          <div class="card-body" *ngIf="formList.showForgotPassword">

            <form [formGroup]="forgotPasswordForm">
              <h2 class="card-title mb-2" style="font-weight: 700 !important; color: #252525;">{{ 'label.Forgot Password' | i18next }}</h2>
              <div class="row">
                <div class="col-12 mb-5">
                  <b>{{ 'label.Share your Email' | i18next }}</b>
                </div>
                <div class="col-12 mb-3">
                  <label for="email" class="form-label"><b>{{ 'formSteps.Email Id/ Mobile' | i18next }}</b></label>
                  <input placeholder="{{ 'formSteps.Email Id/ Mobile' | i18next }}" type="email"  (input)="checkCaptchaForgotPassword()" name="email" class="form-control"
                    formControlName="userId" />
                    <!-- <div class="error" *ngIf="forgotPasswordForm.controls['userId'].errors">
                    {{ 'validations.valid email id' | i18next }}</div> -->
                  <div class="error"
                    *ngIf="forgotPasswordForm.controls['userId'].hasError('required') && (forgotPasswordForm.controls['userId'].dirty || forgotPasswordForm.controls['userId'].touched)">
                    {{ 'validations.valid email id' | i18next }}</div>
                  <div class="error"
                    *ngIf="forgotPasswordForm.controls['userId'].hasError('pattern') && (forgotPasswordForm.controls['userId'].dirty || forgotPasswordForm.controls['userId'].touched)">
                    {{ 'validations.valid email id' | i18next }}</div>
                </div>
                <div class="mb-3 row" *ngIf="captchaVisibleForgotPas">
                  <!-- <label for="gstin" class="col-sm-3 col-form-label"></label> -->
                  <label for="gstin" class="col-sm-5 text-center font-size-30 captchaTxt">{{captchStringForgetPass}}</label>
                  <!-- <label *ngIf="!setCaptch">{{this.captchString}}</label> -->
                  <div class="col-sm-1">
                    <button title="{{ 'label.Refresh Data' | i18next }}" class="btn btn-sm custom-icon-button p12"
                      (click)="generateRandomStringForgetPass(5)">
                      <!-- <i class="bi bi-arrow-clockwise "></i> -->
                      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M17.0625 3.3457C15.25 1.5332 12.7625 0.408203 9.99995 0.408203C4.47495 0.408203 0.0124512 4.8832 0.0124512 10.4082C0.0124512 15.9332 4.47495 20.4082 9.99995 20.4082C14.6625 20.4082 18.5499 17.2207 19.6625 12.9082H17.0625C16.0375 15.8207 13.2625 17.9082 9.99995 17.9082C5.86245 17.9082 2.49995 14.5457 2.49995 10.4082C2.49995 6.2707 5.86245 2.9082 9.99995 2.9082C12.075 2.9082 13.9249 3.7707 15.275 5.1332L11.25 9.1582H20V0.408203L17.0625 3.3457Z"
                          fill="#252525" />
                      </svg>

                    </button>
                  </div>
                  <div class="col-sm-5" *ngIf="captchaVisibleForgotPas">
                    <input type="text" class="form-control ml15" id="gstin" name="gstin"
                    formControlName="captcha" placeholder="{{ 'common.Enter Captcha' | i18next }}">
                  </div>



                </div>
                <div class="col-12 my-3 text-center">
                  <button type="submit" class="btn btn-dark px-5 py-2" [disabled]="!forgotPasswordForm.controls['userId'].valid" (click)="generateForgotPasswordOTP()" >{{ 'label.Generate OTP' | i18next }}</button>
                </div>
              </div>
            </form>
          </div>
          <div class="card-body" *ngIf="formList.showOTPVerificatonForm">

            <form [formGroup]="OTPForm" (ngSubmit)="onSubmit()">
              <h2 class="card-title mb-2" style="font-weight: 700 !important; color: #252525;">{{ 'label.OTP Verification' | i18next }}</h2>
              <div class="row">
                <div class="col-12 mb-5">
                  <b>{{ 'label.We sent OTP' | i18next }}: {{forgotPasswordForm.value.userId}}</b>
                </div>
                <div class="col-12 mb-3">
                  <label for="exampleFormControlInput2" class="form-label"><b>{{ 'label.OTP' | i18next }}</b></label>
                  <input placeholder="" type="otp" class="form-control" name="otp" formControlName="otp"
                    minLength="6" onKeyPress="if(this.value.length==6) return false;">
                  <small class="text-muted">{{ 'label.OTP will remain' | i18next }}</small>
                </div>
                <div class="col-12 my-3 text-center">
                  <button type="submit" [disabled]="!OTPForm.controls['otp'].valid" class="btn btn-dark px-5 py-2">{{ 'label.Validate OTP' | i18next }}</button>
                </div>
                <div class="col-12 text-center">
                  <p class="mb-0">{{ 'label.Did receive an OTP' | i18next }} </p>
                  <span class="text-primary"><a (click)="resendOTP()"
                      class="text-decoration-none custom-text anchorLink mt-2 cursor-pointer">{{ 'label.Resend OTP' | i18next }} </a></span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>



  </div>
</div>
<div class="container-fluid" style="background-color: #faf9fa;" *ngIf="formList.showResetPasswordForm">
  <div class="row align-items-center banner-sidebar customVh">

    <app-auth-header></app-auth-header>
    <div class="col-lg-5  text-center diplayFooter">
      <img src="assets/images/Infographic.png" class="img-fluid" />
      <div class="row">
        <div class="col-12 px-lg-5 text-lg-start ">
          <h4>{{ 'label.IRIS IRP for an easy' | i18next }}</h4>
          <ul>
            <li>{{ 'label.Manage e-invoicing' | i18next }}</li>
            <li>{{ 'label.Bulk IRN generation' | i18next }}</li>
            <li>{{ 'label.Multiple modes' | i18next }}</li>
            <li>{{ 'label.Simple and intuitive' | i18next }}</li>
          </ul>
          <h5>{{ 'label.Sign-up and explore' | i18next }}</h5>
        </div>
      </div>
    </div>
    <div class="col-lg-7">
      <div class="signup_form">
        <div class="card border-0 shadow py-4 px-3 mx-auto" style="max-width: 33.5rem;">
          <div class="card-body">
            <div class="content-center">{{ 'label.Your Password expired' | i18next }}</div>
            <form [formGroup]="setPasswordForm">

              <h2 class="card-title mb-5" style="font-weight: 700 !important; color: #fff;">{{ 'label.Reset Password' | i18next }}</h2>
              <div class="row">
                <div class="col-12 small text-muted mb-3">
                  <i class="bi bi-info-circle-fill"></i>{{ 'label.Password shall have' | i18next }} :
                  <ul>
                    <li>{{ 'validations.Min 8 and Max 16' | i18next }}</li>
                    <li>{{ 'validations.one character' | i18next }}</li>
                    <li>{{ 'validations.special character' | i18next }}</li>
                    <li>{{ 'validations.numeric value' | i18next }}</li>
                  </ul>
                </div>
                <div class="col-12 mb-3">
                  <label class="form-label">{{ 'label.Old Password' | i18next }}<span style="color: red">*</span></label>
                  <input type="password" class="form-control" name="oldPassword" formControlName="oldPassword"
                    placeholder="">
                  <div
                    *ngIf="!setPasswordForm.get('oldPassword')?.valid && (setPasswordForm.get('oldPassword')?.dirty ||setPasswordForm.get('oldPassword')?.touched)">
                    <div class="error" [hidden]="!setPasswordForm.get('oldPassword')?.errors?.required">
                      {{ 'label.Old Password req' | i18next }}
                    </div>
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <label class="form-label"> {{ 'label.New Password' | i18next }} <span style="color: red">*</span></label>
                  <!-- <input type="password" class="form-control" id="exampleFormControlInput2" placeholder="" required> -->
                  <div class="col-12 position-relative">
                    <div class="custom_input">
                      <input type="password" class="form-control" name="password" formControlName="password"
                        (ngModelChange)="passwordMatched = true" placeholder="********">
                      <div *ngIf="setPasswordForm.get('password')!.invalid" class="text-danger m-1">
                        <p class="m-0 text-danger font-size-12" *ngIf="setPasswordForm.controls['password'].errors">{{ 'valid password.valid password' | i18next }}
                        </p>
                        <!-- <p *ngIf="setPasswordForm.get('password')!.touched && setPasswordForm.get('password')!.value !== '' && setPasswordForm.get('password')!.errors!['minlength']
                                            " class="m-0 text-danger font-size-12">* {{ 'login.Min 8' | i18next }}
                        </p>
                        <p *ngIf="setPasswordForm.get('password')!.touched && setPasswordForm.get('password')!.value !== '' && setPasswordForm.get('password')!.errors!['maxlength']
                                        " class="m-0 text-danger font-size-12">* {{ 'login.Min 8' | i18next }}
                          characters long
                        </p>
                        <p *ngIf="setPasswordForm.get('password')!.touched && setPasswordForm.get('password')!.value !== '' && setPasswordForm.get('password')!.errors!['hasCapitalCase']
                                            " class="m-0 text-danger font-size-12">* {{ 'login.1 capital' | i18next }}
                        </p>
                        <p *ngIf="setPasswordForm.get('password')!.touched && setPasswordForm.get('password')!.value !== '' && setPasswordForm.get('password')!.errors!['hasSpecialCharacters']
                                            " class="m-0 text-danger font-size-12">* {{ 'login.1 special' | i18next }}
                        </p>
                        <p *ngIf="setPasswordForm.get('password')!.touched && setPasswordForm.get('password')!.value !== '' && setPasswordForm.get('password')!.errors!['hasNumber']
                                            " class="m-0 text-danger font-size-12">* {{ 'login.1 number' | i18next }}
                        </p> -->
                      </div>
                    </div>
                  </div>
                  <div class="">
                    <div class="col-12 mb-3">
                      <label class="form-label">Confirm Password <span style="color: red">*</span></label>
                    </div>
                    <div class="col-12 position-relative">
                      <div class="custom_input">
                        <input type="password" class="form-control" name="confirmPassword"
                          formControlName="confirmPassword" (ngModelChange)="passwordMatched = true"
                          placeholder="********">
                      </div>
                      <div *ngIf="!passwordMatched">
                        <span class="font-size-12 text-danger">{{ 'label.Pass doesnt Match' | i18next }}.</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 my-3 text-center">
                  <button type="submit" class="btn btn-dark px-5 py-2 mouse-pointer"
                    [disabled]="!setPasswordForm.value.password || !setPasswordForm.value.confirmPassword"
                    (click)="resetPassword()">{{ 'label.Reset Password' | i18next }}</button>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div style="padding: 70px 30px 10px;;background-color: #fafafa;">
  <hr/>
  <div class="container">
      <div class="row">
          <div class="col-md-4">
              <h6>Our products</h6>
              <ul style="list-style: none; padding-left: 10px;">
                  <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                      <i class="bi bi-dash" style="font-size: 18px !important;  vertical-align: middle;
                      margin-right: 5px;"></i> E-Invoicing for
                      Taxpayers
                  </li>
                  <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                      <i class="bi bi-dash" style="font-size: 18px !important;  vertical-align: middle;
                      margin-right: 5px;"></i> E-Invoice APIs
                      for
                      Solution
                      Providers
                  </li>
              </ul>
              <h6>Find us here</h6>
              <i class="bi bi-twitter"></i>
              <i class="mx-2 bi bi-linkedin"></i>
          </div>
          <div class="col-md-4">
              <h6>Resources</h6>
              <ul style="list-style: none; padding-left: 10px;">
                  <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                      <i class="bi bi-dash" style="font-size: 18px !important;   vertical-align: middle;
                      margin-right: 5px;"></i>Notifications
                  </li>
                  <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                      <i class="bi bi-dash" style="font-size: 18px !important;  vertical-align: middle;
                      margin-right: 5px;"></i> Blog
                  </li>
              </ul>
          </div>
          <div class="col-md-4">
              <h6>Contact us</h6>
              <ul style="list-style: none; padding-left: 0px;">
                  <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                      T231, Tower 2, Third floor, International Infotech Park Vashi Navi Mumbai 400703
                      Maharashtra, India
                  </li>
                  <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                      <a href="tel:+9122 6723 1000">+9122 6723 1000</a><br>
                      <a href="javascript:;" href="mailto:hello@irisirp.com">hello@irisirp.com</a>
                  </li>
              </ul>
          </div>
          <div class="col-12 mt-4">
              <h6>© 2022 IRIS Business Services Ltd. All Rights Reserved. | <a (click)="redirectToPrivacy()"  href="javascript:;">Privacy Policy</a> |  <a (click)="redirectToTerms()" href="javascript:;">Terms &
                  Conditions</a></h6>
          </div>
      </div>
  </div>
</div> -->

 <div class="container-fluid footer">
  <div class="row footerDiv">
    <div class="col-md-9">
      <h3 class="display-4 text-white">{{ 'new.IRIS IRP is govt authorised E-Invoice Registration Portal' | i18next }}</h3>
    </div>
    <div class="col-md-3">
      <a href="https://einvoice6.gst.gov.in/content/contact-us/" target="blank"
        class="btn btn-primary rounded mb-0 text-nowrap">{{ 'label.Contact Us' | i18next }}</a>
    </div>
    <hr class="hrdiv">
    <div class="col-md-3">
      <h4 class="widget-title text-white mb-3">{{ 'new.Important links' | i18next }}</h4>
      <ul class="text-white" style="list-style: none; padding-left: 0px;">
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://www.gstn.org.in/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.GSTN' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://cbic-gst.gov.in/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.CBIC' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisirp.com/notifications/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.GST notifications' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://www.india.gov.in/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.National Portal' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://einvoice6.gst.gov.in/content/wp-content/uploads/2023/04/Letter-ON-NEW-IRPS-20April23.pdf"
            target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.GSTN Authorization of IRIS IRP' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://sandbox.einvoice6.gst.gov.in/login" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.IRIS IRP Sandbox' | i18next }}</a>
        </li>
      </ul>
    </div>
    <div class="col-md-3">
      <h4 class="widget-title text-white mb-3">{{ 'new.IRIS IRP Policies' | i18next }}</h4>
      <ul class="text-white" style="list-style: none; padding-left: 0px;">
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://einvoice6.gst.gov.in/content/terms-and-conditions/">{{ 'new.Terms and Condition' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://einvoice6.gst.gov.in/content/privacy/">{{ 'new.Privacy Policy' | i18next }}</a>
        </li>

      </ul>

    </div>
    <div class="col-md-3">
      <h4 class="widget-title text-white mb-3">{{ 'new.About IRIS' | i18next }}</h4>
      <ul class="text-white" style="list-style: none; padding-left: 0px;">
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisirp.com/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.IRIS IRP' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisgst.com/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.IRIS GST' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisbusiness.com/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.IRIS Business' | i18next }}</a>
        </li>

      </ul>
      <h4 class="widget-title text-white mb-3">{{ 'new.Need Help' | i18next }}</h4>
      <ul class="text-white" style="list-style: none; padding-left: 0px;">
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="mailto:support@irisirp.com">{{ 'new.Write to: support@irisirp.com' | i18next }}</a>
        </li>
      </ul>
    </div>
    <div class="col-md-3">
      <h4 class="widget-title text-white mb-3">{{ 'new.Other Products' | i18next }}</h4>
      <ul class="text-white" style="list-style: none; padding-left: 0px;">
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;"> <a
            href="https://irisgst.com/irissapphire/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.IRIS GST Software' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisgst.com/iris-onyx/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.IRIS E-Invoicing' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisgst.com/gst-litigation-management-system/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.IRIS Litigation Management' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisgst.com/iristopaz/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.IRIS E-Way Bill' | i18next }}  </a>
        </li>

        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisgst.com/iris-lookup/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.IRIS LookUp' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisgst.com/irisperidot/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.IRIS Peridot' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://easywaybill.in/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.EWB app for transporters' | i18next }}</a>
        </li>
      </ul>
    </div>
    <hr class="hrdiv mb10">
    <div class="row">
      <div class="col-md-3">
        <div class="social social-white social-center">
          <span><a href="https://twitter.com/IRIS_IRP" target="blank"><i
                style="color:#fff; font-size: 18px;     padding-right: 16px; font-weight: 400;padding-bottom: 15px;"
                class="bi bi-twitter"></i></a></span>
          <span><a href="https://www.linkedin.com/showcase/82795027/admin/" target="blank"><i
                style="color:#fff; font-size: 18px;     padding-right: 16px; font-weight: 400;padding-bottom: 15px;"
                class="bi bi-linkedin"></i></a></span>
          <span><a href="https://www.youtube.com/@irisirp" target="blank"><i
                style="color:#fff; font-size: 18px;     padding-right: 16px; font-weight: 400;padding-bottom: 15px;"
                class="bi bi-youtube"></i></a></span>
        </div>
      </div>
      <div class="col-md-6">
        <p class="text-center text-white">© {{ 'new.2023 IRIS Business Services Ltd' | i18next }}.</p>
      </div>
      <div class="col-md-3 social-center">
        <span><img class="responsive" style="height: 40px;width: 100px;"
            src="../../../../assets/images/brands/sslcertificate.png" alt=""></span>
        <span><img class="responsive" style="height: 40px;width: 100px;" src="../../../../assets/images/brands/iso.png"
            alt=""></span>

      </div>
    </div>
  </div>
</div>
<ng-template #mfaModal>
  <div class="modal-header">
    <h5 class="modal-title pull-left text_dark_blue">
      Important Update: Multi Factor Authentication
    </h5>
    <button
      type="button"
      class="btn-close close pull-right"
      aria-label="Close"
      (click)="mfaModelRef?.hide()"
    >
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      Dear Taxpayers,
      To enhance the security of the E-Way Bill system, Multi-Factor Authentication (MFA) has been introduced. MFA provides an additional layer of security, ensuring that only authorized users can access the system.
      Users will be required to setup MFA using authenticator apps like Google Authenticator or Microsoft Authenticator.
      If you encounter trouble logging in with MFA, you can use the "Resend OTP" option for email or SMS OTP.
    </p>
    <p>
      <b>How to Enable MFA for Your Account:</b> <br><br>
      1. Log in to your IRP account. <br>
      2. Navigate to your Profile <br>
      3. Click on the "Enable MFA' link<br>
      4. Follow the steps to link your authenticator app.</p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary font-size-14 ms-2"
      (click)="enableMFA()"
    >
      Proceed to Setup MFA
    </button>
    <button
      type="button"
      class="btn btn-secondary font-size-14 ms-2"
      (click)="mfaModelRef?.hide()"
    >
      Do it later
    </button>
  </div>
</ng-template>

<!-- <div bsModal #advisoryModel="bs-modal" class="modal fade show" style="z-index: 99999;" data-bs-backdrop="static" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-adv">
    <div class="modal-content">
      <button type="button" (click)="advisoryModel.hide()" class="advisory-close" data-bs-dismiss="modal" aria-label="Close">×</button>
      <div class="modal-body">

        <strong class="black-text">Important Advisory on Verifying Signed Invoices / QR Code for E-Invoices Generated Between 21st Nov 2024 to 26th Nov 2024 from <a  class="link" href="https://einvoice6.gst.gov.in/"> IRIS IRP</a></strong>
        <br />
        <br />
        <p><strong  class="black-text">Date:</strong> 26th Nov 2024</p>
        <p>Dear Taxpayers,</p>

        <p>Please be informed that due to a technical glitch, e-invoices generated between <strong  class="black-text">21st Nov 2024 and 26th Nov 2024</strong> will not be verified through the GSTN/NIC app.</p>

        <p class="important">To verify the authenticity of e-invoices, please inform the appropriate officer to use the "<a href="https://einvoice.gst.gov.in/einvoice/search-irn" class="link">Search IRN</a>" functionality on the GST portal.</p>

        <p>We are working to resolve this issue, and the fix will be deployed by <strong  class="black-text">26th Nov 2024 end of the day</strong>.</p>

        <p>We apologize for the inconvenience caused.</p>

        <p>Thank you,</p>
        <p><strong class="black-text">Team IRISIRP</strong></p>
      </div>
    </div>
  </div>
</div> -->

