<div class="custom_container">
  <div class="row">
    <div class="col-md-12" *ngIf="!setCaptch">
      <div class="iris-dashboard-cards2">
        <div class="bannerImg highDiv">
          <img src="../../../../../assets/images/landingpage/bannerImg.png" height="200" />
          <a class="btn btn-primary rounded p13 btnContact" href="https://sandbox.einvoice6.gst.gov.in/login">{{ 'label.Try Sandbox today' | i18next }}</a>
        </div>
        <div class="smallDiv">
          <p>{{ 'label.E-invoicing mandatory' | i18next }}<sup>{{ 'label.st' | i18next }}</sup> {{ 'common.August' | i18next }}</p>
          <p>({{ 'label.GST Notification' | i18next }})</p>
          <p><a class="btn rounded p13 btnContact" href="https://sandbox.einvoice6.gst.gov.in/login">{{ 'label.Try Sandbox today' | i18next }}</a></p>
        </div>
          <!-- <a href="https://sandbox.einvoice6.gst.gov.in/login"><img
              src="../../../../../assets/images/landingpage/sandboximg.png" height="250" width="100%" /></a> -->
        </div>
      </div>
      </div>
      <div class="row">
        <div class="col-md-9 mt15">
          <div class="row">
            <div class="col-md-12">
              <div class="iris-dashboard-cards iris-news-updates h265">
                <div class="col-12 text-end">

                </div>
                <h4 class="mb-4">{{ 'label.Search Einvoice enablement' | i18next }}</h4>

                <div class="content">
                  <div class="mb-3 row">
                    <label for="gstin" class="col-sm-3 col-form-label">{{ 'formSteps.GSTIN' | i18next }}: </label>
                    <div class="col-sm-9">
                      <input type="text" class="form-control" id="gstin" name="gstin" [(ngModel)]="gstin"
                        (input)="checkCaptcha()" placeholder="{{ 'formSteps.GSTIN' | i18next }}">
                    </div>

                  </div>
                  <div class="mb-3 row ww" *ngIf="!setCaptch && captchaVisible1">
                    <label for="gstin" class="col-sm-3 col-form-label"></label>
                    <label for="gstin" *ngIf="!setCaptch"
                      class="col-sm-3 text-center font-size-30 captchaTxt">{{this.captchString}}</label>
                    <!-- <label *ngIf="!setCaptch">{{this.captchString}}</label> -->
                    <div class="col-sm-3">
                      <button title="{{ 'label.Refresh Data' | i18next }}" *ngIf="!setCaptch" class="btn btn-sm custom-icon-button"
                        (click)="generateRandomString(5)">
                        <!-- <i class="bi bi-arrow-clockwise "></i> -->
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.0625 3.3457C15.25 1.5332 12.7625 0.408203 9.99995 0.408203C4.47495 0.408203 0.0124512 4.8832 0.0124512 10.4082C0.0124512 15.9332 4.47495 20.4082 9.99995 20.4082C14.6625 20.4082 18.5499 17.2207 19.6625 12.9082H17.0625C16.0375 15.8207 13.2625 17.9082 9.99995 17.9082C5.86245 17.9082 2.49995 14.5457 2.49995 10.4082C2.49995 6.2707 5.86245 2.9082 9.99995 2.9082C12.075 2.9082 13.9249 3.7707 15.275 5.1332L11.25 9.1582H20V0.408203L17.0625 3.3457Z"
                            fill="#252525" />
                        </svg>

                      </button>
                    </div>
                    <label for="gstin" class="col-sm-3 col-form-label"></label>



                  </div>
                  <div class="mb-3 row" >
                    <label for="gstin" class="col-sm-3 col-form-label hideDiv"></label>
                    <div class="col-sm-4" *ngIf="captchaVisible1">
                      <input type="text" class="form-control" id="gstin" *ngIf="!setCaptch" name="gstin"
                        [(ngModel)]="captcha" placeholder="{{ 'common.Enter Captcha' | i18next }}">
                    </div>
                    <div class="text-end" [ngClass]="captchaVisible1 ? 'col-sm-5' : 'col-md-9'">

                      <button class="btn btn-outline-dark px-4 me-2" (click)="clear()">{{ 'common.Clear' | i18next }}</button>
                      <button class="btn btn-dark px-4" *ngIf="setCaptch" (click)="searchEinvoice()">{{ 'label.Search' | i18next }}</button>
                      <button class="btn btn-dark px-4" *ngIf="!setCaptch" (click)="searchEinv()">{{ 'label.Search' | i18next }}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-md-3" *ngIf="!setCaptch">
          <div class="iris-dashboard-cards2">
            <img src="../../../../../assets/images/landingpage/sandboximg.png" height="200" />
          </div>
        </div> -->
          </div>
          <div class="iris-dashboard-cards iris-news-updates mt15" *ngIf="showResults">
            <div class="visibleGstin" id="visibleGstin">
              <h4 *ngIf="!isErrorPresent" class="mb-4 success-field ">{{ 'label.Search Einvoice enablement' | i18next }}</h4>
              <h4 *ngIf="isErrorPresent" class="mb-4 error-field text-center">{{ 'common.Error Details' | i18next }}</h4>

              <div class="content text-start ms-0">
                <div class="mb-3 row">
                  <div class="col-12">
                    <ng-container *ngIf="isErrorPresent">
                      <div class="row mt-3 text-center">
                        <div class="col-md-12">
                          <img src="../../../../../assets/images/No-Data-found.png" height="200" />
                          <p>{{errorMessage}}</p>
                        </div>

                      </div>
                      <div class="col-12 text-end">
                        <button class="btn btn-dark px-4 mb-3" (click)="getUpdatedData()">{{ 'label.Update From Common Portal' | i18next }}
                          </button>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="!isErrorPresent">
                      <div class="row mt-3">
                        <div class="col-md-12 col-12 font-size-14">

                          <div class="row mb-3">
                            <div class="col-md-4 col-12">
                              <label class="static-label lbl">{{ 'formSteps.GSTIN' | i18next }} : </label>
                            </div>
                            <div class="col-md-8 col-12">
                              <span class="textBreakAll">{{invDetail?.gstin}}</span>
                            </div>
                          </div>
                          <div class="row mb-3">
                            <div class="col-md-4 col-12">
                              <label class="static-label lbl">{{ 'formSteps.Trade Name' | i18next }} : </label>
                            </div>
                            <div class="col-md-8 col-12">
                              <span class="textBreakAll">{{invDetail?.tradeNm}}</span>
                            </div>
                          </div>
                          <div class="row mb-3">
                            <div class="col-md-4 col-12">
                              <label class="static-label lbl">{{ 'formSteps.Legal Name' | i18next }} : </label>
                            </div>
                            <div class="col-md-8 col-12">
                              <span class="textBreakAll">{{invDetail?.legalNm}}</span>
                            </div>
                          </div>
                          <div class="row mb-3">
                            <div class="col-md-4 col-12">
                              <label class="static-label lbl">{{ 'label.Enablement Status' | i18next }} : </label>
                            </div>
                            <div class="col-md-8 col-12">
                              <span class="textBreakAll">{{invDetail?.einvoiceEnable}}
                              </span>
                            </div>
                          </div>
                          <div class="row mb-3">
                            <div class="col-md-4 col-12">
                              <label class="static-label lbl">{{ 'formSteps.Type' | i18next }} : </label>
                            </div>
                            <div class="col-md-8 col-12">
                              <span class="textBreakAll">{{invDetail?.type}}</span>
                            </div>
                          </div>

                          <div class="row mb-3" *ngIf="!showReason">
                            <div class="col-md-4 col-12">
                              <label class="static-label lbl">{{ 'common.Enablement Date' | i18next }} : </label>
                            </div>
                            <div class="col-md-8 col-12">
                              <span class="textBreakAll">{{invDetail?.einvoiceDate}}</span>
                            </div>
                          </div>
                          <div class="row mb-3" *ngIf="showReason">
                            <div class="col-md-4 col-12">
                              <label class="static-label lbl">{{ 'tableHeader.generatedTablecols.Reason' | i18next }} : </label>
                            </div>
                            <div class="col-md-8 col-12">
                              <span class="textBreakAll">{{reasonValue}} <span *ngIf="showDisclaimer"
                                  style="color: red;">*</span></span>
                            </div>
                          </div>
                          <div class="col-12 text-end">
                            <button class="btn btn-dark px-4 mb-3" (click)="getUpdatedData()">{{ 'label.Update From Common Portal' | i18next }}</button>
                          </div>
                          <div *ngIf="showDisclaimer" class="col-12 mt-2">
                            <span style="color: red;">*</span>{{ 'label.TCS GSTINs are not allowed to' | i18next }}T

                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 mt15">
          <div class="iris-dashboard-cards iris-news-updates ">
            <h4 class="mb-4">{{ 'label.IRIS IRPs e-invoicing' | i18next }}</h4>
            <div class="content">
              <div class="font-size-14 text-start mt-3 text-muted"><i class="bi bi-info-circle-fill"></i>
                {{ 'label.This option to search' | i18next }}
                <!-- <a href="#"
            class="footer-links anchor">Archived Data</a> -->
              </div>
              <div class="font-size-14 text-start mt-3 text-muted">
                {{ 'label.Where status is enabled' | i18next }}
                <!-- <a href="#"
            class="footer-links anchor">Archived Data</a> -->
              </div>
              <div class="font-size-14 text-start mt-3 text-muted">
                {{ 'label.If your PAN has' | i18next }} : <a href="https://selfservice.gstsystem.in/"
                  title="{{ 'label.This is an external link' | i18next }} "
                  target="_blank">{{ 'label.Goods and Services Tax' | i18next }}</a>
                <!-- <a href="#"
            class="footer-links anchor">Archived Data</a> -->
              </div>
              <div class="font-size-14 text-start mt-3 text-muted">
                {{ 'label.In case any registered person' | i18next }} 
                <!-- <a href="#"
            class="footer-links anchor">Archived Data</a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
 
  